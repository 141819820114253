import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesValidationModule} from '../../directives/validation/directives.validation.module';
import {PipesModule} from '../../pipes/pipes.validation.module';
import {BrowserModule} from '@angular/platform-browser';
import {BaseComponentsModule, BaseCoreServicesModule} from 'base';
import {environment} from '../../../environments/environment';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    BrowserModule,
    FormsModule,
    TranslateModule,
    DirectivesValidationModule,
    PipesModule,
    RouterModule,
    BaseComponentsModule.forRoot(environment),
    BaseCoreServicesModule
  ]
})
export class LoginModule {}
