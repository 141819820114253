import {BatchConfig} from './batch/BatchConfig';
import {FlowOperation} from './FlowOperation';
import {ImportConfig} from './_import/ImportConfig';
import {NotificationConfig} from './notif/NotificationConfig';
import {Transform, Type} from 'class-transformer';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {BlacklistPepInjector} from '../injector/BlacklistPepInjector';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';

export class Flow extends ClassedEntity {
  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'flow';

  company: string;
  name: string;
  @Type(() => BatchConfig)
  batchConfig: BatchConfig = new BatchConfig();
  authorizedOperations: FlowOperation[] = [];
  @Type(() => ImportConfig)
  importConfig: ImportConfig = new ImportConfig();
  @Type(() => NotificationConfig)
  notificationConfig: NotificationConfig = new NotificationConfig();
  @Transform(ReferenceTransformer.transformTo(BlacklistPepInjector))
  injector: Reference<BlacklistPepInjector>;

  constructor() {
    super('com.beclm.blacklist.pep.api.flow.Flow');
  }

  public static toSelectableRef(
    reference: Reference<Flow>
  ): SelectableRef<Flow> {
    const selectableRef = new SelectableRef<Flow>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<Flow> {
    const selectableRef = new SelectableRef<Flow>();
    selectableRef.id = new Reference<Flow>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }

  hasRiskFolderCreation(): boolean {
    return (
      this.authorizedOperations.indexOf(FlowOperation.RISKFOLDER_CREATION) !==
      -1
    );
  }

  isGDA(): boolean {
    return (
      this.batchConfig.blacklistMatchingBatchConfig != null &&
      this.batchConfig.blacklistMatchingBatchConfig.active === true
    );
  }

  isPPE(): boolean {
    return (
      this.batchConfig.pepMatchingBatchConfig != null &&
      this.batchConfig.pepMatchingBatchConfig.active === true
    );
  }

  isAdverseMedia(): boolean {
    return (
      this.batchConfig.adverseMediaMatchingBatchConfig != null &&
      this.batchConfig.adverseMediaMatchingBatchConfig.active === true
    );
  }
}
