import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {IntResponse} from '../../../../domain/IntResponse';
import {PersonControlResultType} from '../../domain/adapter/pcr-archiver/person-control-result/PersonControlResultType';
import {NavigationService} from '../../../../services/navigation.service';

@Injectable()
export class PersonControlResultService {
  private readonly baseUrl = '/api/person-control-result';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    private navigationService: NavigationService,
  ) {}

  public getGroupIndexByExternalRefId(
    externalRefId: string
  ): Observable<IntResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(IntResponse)
          .get<IntResponse>(url + '/group-index', {
            params: {
              externalRefId: externalRefId
            }
          })
      )
    );
  }

  public openPcrSearchFromExternalModule(
    companyId: string,
    sourceId: string,
    externalRefId: string,
    personControlResultType: PersonControlResultType
  ) {
    this.moduleService
      .companyModuleUiUrl(
        '/company/' + companyId + '/person-control-result/search'
      )
      .subscribe((url) => {
        this.navigationService.navigateTo(
          url +
            '?sourceId=' +
            sourceId +
            '&externalRefId=' +
            externalRefId +
            '&personControlResultType=' +
            personControlResultType
        );
      });
  }
}
