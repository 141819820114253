<div class="grid-x grid-padding-x">
  <div class="cell small-24">
    <a-title-view
      [titleKey]="'register.step.three.title' | translate"
    ></a-title-view>
    <b
      >{{'register.step.three.title.information.' + data.registrationProfile |
      translate}}</b
    >
    <spacer [size]="16"></spacer>
  </div>
  <form #form="ngForm" class="grid-x grid-padding-x grid-padding-y">
    <div class="cell small-24 grid-x grid-padding-x">
      <a-input
        class="cell small-24"
        [(ngModel)]="data.companyRegistration.name"
        name="companyName"
        [inputType]="'text'"
        [label]="'register.company.name' | translate"
        [required]="true"
      >
      </a-input>

      <m-siren-check-input
        class="cell small-24"
        name="siren"
        [(ngModel)]="data.companyRegistration.siren"
        (legalEntityChange)="onSirenLegalEntityChange($event)"
        [checkExistingSiren]="false"
        [editable]="true"
        [isCompany]="true"
        [whitelistSirenByNaf]="true"
        [checkStruckOffSiren]="true"
        [checkPublicationStatus]="true"
        [checkSirenPlaceHolder]="'register.siren.ask.validation' | translate"
        #sirenInput="ngModel"
        [required]="true"
        [forceCheckSiren]="true"
      >
      </m-siren-check-input>

      <a-input
        *ngIf="!companyPublished || !isCompanyAddressComplete"
        class="cell small-24 medium-12"
        [(ngModel)]="data.companyRegistration.address.address"
        name="companyAddress"
        [inputType]="'text'"
        [label]="'register.company.address' | translate"
        [required]="!companyPublished"
      >
      </a-input>

      <a-input
        *ngIf="!companyPublished || !isCompanyAddressComplete"
        class="cell small-24 medium-12"
        [(ngModel)]="data.companyRegistration.address.zipCode"
        name="companyZipCode"
        [inputType]="'text'"
        [label]="'register.company.zipCode' | translate"
        [required]="!companyPublished"
      >
      </a-input>

      <a-input
        class="cell small-24 medium-12"
        *ngIf="!companyPublished || !isCompanyAddressComplete"
        [(ngModel)]="data.companyRegistration.address.city"
        name="companyCity"
        [inputType]="'text'"
        [label]="'register.company.city' | translate"
        [required]="!companyPublished"
      >
      </a-input>

      <a-input
        class="cell small-24"
        *ngIf="data.registrationProfile === registrationProfileEnum.BROKER"
        [(ngModel)]="data.companyRegistration.headcount"
        noNegativeValue
        name="headcount"
        [inputType]="'number'"
        [label]="'register.headcount' | translate"
        [required]="data.registrationProfile === registrationProfileEnum.BROKER"
        valueIsInRange
        [minValue]="1"
        [min]="1"
        [step]="1"
      >
      </a-input>

      <div class="cell small-24" *ngIf="data.registrationProfile === registrationProfileEnum.GENERAL_AGENT">
        <spacer [size]="8"></spacer>
        <m-callout [calloutTxtAlign]="'LEFT'">
          <ng-container txt>
            <div [innerHtml]="'register.company.agea.number.information' | translate"></div>
          </ng-container>
        </m-callout>
        <spacer [size]="16"></spacer>
      </div>

      <a-input
        class="cell small-24 medium-12"
        *ngIf="data.registrationProfile === registrationProfileEnum.GENERAL_AGENT"
        [(ngModel)]="data.companyRegistration.ageaNumber"
        name="ageaNumber"
        [inputType]="'text'"
        [label]="'register.company.agea.number' | translate"
      >
      </a-input>
    </div>
  </form>
</div>
