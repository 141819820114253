export enum FolderType {
  GENERIC = 'GENERIC',
  NATURAL_PERSON = 'NATURAL_PERSON',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  TRAININGS = 'TRAININGS',
  PRODUCTS = 'PRODUCTS'
}

export namespace FolderType {
  export function values() {
    return Object.keys(FolderType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
