import {Reference} from '../../../../domain/Reference';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {BlacklistPepFileMappingField} from './BlacklistPepFileMappingField';

export class BlacklistPepFileMapping extends ClassedEntity {
  public name: string;
  public fileEncoding: string;
  public fileSeparatorChar: string;
  public fileQuotationChar: string;
  public fileEscapeChar: string;
  public fileHeaders: boolean;
  public fileFields: BlacklistPepFileMappingField[] = [];

  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'fileMapping';

  constructor() {
    super('com.beclm.blacklist.pep.api.fileMapping.BlacklistPepFileMapping');
  }

  public static toSelectableRef(
    reference: Reference<BlacklistPepFileMapping>
  ): SelectableRef<BlacklistPepFileMapping> {
    const selectableRef = new SelectableRef<BlacklistPepFileMapping>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<BlacklistPepFileMapping> {
    const selectableRef = new SelectableRef<BlacklistPepFileMapping>();
    selectableRef.id = new Reference<BlacklistPepFileMapping>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
