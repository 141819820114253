import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ExportDescriptor} from '../../domain/exportDescriptor/ExportDescriptor';
import {SearchParams} from '../../../../domain/SearchParams';
import {ExportDescriptorSearchCriteria} from '../../domain/exportDescriptor/ExportDescriptorSearchCriteria';
import {PaginatedExportDescriptors} from '../../domain/exportDescriptor/PaginatedExportDescriptors';
import {ExportDescriptorModification} from '../../domain/exportDescriptor/ExportDescriptorModification';
import {ExportParams} from '../../domain/exportDescriptor/ExportParams';
import {IntegrationFormat} from '../../../../domain/IntegrationFormat';

@Injectable()
export class ExportDescriptorService {
  private readonly baseUrl = '/api/exportDescriptor';


  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public search(
    params?: ExportDescriptorSearchCriteria
  ): Observable<PaginatedExportDescriptors> {
    return this.moduleService
      .moduleBackendUrl(params.module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedExportDescriptors)
            .get<PaginatedExportDescriptors>(`${url}`, {
              params: SearchParams.toHttpParams(params)
            })
        )
      );
  }

  public getById(id: string, module: string): Observable<ExportDescriptor> {
    return this.moduleService
      .moduleBackendUrl(module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ExportDescriptor)
            .get<ExportDescriptor>(`${url}/${id}`)
        )
      );
  }

  public updateExportDescriptor(
    exportDescriptor: ExportDescriptorModification,
    module: string
  ): Observable<any> {
    return this.moduleService
      .moduleBackendUrl(module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ExportDescriptor)
            .put(`${url}/update`, exportDescriptor)
        )
      );
  }

  public createTask(exportDescriptorId: string, params: ExportParams, module: string): Observable<any>{
    return this.moduleService
      .moduleBackendUrl(module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .get(`${url}/${exportDescriptorId}/export`, {
              params: SearchParams.toHttpParams(params)
            })
        )
      );
  }

  public getExampleUrl(exportDescriptorId: string, format: IntegrationFormat, module: string){
    return (`/${module}${this.baseUrl}/${exportDescriptorId}/example?format=${format}`); //TODO: use moduleBackendUrl instead
  }
}
