import {Component, Input} from '@angular/core';
import {FormStep} from '../FormStep';

@Component({
  selector: 'm-form-step-thread',
  templateUrl: './m-form-step-thread.component.html',
  styleUrls: ['./m-form-step-thread.component.scss']
})
export class MFormStepThreadComponent {
  @Input()
  stepsConfig: FormStep[] = [];
  @Input()
  activeFormStepIndex = 0;
}
