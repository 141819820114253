import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Transform} from 'class-transformer';
import {CompanyTypeWrapper, ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../company/Company';
import {Reference} from '../../../../../domain/Reference';
import {SelectableRef} from '../../../../../domain/SelectableRef';
import {Uri} from '../../../../../domain/Uri';
import {PersonDataSourceDefinitionType} from './PersonDataSourceDefinitionType';

export class PersonDataSource extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'personDataSource';

  name: string;
  personDataSourceTypes: string[];
  @Transform(ReferenceTransformer.transformTo(CompanyTypeWrapper))
  companies: Reference<Company>[];
  editable: boolean;
  hidden: boolean;
  label: string;
  shared: boolean;
  activatedPersonDataCategoryType: string[];
  definitionType: PersonDataSourceDefinitionType;

  constructor() {
    super('com.beclm.core.api.personDatasource.PersonDataSource');
  }

  public static toSelectableRef(
    reference: Reference<PersonDataSource>
  ): SelectableRef<PersonDataSource> {
    const selectableRef = new SelectableRef<PersonDataSource>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.label;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<PersonDataSource> {
    const selectableRef = new SelectableRef<PersonDataSource>();
    selectableRef.id = new Reference<PersonDataSource>(this);
    selectableRef.__label = this.label;
    return selectableRef;
  }

  public static referenceFrom(id: string) {
    return new Reference<Company>(new Uri(this.MODULE, this.COLLECTION, id));
  }
}
