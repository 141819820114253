<div class="grid-x grid-padding-x">
  <div class="cell small-24">
    <a-title-view [titleKey]="'register.step.two.title' | translate"></a-title-view>
    <b> {{'register.step.two.title.information' | translate}}</b>
    <spacer [size]="16"></spacer>
  </div>

  <div class="cell small-up-1 medium-up-2 large-up-3 grid-x grid-padding-x">
    <form #form="ngForm" password-confirmation>
      <div class="grid-x">
        <div class="cell small-24 grid-x grid-padding-x grid-margin-x grid-padding-y">
          <a-input
            class="cell small-24"
            [(ngModel)]="userRegistration.lastName"
            name="lastName"
            [label]="'register.name' | translate"
            [required]="true">
          </a-input>

          <a-input
            class="cell small-24"
            [(ngModel)]="userRegistration.firstName"
            name="firstName"
            [label]="'register.firstName' | translate"
            [required]="true">
          </a-input>

          <a-input
            class="cell small-24"
            name="email"
            [inputType]="'email'"
            [label]="'register.email' | translate"
            [(ngModel)]="userRegistration.email"
            [userEmailIsValid]="userRegistration.email"
            emailCheckBlacklist
            [required]="true"
            #email="ngModel">
          </a-input>
          <div *ngIf="email.errors" class="cell small-24 grid-x grid-padding-x">
            <div class="cell small-24 red error-message"
                 *ngIf="email.errors.required && email.status !== 'PENDING' && !email.pristine">
              {{'register.email.error.missing' | translate}}
            </div>
            <div class="cell small-24 red error-message"
                 *ngIf="email.errors.emailIsNotValid">
              {{'register.email.error.not.valid' | translate}}
            </div>
            <div class="cell small-24 red error-message"
                 *ngIf="email.errors.emailIsBlacklist">
              {{'register.email.error.not.valid' | translate}}
            </div>
          </div>

          <m-callout [calloutTxtAlign]="'LEFT'"
                     class="cell small-24">
            <ng-container txt>
              {{'register.password.pattern.description' | translate:{pattern: securityPolicy?.pwdPatternDescription} }}
            </ng-container>
          </m-callout>


          <a-input
            class="cell small-24"
            [(ngModel)]="password"
            name="password"
            password-strength
            [passwordRegex]="securityPolicy?.pwdPattern"
            [inputType]="'password'"
            [label]="'register.password' | translate"
            [required]="true"
            #passwordInput="ngModel">
          </a-input>

          <div *ngIf="passwordInput.errors" class="cell small-24 grid-x grid-padding-x">
            <div class="cell small-24 red error-message"
                 *ngIf="passwordInput.errors['password-strength']">
              {{'register.password.not.matching.pattern' | translate}}
            </div>
          </div>

          <a-input
            class="cell small-24"
            [(ngModel)]="passwordConfirmation"
            name="password-confirmation"
            [inputType]="'password'"
            [label]="'register.password.confirmation' | translate"
            [required]="true">
          </a-input>

          <div *ngIf="form.errors" class="cell small-24 grid-x grid-padding-x">
            <div class="cell small-24 red error-message"
                 *ngIf="form.errors['password-confirmation']">
              {{'register.password.error.not.equals' | translate}}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
