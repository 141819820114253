import {FileConfig} from './FileConfig';
import {DataSource} from '../../data-source/DataSource';
import {ImportType} from '../../import/ImportType';
import {Transform, Type} from 'class-transformer';
import {Reference} from '../../../../../domain/Reference';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';

export class ImportConfig {
  importType: ImportType;
  @Transform(ReferenceTransformer.transformTo(DataSource))
  source: Reference<DataSource> = null;
  @Type(() => FileConfig)
  fileConfigs: Map<string, FileConfig>;
}
