import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHandlerService} from './http/http-handler.service';
import {Version} from '../domain/Version';

@Injectable()
export class VersionService {
  private readonly url = this.environment.apiBaseHref + '/api/version';

  constructor(
    private http: HttpHandlerService,
    @Inject('env') private environment
  ) {}

  public getVersion(): Observable<Version> {
    return this.http.transformResponseTo(Version).get(this.url);
  }
}
