import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ModuleService} from '../../../../services/module.service';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {Subscription} from '../../domain/company/Subscription';

@Injectable()
export class SubscriptionService {
  private readonly baseUrl: string = '/api/subscriptions';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getSubscriptionsForCurrentCompany(): Observable<Subscription[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .get<Subscription[]>(`${url}`)
        )
      );
  }

  public getRecurrentSubscriptionsForCurrentCompany(): Observable<
    Subscription[]
  > {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .get<Subscription[]>(`${url}/current/recurrent`)
        )
      );
  }

  public getSubscriptionsByCompanyId(
    companyId: string
  ): Observable<Subscription[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .get<Subscription[]>(`${url}/${companyId}`)
        )
      );
  }

  public save(subscription: Subscription): Observable<Subscription> {
    if (subscription.id !== undefined) {
      return this.update(subscription);
    } else {
      return this.create(subscription);
    }
  }

  public create(subscription: Subscription): Observable<Subscription> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .post<Subscription>(`${url}`, subscription)
        )
      );
  }

  public update(subscription: Subscription): Observable<Subscription> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .put<Subscription>(`${url}`, subscription)
        )
      );
  }

  public delete(id: string): Observable<Subscription> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .delete<Subscription>(`${url}/${id}`)
        )
      );
  }

  public cancelRecurrentSubscription(
    subscription: Subscription
  ): Observable<Subscription> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Subscription)
            .put<Subscription>(
              `${url}/cancel-recurrent-subscription`,
              subscription
            )
        )
      );
  }
}
