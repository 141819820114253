import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {DataSourceScoringConfig} from '../../domain/person/scoring-config/DataSourceScoringConfig';
import {switchMap} from 'rxjs/operators';
import {PersonDataSource} from '../../domain/person/data-source/PersonDataSource';
import {Reference} from '../../../../domain/Reference';
import {
  DataSourceScoringConfigModification
} from '../../domain/person/scoring-config/DataSourceScoringConfigModification';

@Injectable()
export class DataSourceScoringConfigService {
  private readonly baseUrl = '/api/scoring-config';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getScoringConfigs(): Observable<DataSourceScoringConfig[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(DataSourceScoringConfig)
            .get<DataSourceScoringConfig[]>(
              `${url}/current`
            )
        )
      );
  }

  public findScoringConfigForSource(personDataSourceRef: Reference<PersonDataSource>): Observable<DataSourceScoringConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(DataSourceScoringConfig)
            .get<DataSourceScoringConfig>(
              `${url}/personDataSourceRef`, {
                params: {personDataSourceRef: personDataSourceRef}
              }
            )
        )
      );
  }

  public updateScoringConfigs(
    scoringConfigs: DataSourceScoringConfigModification[]
  ): Observable<DataSourceScoringConfig[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(DataSourceScoringConfig)
            .put<DataSourceScoringConfig[]>(`${url}`, scoringConfigs)
        )
      );
  }
}
