import {Component, Input} from '@angular/core';
import {fromSize, fromVisibility, Size, Visibility} from '../links-utils';

@Component({
  selector: 'a-link-default',
  templateUrl: './a-link-default.component.html',
  styleUrls: ['../links.scss', './a-link-default.component.scss']
})
export class ALinkDefaultComponent {
  @Input()
  public disabled = false;

  @Input()
  public visibility: Visibility = 'MEDIUM';

  @Input()
  public size: Size = 'DEFAULT';

  public fromVisibility = fromVisibility;
  public fromSize = fromSize;
}
