import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {UserCompanyModification} from '../../domain/user/user/UserCompanyModification';
import {User} from '../../domain/user/user/User';

@Injectable()
export class UserGuestsService {
  private readonly baseUrl = '/api/users/guests';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public saveGuestInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    if (userModification.id !== undefined) {
      return this.updateGuestInCompany(userModification, companyId, captchaToken);
    } else {
      return this.createGuestInCompany(userModification, companyId, captchaToken);
    }
  }

  public createGuestInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .post<User>(`${url}/company/${companyId}?captchaToken=${captchaToken}`, userModification)
        )
      );
  }

  public updateGuestInCompany(
    userModification: UserCompanyModification,
    companyId: string,
    captchaToken: string
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .put<User>(`${url}/company/${companyId}?captchaToken=${captchaToken}`, userModification)
        )
      );
  }

  public removeGuestInCompany(
    userId: string,
    companyId: string,
  ): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .delete<void>(`${url}/user/${userId}/company/${companyId}`)
        )
      );
  }

  public saveGuestInCompanies(
    userModification: UserCompanyModification,
    companyIds: string[]
  ): Observable<User> {
    if (userModification.id !== undefined) {
      return this.updateGuestInCompanies(userModification, companyIds);
    } else {
      return this.createGuestInCompanies(userModification, companyIds);
    }
  }

  public createGuestInCompanies(
    userModification: UserCompanyModification,
    companyIds: string[],
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .post<User>(`${url}`, userModification, {params: {companyIds: companyIds}})
        )
      );
  }

  public updateGuestInCompanies(
    userModification: UserCompanyModification,
    companyIds: string[],
  ): Observable<User> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(User)
            .put<User>(`${url}`, userModification, {params: {companyIds: companyIds}})
        )
      );
  }

  public removeGuest(
    userId: string,
  ): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .delete<void>(`${url}/user/${userId}`)
        )
      );
  }

}
