import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'a-button-cancel',
  templateUrl: './a-button-cancel.component.html',
  styleUrls: ['./a-button-cancel.component.scss']
})
export class AButtonCancelComponent implements OnInit {
  // Expanded host binding
  @HostBinding('class._expanded') isExpanded = false;

  @Input()
  public loading = false;

  @Input()
  public labelKey: string;

  @Input()
  public small: string;

  @Input()
  public expanded = false;

  @Input()
  public disabled = false;

  @Output()
  public btnClick = new EventEmitter<MouseEvent>();

  @Input()
  public btnRouterLink: any[] | string | null | undefined;

  @Input()
  public btnRouterLinkQueryParams: {
    [key: string]: any;
  };

  ngOnInit(): void {
    if (this.expanded) {
      this.isExpanded = true;
    }
  }
}
