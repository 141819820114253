import {AbstractControl, ValidatorFn} from '@angular/forms';
import {CustomValidators} from 'base';

export class PasswordConfirmationValidator {
  static validConfirmationStrength(
    passwordControl: AbstractControl,
    confirmationControl: AbstractControl
  ): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const password = passwordControl.value;
      const confirmation = confirmationControl.value;
      return password === confirmation
        ? null
        : CustomValidators.buildError('password-confirmation', true);
    };
  }
}
