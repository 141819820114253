import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';

@Injectable()
export class PersonControlsService {
  private readonly baseUrl = '/api/controlTypes';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getControlTypes(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<string[]>(url)));
  }
}
