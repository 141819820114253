import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {
  CoreConfigService,
  FormStep,
  HubSpotChatConfig,
  RegistrationProfile,
  RegistrationStep,
  SessionService,
  User
} from 'base';
import {MRegistrationProfileSelectStepOneComponent} from './components/m-registration-profile-select-step-one/m-registration-profile-select-step-one.component';
import {MRegistrationUserCreationStepTwoComponent} from './components/m-registration-user-creation-step-two/m-registration-user-creation-step-two.component';
import {MRegistrationCompanyCreationStepThreeComponent} from './components/m-registration-company-creation-step-three/m-registration-company-creation-step-three.component';
import {MRegistrationPaiementStepFourComponent} from './components/m-registration-paiement-step-four/m-registration-paiement-step-four.component';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyRegistration} from '../../domain/registration/CompanyRegistration';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['register.component.scss']
})
export class RegisterComponent implements OnInit {
  stepData: StepData = new StepData();

  stepsConfig = [
    new FormStep(
      'register.step.one.breadcrumb.title',
      MRegistrationProfileSelectStepOneComponent
    ),
    new FormStep(
      'register.step.two.breadcrumb.title',
      MRegistrationUserCreationStepTwoComponent
    ),
    new FormStep(
      'register.step.three.breadcrumb.title',
      MRegistrationCompanyCreationStepThreeComponent
    ),
    new FormStep(
      'register.step.four.breadcrumb.title',
      MRegistrationPaiementStepFourComponent
    )
  ];

  currentIndexStep = 0;
  currentStep: RegistrationStep = RegistrationStep.STEP_ONE;
  registrationStep = RegistrationStep;
  alreadyRegistered = false;

  constructor(
    private sessionService: SessionService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private coreConfigService: CoreConfigService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.skipFirstStepIfRegistrationProfileIsSet();
    this.loadUser();
    this.loadHubspotChat();
  }

  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script: HTMLScriptElement = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  loadHubspotChat(): void {
    this.coreConfigService
      .getHubspotChatConfig()
      .subscribe((response: HubSpotChatConfig) => {
        if (response.enabled) {
          this.loadJsScript(
            this.renderer,
            `https://js-eu1.hs-scripts.com/${response.apiKey}.js`
          );
        }
      });
  }

  registrationStepToIndex(registrationStep: RegistrationStep): number {
    let index = 0;
    switch (registrationStep) {
      case RegistrationStep.STEP_ONE:
        index = 0;
        break;
      case RegistrationStep.STEP_TWO:
        index = 1;
        break;
      case RegistrationStep.STEP_THREE:
        index = 2;
        break;
      case RegistrationStep.STEP_FOUR:
        index = 3;
        break;
    }
    return index;
  }

  loadUser(): void {
    this.sessionService.currentUser().subscribe((user: User) => {
      if (
        user.userRegistrationProgress != null &&
        !user.userRegistrationProgress.isFinish
      ) {
        this.currentStep = user.userRegistrationProgress.currentStep;
        this.stepData.registrationProfile =
          user.userRegistrationProgress.registrationProfile;
        this.currentIndexStep = this.registrationStepToIndex(this.currentStep);
      } else {
        this.alreadyRegistered = true;
      }
    });
  }

  redirectToLogin(): void {
    this.router.navigate(['']).then(() => {
      // Reload page to remove chat from hubspot
      window.location.reload();
    });
  }

  private skipFirstStepIfRegistrationProfileIsSet(): void {
    if (this.currentStep == RegistrationStep.STEP_ONE) {
      this.loadRegistrationProfile();
    }
  }

  private loadRegistrationProfile(): void {
    this.route.queryParams.subscribe((queryParams) => {
      const profileQuery = queryParams['registrationProfile'] as string;
      const registrationProfile = RegistrationProfile[profileQuery];

      if (registrationProfile != null) {
        this.stepData.registrationProfile = registrationProfile;
        this.currentStep = RegistrationStep.STEP_TWO;
        this.currentIndexStep = this.registrationStepToIndex(this.currentStep);
        this.clearRegistrationProfileQueryParam();
      } else if (profileQuery != null) {
        this.clearRegistrationProfileQueryParam();
      }
    });
  }

  private clearRegistrationProfileQueryParam(): void {
    this.router.navigate([], {
      queryParams: {
        registrationProfile: null
      },
      queryParamsHandling: 'merge'
    });
  }
}

export class StepData {
  registrationProfile: RegistrationProfile;
  companyRegistration: CompanyRegistration = new CompanyRegistration();
  canAccessNextStep = true;
}
