import {Exclude, Expose} from 'class-transformer';
import {Uri} from './Uri';
import {Entity} from './Entity';
import {UtilsService} from '../services/utils.service';
import {ClassedEntity} from './ClassedEntity';

export class Reference<T extends Entity> {
  @Exclude() private readonly _uri: Uri;
  @Exclude() private readonly _entity: T;

  constructor();
  constructor(uri: Uri);
  constructor(entity: T);
  constructor(uri: Uri, entity: T);
  constructor(uriOrEntity?: Uri | T, entity?: T) {
    this._uri = null;
    this._entity = null;

    if (UtilsService.exists(uriOrEntity)) {
      if (uriOrEntity instanceof Uri) {
        this._uri = uriOrEntity;
      } else {
        this._entity = uriOrEntity;
        this._uri = new Uri(
          Reference.getModuleFromEntity(this._entity),
          Reference.getCollectionFromEntity(this._entity),
          this._entity.id
        );
      }
    }

    if (UtilsService.exists(entity)) {
      this._entity = entity;
    }
  }

  public static of<X extends ClassedEntity>(uri: Uri): Reference<X>;
  public static of<X extends ClassedEntity>(entity: X): Reference<X>;
  public static of<X extends ClassedEntity>(uri: Uri, entity: X): Reference<X>;
  public static of<X extends ClassedEntity>(
    uriOrEntity: Uri | any,
    entity?: X
  ): Reference<X> {
    return new Reference<X>(uriOrEntity, entity);
  }

  public static isReference(value: any): value is Reference<any> {
    return (
      UtilsService.exists(value) &&
      (value instanceof Reference || UtilsService.isFunction(value.isResolved))
    );
  }

  public static getModuleFromEntity(entity: Entity) {
    // Here we supposed the entity class has a property COLLECTION defined.
    return entity.constructor['MODULE'];
  }

  public static getCollectionFromEntity(entity: Entity) {
    // Here we supposed the entity class has a property COLLECTION defined.
    return entity.constructor['COLLECTION'];
  }

  public static isResolved(reference: Reference<any>) {
    return UtilsService.exists(reference.entity);
  }

  public static hasUri(reference: Reference<any>): boolean {
    return UtilsService.exists(reference.uri);
  }

  @Expose()
  public get uri(): Uri {
    return this._uri;
  }

  @Expose()
  public get entity(): T {
    return this._entity;
  }

  public get id(): string {
    return this.isResolved()
      ? this.entity.id
      : UtilsService.exists(this.uri)
      ? this.uri.id
      : null;
  }

  public isResolved(): boolean {
    return Reference.isResolved(this);
  }

  public hasUri(): boolean {
    return Reference.hasUri(this);
  }

  public toJSON(): string {
    if (this.isResolved()) {
      return Uri.serialize(
        Reference.getModuleFromEntity(this.entity),
        Reference.getCollectionFromEntity(this.entity),
        this.entity.id
      );
    }

    if (this.hasUri()) {
      return this.uri.toString();
    }

    return 'error: not resolved and has not uri';
  }

  public toString(): string {
    return this.toJSON();
  }

  public isEqualTo(otherRef: Reference<T>): boolean {
    return (
      otherRef &&
      otherRef._uri &&
      this._uri.toString() === otherRef._uri.toString()
    );
  }
}
