import {Transform} from 'class-transformer';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {PersonDataSource} from '../data-source/PersonDataSource';
import {Reference} from '../../../../../domain/Reference';

export class DataSourceScoringConfig extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'scoringConfig';

  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  public personDataSourceRef: Reference<PersonDataSource>;
  public controlsWeight: Map<string, number> = new Map();

  constructor() {
    super(
      'com.beclm.core.api.scoringConfig.ScoringConfig'
    );
  }
}
