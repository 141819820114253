import {CodedValue} from '../../CodedValue';
import {Type} from 'class-transformer';
import {Contract} from './Contract';

export class ProfessionalInformation {
  professionalStatus: string;
  career: string;
  sirenCode: string;

  @Type(() => CodedValue)
  naf: CodedValue[] = new Array<CodedValue>();

  @Type(() => CodedValue)
  socialProfessionalCategory: CodedValue[] = new Array<CodedValue>();

  expectedRetirementAge: string;

  @Type(() => Contract)
  contract: Contract = new Contract();

  companyAdditionalInformation: string;
  department: string;
  function: string;
  capacityLevel: string;
  isSupervisoryStaff: boolean;
  isRemoteWorking: boolean;

  @Type(() => Date)
  shiftChangeDate: Date;
}
