import {AbstractControl, ValidatorFn} from '@angular/forms';
import {CustomValidators} from 'base';

export class PasswordStrengthValidator {
  static validPasswordStrength(passwordRegex: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const password = abstractControl.value;
      const regexp = new RegExp(
        passwordRegex
      );

      return !password || regexp.test(password)
        ? null
        : CustomValidators.buildError('password-strength', true);
    };
  }
}
