import {ControlType} from './ControlType';
import {PersonDataCategoryType} from '../../data-category/PersonDataCategoryType';
import {ControlField} from './ControlField';
import {Type} from 'class-transformer';

export class ControlResult {
  controlType: ControlType;
  date: string;
  computed: boolean;
  score: number;
  status: string;
  personDataCategoryType: PersonDataCategoryType;
  controlWeight: number;
  @Type(() => ControlField)
  controlField: {[key: string]: ControlField};
  description: string;
  evaluatedScore: number;
}
