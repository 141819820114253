import {TransformationType} from 'class-transformer/TransformOperationExecutor';
import {UtilsService} from '../../services/utils.service';

export abstract class Transformer<T> {
  // 'Or any' is important. It allow to override the signature of toClass in subClasses.
  // See ReferenceTransformer.
  protected abstract toClass(value: T | Array<T> | any): T | Array<T>;

  protected abstract toPlain(value: T | Array<T>): any;

  protected transform(): (
    value: any,
    obj: any,
    transformationType: TransformationType
  ) => any {
    return (
      value: any,
      obj: any,
      transformationType: TransformationType
    ): any => {
      if (!UtilsService.exists(value)) {
        return value;
      }

      switch (transformationType) {
        case TransformationType.CLASS_TO_PLAIN:
          return this.toPlain(value);
        case TransformationType.PLAIN_TO_CLASS:
          return this.toClass(value);
        default:
      }
    };
  }
}
