<label [class.input-group]="hasIcon">
  <div class="input-label" [class.nowrap]="nowrap">
    <div class="input-label-value">
      {{label}}
    </div>
    <div class="input-label-link" *ngIf="displayLink">
      <ng-content select="[link]"></ng-content>
    </div>
  </div>
  <span class="input-group-label" *ngIf="hasIcon"
    ><ng-content select="[icon]"></ng-content
  ></span>
  <div class="input" [class._withIcon]="faIcon" [class._noLabel]="noLabel">
    <!-- required : trick for demo -->
    <input
      #input
      inputNotContainsOnlySpace
      [max]="max"
      [min]="min"
      [value]="value"
      [step]="step"
      [class._withSubLabelEnd]="subLabelEnd"
      [class._delete]="delete"
      [class._search]="search"
      [class._disabled]="disabled"
      [disabled]="disabled"
      [class._form_submitted]="formSubmitted"
      [placeholder]="placeholder"
      [required]="formSubmitted && required"
      [type]="inputType"
      [name]="name"
      [ngModel]="data"
      (ngModelChange)="onChange($event)"
      (blur)="onBlur()"
    />
    <i [ngClass]="faIcon" *ngIf="faIcon"></i>
    <a *ngIf="delete" (click)="onDelete()" class="deleteIcon"
      ><i class="fas fa-times-circle"></i
    ></a>
    <div class="subLabelInput _end" *ngIf="subLabelEnd">{{txtSubLabel}}</div>
  </div>
</label>
