import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslationService {
  private readonly ngxTranslateService: TranslateService;

  constructor(ngxTranslateService: TranslateService) {
    this.ngxTranslateService = ngxTranslateService;
  }

  public get<K, V>(
    key: K | string | Array<string>,
    interpolateParams?: Object
  ): Observable<V | any> {
    return this.ngxTranslateService.get(<string>key, interpolateParams);
  }

  public use(key: string): Observable<any> {
    return this.ngxTranslateService.use(key);
  }

  public setDefaultLang(key: string): void {
    this.ngxTranslateService.setDefaultLang(key);
  }
}
