<form>
  <label class="checkbox" [class]="margin" [class._disabled]="disabled">
    <input
      type="checkbox"
      [ngModel]="checked"
      (ngModelChange)="onCheckedChange($event)"
      [disabled]="disabled"
      name="checkbox"
    />

    <span class="checkbox-skin"></span>

    <span class="checkbox-label" *ngIf="label">
      <div>
        <span [class._titleLabel]="titleLabel">{{label}}</span>
        <span class="checkbox-sublabel" *ngIf="subLabel">{{subLabel}}</span>
      </div>
      <a-link-icon *ngIf="tooltipLabel" [tooltip]="tooltipLabel" size="LARGE">
        <i class="fa fa-info-circle"></i>
      </a-link-icon>
    </span>
  </label>
</form>
