import {Injectable} from '@angular/core';
import {classToPlain, plainToClass} from 'class-transformer';
import {Newable} from '../domain/Newable';

@Injectable()
export class SessionStorageService<T> {
  public get(key: string, newable: Newable<T>): T {
    return plainToClass(newable, JSON.parse(sessionStorage.getItem(key)));
  }

  public set(key: string, serializedClass: T): void {
    if (serializedClass == null) {
      return;
    }
    sessionStorage.setItem(key, JSON.stringify(classToPlain(serializedClass)));
  }

  public delete(key: string): void {
    sessionStorage.removeItem(key);
  }
}
