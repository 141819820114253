import {Injectable} from '@angular/core';
import {ExportDescriptorSearchCriteria} from '../../domain/exportDescriptor/ExportDescriptorSearchCriteria';
import {Observable} from 'rxjs';
import {PaginatedExportHistorics} from '../../domain/exportDescriptor/PaginatedExportHistorics';
import {switchMap} from 'rxjs/operators';
import {SearchParams} from '../../../../domain/SearchParams';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';

@Injectable()
export class ExportTaskService {
  private readonly baseUrl = '/api/export/historic';
  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}
  public getHistoric(params: ExportDescriptorSearchCriteria): Observable<PaginatedExportHistorics> {
    return this.moduleService
      .moduleBackendUrl(params.module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedExportHistorics)
            .get<PaginatedExportHistorics>(`${url}`, {
              params: SearchParams.toHttpParams(params)
            })
        )
      );
  }

  public restartTask(id: string, module: string): Observable<any> {
    return this.moduleService
      .moduleBackendUrl(module, this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .put(`${url}/restart/${id}`, {})
        )
      );
  }

  public getDownloadUrl(id: string, module: string){
    return (`/${module}${this.baseUrl}/download/${id}`); //TODO: use moduleBackendUrl instead
  }
}
