import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fromSize, Size} from '../buttons-utils';
import {QueryParamsHandling} from '@angular/router';

@Component({
  selector: 'a-button-round',
  templateUrl: './a-button-round.component.html',
  styleUrls: ['./a-button-round.component.scss']
})
export class AButtonRoundComponent {
  @Input()
  public disabled = false;

  @Input()
  public validate: boolean;

  @Input()
  public alert: boolean;

  @Input()
  public white: boolean;

  @Input()
  public collapse: boolean;

  @Input()
  public hollow = false;

  @Input()
  public faIcon: string;

  @Input()
  public type: 'button' | 'submit' = 'button';

  @Input()
  public size: Size = 'DEFAULT';
  public fromSize = fromSize;

  @Output()
  public btnClick = new EventEmitter<MouseEvent>();

  @Input()
  public btnRouterLink: any[] | string | null | undefined;

  @Input()
  public btnRouterLinkQueryParams: {
    [key: string]: any;
  };

  @Input()
  btnQueryParamsHandling: QueryParamsHandling
}
