import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PaginatedPersonLinkTypes} from '../../domain/person/person-link/PaginatedPersonLinkTypes';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonLinkTypeModification} from '../../domain/person/person-link/PersonLinkTypeModification';
import {PersonLinkType} from '../../domain/person/person-link/PersonLinkType';
import {Reference} from '../../../../domain/Reference';
import {Company} from '../../domain/company/Company';
import {PersonLinkTypeSearchParam} from '../../domain/person/person-link/PersonLinkTypeSearchParam';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class PersonLinkTypeService {
  private readonly baseUrl = '/api/person-link-type';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public createPersonLinkType(
    personLinkType: PersonLinkTypeModification
  ): Observable<PersonLinkType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonLinkType)
            .post<PersonLinkType>(url, personLinkType)
        )
      );
  }

  public updatePersonLinkType(
    personLinkTypeModification: PersonLinkTypeModification
  ): Observable<PersonLinkType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonLinkType)
            .put<PersonLinkType>(url, personLinkTypeModification)
        )
      );
  }

  public findById(id: string): Observable<PersonLinkType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PersonLinkType)
            .get<PersonLinkType>(url + '/' + id)
        )
      );
  }

  public findPersonLinkTypeForCompanyRef(
    companyRef: Reference<Company>
  ): Observable<PersonLinkType[]> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PersonLinkType)
          .get<PersonLinkType[]>(url + '/companyRef', {
            params: {companyRef: companyRef}
          })
      )
    );
  }

  public find(param: PersonLinkTypeSearchParam): Observable<PersonLinkType[]> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PersonLinkType)
          .get<PersonLinkType[]>(url, {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public paginatedFind(
    param: PersonLinkTypeSearchParam
  ): Observable<PaginatedPersonLinkTypes> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedPersonLinkTypes)
          .get<PaginatedPersonLinkTypes>(url + '/paginated', {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public save(
    personLinkTypeModification: PersonLinkTypeModification
  ): Observable<PersonLinkType> {
    if (personLinkTypeModification.id !== undefined) {
      return this.updatePersonLinkType(personLinkTypeModification);
    } else {
      return this.createPersonLinkType(personLinkTypeModification);
    }
  }
}
