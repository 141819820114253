import {Newable} from '../../Newable';
import {UtilsService} from '../../../services/utils.service';

export abstract class AbstractClassMapper<T> {
  protected constructor(
    protected key: string,
    protected defaultImplem: Newable<T>,
    protected mapping: {[key: string]: Newable<T>}
  ) {}

  public getClass(value: T): Newable<T> {
    if (
      UtilsService.exists(value) &&
      UtilsService.exists(value[this.key]) &&
      UtilsService.exists(this.mapping[value[this.key]])
    ) {
      return this.mapping[value[this.key]];
    }

    return this.defaultImplem;
  }
}
