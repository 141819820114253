import {Injectable} from '@angular/core';
import {noop, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';

@Injectable()
export class NotificationService {
  private translateService: TranslateService;
  private toastrService: ToastrService;

  constructor(
    translateService: TranslateService,
    toastrService: ToastrService
  ) {
    this.translateService = translateService;
    this.toastrService = toastrService;
  }

  public errorWithMessage(message: Observable<string>): Observable<void> {
    return message.pipe(
      map((m) => this.toastrService.error(m)),
      map(() => noop())
    );
  }

  public error(key: string, params?: Object): Observable<void> {
    return this.translateService.get(key, params).pipe(
      map((message) => this.toastrService.error(message)),
      map(() => noop())
    );
  }

  public success(key: string, params?: Object): Observable<void> {
    return this.translateService.get(key, params).pipe(
      map((message) => this.toastrService.success(message)),
      map(() => noop())
    );
  }

  public warning(key: string, params?: Object): Observable<void> {
    return this.translateService.get(key, params).pipe(
      map((message) => this.toastrService.warning(message)),
      map(() => noop())
    );
  }

  public info(key: string, params?: Object): Observable<void> {
    return this.translateService.get(key, params).pipe(
      map((message) => this.toastrService.info(message)),
      map(() => noop())
    );
  }
}
