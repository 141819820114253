<div #topPage>
  <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
</div>

<m-form-step-thread
  [stepsConfig]="stepsConfig"
  [activeFormStepIndex]="activeFormStepIndex"
></m-form-step-thread>

<m-form-step-detail
  *ngIf="activeFormStep"
  [formStep]="activeFormStep"
  [data]="data"
  [isSavable]="isSavable"
  (dataChange)="dataChanged($event)"
  [canAccessNextStep]="canAccessNextStep"
  [isFirstStep]="activeFormStepIndex === 0"
  [isLastStep]="activeFormStepIndex === stepsConfig.length - 1"
  [isValidationStep]="(activeFormStepIndex === stepsConfig.length - 1) || isValidateShown"
  [editable]="editable"
  [forceShowValidationOnLastStep]="forceShowValidationOnLastStep"
  (previous)="setPreviousStep()"
  (next)="setNextStep()"
  (validate)="validateForm()"
  (save)="saveForm()"
  (reset)="resetForm()"
></m-form-step-detail>
