import {ErrorHandler, Injectable} from '@angular/core';
import {ModuleService} from './module.service';
import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';

@Injectable()
export class BeclmErrorHandler implements ErrorHandler {
  private moduleName: string;
  private moduleUiName: string;

  constructor(private moduleService: ModuleService) {
    this.loadModuleName();
    this.loadModuleUiName();
  }

  private loadModuleName() {
    this.moduleService.currentModule.subscribe((module) => {
      this.moduleName = module.name;
    });
  }

  private loadModuleUiName() {
    this.moduleService.getCurrentModuleUi().subscribe((moduleUiName) => {
      this.moduleUiName = moduleUiName;
    });
  }

  public handleError(error): void {
    this.redirectToLoginPageOnUnauthorized(error);
    if (error instanceof HttpErrorResponse && error.status < 500) {
      return;
    }
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }

  private redirectToLoginPageOnUnauthorized(error) {
    if (
      error instanceof HttpErrorResponse &&
      error.status === 401 &&
      this.moduleUiName !== 'user'
    ) {
      this.moduleService
        .userModuleUiUrl('/login')
        .subscribe((url) => (window.location.href = url));
    }
  }
}
