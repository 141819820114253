<select
  [ngModel]="productRefSelected"
  (ngModelChange)="valueSelected($event)"
  [compareWith]="compareProductRef"
  [disabled]="disabled"
>
  <option></option>
  <option [ngValue]="productRef" *ngFor="let productRef of productRefs">
    {{productRef.entity.name}}
  </option>
</select>
