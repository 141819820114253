import {NgModule} from '@angular/core';
import {NoNegativeValue} from './noNegativeValue.directive';

const directives = [NoNegativeValue];

@NgModule({
  imports: [],
  exports: [directives],
  declarations: [directives],
  providers: []
})
export class DirectivesModule {}
