import {Saving} from './Saving';
import {Type} from 'class-transformer';

export class LifeInsurance {
  anteriority: string;
  supportType: string;
  insurer: string;
  @Type(() => Saving)
  savings: Saving = new Saving();
}
