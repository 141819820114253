import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ARadioComponent} from './a-radio/a-radio.component';
import {ACheckboxComponent} from './a-checkbox/a-checkbox.component';
import {AInputComponent} from './a-input/a-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {DpDatePickerModule} from 'ng2-date-picker';
import {ADatePickerComponent} from './a-date-picker/a-date-picker.component';
import {BaseDirectivesModule} from '../../../directives/base.directives.module';
import {TranslateModule} from '@ngx-translate/core';
import {ARadioYesNoComponent} from './a-radio-yes-no/a-radio-yes-no.component';
import {LinksModule} from '../links/links.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {ASwitchInputComponent} from './a-switch-input/a-switch-input.component';

const components = [
  ACheckboxComponent,
  ARadioComponent,
  ARadioYesNoComponent,
  AInputComponent,
  ADatePickerComponent,
  ASwitchInputComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgSelectModule,
    DpDatePickerModule,
    BaseDirectivesModule,
    LinksModule,
    TooltipModule
  ],
  declarations: components,
  exports: components
})
export class UiFormsModule {}
