import {Type} from 'class-transformer';
import {Birth} from './Birth';
import {CodedValue} from '../../CodedValue';

export class Identity {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mobilePhoneNumber: string;

  @Type(() => CodedValue)
  civility: CodedValue;

  @Type(() => Birth)
  birth: Birth = new Birth();

  title: string;
  maidenName: string;
  @Type(() => CodedValue)
  nationality: CodedValue;
  personType: string;
  legalForm: string;
  socialSecurityNumber: string;
  @Type(() => CodedValue)
  judicialProtectionRegime: CodedValue;
}
