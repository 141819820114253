import {ClassedEntity} from '../../../../../../domain/ClassedEntity';
import {NeedCommentType} from './NeedCommentType';
import {Reference} from '../../../../../../domain/Reference';
import {SelectableRef} from '../../../../../../domain/SelectableRef';

export class PresetNeedComment extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'presetNeedComment';

  type: NeedCommentType;
  label: string;
  value: string;

  constructor() {
    super('com.beclm.core.api.producttype.presetNeedComment.PresetNeedComment');
  }

  public static toSelectableRef(
    reference: Reference<PresetNeedComment>
  ): SelectableRef<PresetNeedComment> {
    const selectableRef = new SelectableRef<PresetNeedComment>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.label;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<PresetNeedComment> {
    const selectableRef = new SelectableRef<PresetNeedComment>();
    selectableRef.id = new Reference<PresetNeedComment>(this);
    selectableRef.__label = this.label;
    return selectableRef;
  }
}
