import {Type} from 'class-transformer';
import {PaginatedResults} from '../../../../../domain/PaginatedResults';
import {Person} from './Person';
import {NaturalPerson} from './natural/NaturalPerson';
import {LegalEntity} from './legal-entity/LegalEntity';

export class PaginatedPersons extends PaginatedResults<Person> {
  @Type(() => Person, {
    discriminator: {
      property: '@class',
      subTypes: [
        {
          value: NaturalPerson,
          name: 'com.beclm.core.api.person.naturalPerson.NaturalPerson'
        },
        {
          value: LegalEntity,
          name: 'com.beclm.core.api.person.legalEntity.LegalEntity'
        }
      ]
    }
  })
  public results: Person[];
}
