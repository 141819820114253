import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class Product extends ClassedEntity {
  public static readonly MODULE: string = 'lab';
  public static readonly COLLECTION: string = 'product';

  public name: string;

  constructor() {
    super('com.beclm.lab.domain.product.Product');
  }
}
