import {Transform, Type} from 'class-transformer';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {BlobItem} from '../../../../domain/BlobItem';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../company/Company';
import {Reference} from '../../../../domain/Reference';
import {SubscriptionProposalSyntecData} from './SubscriptionProposalSyntecData';
import {SubscriptionProposalPreviousData} from './SubscriptionProposalPreviousData';
import {SubscriptionProposalReminderData} from './SubscriptionProposalReminderData';

export class SubscriptionProposal extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'subscriptionProposal';

  id: string;
  @Type(() => Date)
  creationDate: Date;
  @Type(() => Date)
  proposalDate: Date;
  @Type(() => Date)
  paymentDate: Date;
  @Type(() => Date)
  companyAdminOpeningDate: Date;
  subscriptionObject: string;
  preTaxValue: number;
  taxedValue: number;
  vat = 20;
  reference: string;
  description: string;
  status: string;
  @Type(() => BlobItem)
  attachmentBlobItem: BlobItem;
  @Type(() => BlobItem)
  invoiceBlobItem: BlobItem;
  invoiceSubscriptionId: string;
  paymentUrl: string;
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  module: string;
  duration: number;
  maxRecordNumber: number;
  isRecurrent = false;
  automaticModuleDisabling = false;
  @Type(() => Date)
  automaticPaymentDate: Date;
  @Type(() => SubscriptionProposalSyntecData)
  syntecData: SubscriptionProposalSyntecData = new SubscriptionProposalSyntecData();
  @Type(() => SubscriptionProposalPreviousData)
  previousData: SubscriptionProposalPreviousData;
  @Type(() => SubscriptionProposalReminderData)
  paymentReminderData: SubscriptionProposalReminderData;

  constructor() {
    super('com.beclm.core.api.subscriptionProposal.SubscriptionProposal');
  }
}
