<div class="grid-x grid-padding-x">
  <div class="cell small-24">
    <a-title-view
      [titleKey]="'register.step.one.title' | translate"
    ></a-title-view>
    <b>{{'register.step.one.title.information' | translate}}</b>
    <spacer [size]="16"></spacer>
  </div>

  <div class="cell small-up-1 medium-up-2 grid-x grid-padding-x">
    <div *ngFor="let registrationProfile of registrationProfiles" class="cell">
      <div class="card">
        <div
          (click)="selectRegistrationProfile(registrationProfile)"
          class="card-section grid-x text-center modelCompany"
        >
          <div class="cell small-24 text-left">
            <span class="modelCompany-label"
              >{{'register.profile.' + registrationProfile | translate}}</span
            >
            <small
              >{{'register.profile.' + registrationProfile + '.information' |
              translate}}</small
            >
          </div>
          <div class="cell small-24 text-right">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cell small-24">
    <spacer [size]="8"></spacer>
    <m-callout [calloutTxtAlign]="'LEFT'" [calloutSize]="'SMALL'">
      <ng-container txt>
        <div [innerHtml]="'register.step.one.footer' | translate"></div>
      </ng-container>
    </m-callout>
    <spacer [size]="16"></spacer>
  </div>
</div>
