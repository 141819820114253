import {Component} from '@angular/core';
import {RegistrationProfile, StepSingleFormComponent} from 'base';
import {StepData} from '../../register.component';

@Component({
  selector: 'm-registration-profile-select-step-one',
  templateUrl: './m-registration-profile-select-step-one.component.html',
  styleUrls: ['./m-registration-profile-select-step-one.component.scss']
})
export class MRegistrationProfileSelectStepOneComponent extends StepSingleFormComponent<StepData> {
  automaticNext = true;
  registrationProfiles = Object.keys(RegistrationProfile);

  selectRegistrationProfile(registrationProfile: RegistrationProfile): void {
    this.data.registrationProfile = registrationProfile;
    this.forceOnNext.emit();
  }
}
