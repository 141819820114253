import {Saving} from './Saving';
import {Type} from 'class-transformer';

export class FinancialWealth {
  @Type(() => Saving)
  liquidity: Saving = new Saving();
  @Type(() => Saving)
  savingsPassbook: Saving = new Saving();
  @Type(() => Saving)
  realEstateSavings: Saving = new Saving();
  @Type(() => Saving)
  salarySavings: Saving = new Saving();
  @Type(() => Saving)
  titleAccountsAmount: Saving = new Saving();
}
