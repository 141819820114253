import * as moment from 'moment';
import {Moment} from 'moment';

export class LocalDate {
  private readonly moment: Moment;

  static fromMoment(moment: Moment) {
    return new LocalDate(moment);
  }

  static fromString(value?: string, format = 'YYYY-MM-DD'): LocalDate {
    if (value) {
      return new LocalDate(moment(value, format));
    } else {
      return new LocalDate(moment());
    }
  }

  static fromAny(obj: any): LocalDate {
    return new LocalDate(moment(obj.moment._d));
  }

  constructor(_moment?: Moment) {
    if (_moment) {
      this.moment = _moment;
    } else {
      this.moment = moment();
    }
  }

  public toISOString(): string {
    return this.moment.format('YYYY-MM-DD');
  }

  public format(format: string): string {
    return this.moment.format(format);
  }

  public toDate(): Date {
    let date = this.moment.toDate();
    return date;
  }

  public toMoment(): Moment {
    return this.moment;
  }
}
