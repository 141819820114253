import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Directive, Injectable} from '@angular/core';
import {CustomValidators} from '../../utils/CustomValidators';

@Directive({
  selector: '[inputNotContainsOnlySpace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputNotContainsOnlySpaceDirective,
      multi: true
    }
  ]
})
@Injectable()
export class InputNotContainsOnlySpaceDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (CustomValidators.containsOnlySpace(control)) {
      return CustomValidators.buildError('inputNotContainsOnlySpace', true);
    }

    return null;
  }
}
