import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {
  ControlScoresEvaluationConfig
} from '../../domain/person/control-scores-evaluation-config/ControlScoresEvaluationConfig';

@Injectable({
  providedIn: 'root'
})
export class ScoresEvaluationConfigService {
  private readonly baseUrl: string = '/api/control-score-evaluation-config';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getControlScoreEvaluationConfig(): Observable<ControlScoresEvaluationConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlScoresEvaluationConfig)
            .get<ControlScoresEvaluationConfig>(
              `${url}/current`
            )
        )
      );
  }

  public updateControlScoreEvaluationConfig(config: ControlScoresEvaluationConfig): Observable<ControlScoresEvaluationConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlScoresEvaluationConfig)
            .put<ControlScoresEvaluationConfig>(
              `${url}`, config
            )
        )
      );
  }

}
