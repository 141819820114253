import {Code} from './CodedValue';
import {Type} from 'class-transformer';
import {HierarchizedCode} from './HierarchizedCode';
import {ScoreType} from './control/ScoreType';

export class ScoredCodedValue {
  @Type(() => Code, {
    discriminator: {
      property: '@class',
      subTypes: [
        {
          value: HierarchizedCode,
          name: 'com.beclm.base.api.codedValue.HierarchizedCode'
        },
        {
          value: Code,
          name: 'com.beclm.base.api.codedValue.Code'
        }
      ]
    }
  })
  code: Code;
  score: number;
  scoreType: ScoreType;
}


