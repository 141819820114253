import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {CustomValidators} from '../utils/CustomValidators';

export class SirenIsValidValidator {
  validate(control: AbstractControl): ValidationErrors {
    return this.sirenIsValidValidatorFn(control);
  }

  sirenIsValidValidatorFn(control: AbstractControl): ValidatorFn {
    if (
      control.value !== null &&
      control.value !== undefined &&
      !this.sirenIsValid(control.value)
    ) {
      return CustomValidators.buildError('sirenInvalid', true);
    } else {
      return null;
    }
  }

  sirenIsValid(value: string): boolean {
    return (
      value.toString().length === 9 &&
      !!value.toString().match(RegExp('^[0-9]{9}$'))
    );
  }
}
