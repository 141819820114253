import {BlacklistMatchingBatchConfig} from './BlacklistMatchingBatchConfig';
import {PEPMatchingBatchConfig} from './PEPMatchingBatchConfig';
import {Type} from 'class-transformer';
import {AdverseMediaMatchingBatchConfig} from './AdverseMediaMatchingBatchConfig';
import {DataSourceType} from '../../data-source/DataSourceType';

export class BatchConfig {
  @Type(() => BlacklistMatchingBatchConfig)
  blacklistMatchingBatchConfig: BlacklistMatchingBatchConfig = new BlacklistMatchingBatchConfig();
  @Type(() => PEPMatchingBatchConfig)
  pepMatchingBatchConfig: PEPMatchingBatchConfig = new PEPMatchingBatchConfig();
  @Type(() => AdverseMediaMatchingBatchConfig)
  adverseMediaMatchingBatchConfig: AdverseMediaMatchingBatchConfig = new AdverseMediaMatchingBatchConfig();

  getConfigurationFromType(dataSourceType: DataSourceType): BlacklistMatchingBatchConfig | PEPMatchingBatchConfig | AdverseMediaMatchingBatchConfig {
    if (dataSourceType === DataSourceType.BLACKLIST) {
      return this.blacklistMatchingBatchConfig;
    } else if (dataSourceType === DataSourceType.PEP) {
      return this.pepMatchingBatchConfig;
    } else {
      return this.adverseMediaMatchingBatchConfig;
    }
  }
}
