export class CompanyRegistration {
  siren: string;
  name: string;
  address: CompanyRegistrationAddress = new CompanyRegistrationAddress();
  headcount: number;
  ageaNumber: string;
}

export class CompanyRegistrationAddress {
  address: string;
  zipCode: string;
  city: string;
}

