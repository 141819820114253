import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private translateService: TranslateService) {}

  public showConfirmDialog(
    message: string,
    params: any,
    title?: string,
    titleParams?: any
  ): Observable<boolean> {
    return from(this.confirmDialog(message, params, title, titleParams)).pipe(
      map((result: SweetAlertResult) => !result.dismiss)
    );
  }

  private async confirmDialog(
    message: string,
    params: any,
    title?: string,
    titleParams?: any
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text: message,
      icon: 'question',
      heightAuto: false,
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('common.cancel'),
      confirmButtonText: `<i class="fa fa-check"></i> ${this.translateService.instant(
        'common.continue'
      )}`
    });
  }

  public showInformDialog(
    message: string,
    params: any,
    title?: string,
    titleParams?: any
  ): Observable<boolean> {
    return from(this.informDialog(message, title)).pipe(
      map((result: SweetAlertResult) => !result.dismiss)
    );
  }

  private async informDialog(
    message: string,
    title?: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text: message,
      icon: 'info',
      heightAuto: false,
      confirmButtonText: `<i class="fa fa-check"></i> ${this.translateService.instant(
        'common.ok'
      )}`
    });
  }
}
