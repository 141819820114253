import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequestTransformer} from '../../domain/http/HttpRequestTransformer';
import {take} from 'rxjs/operators';
import {ClassMapper} from '../../domain/transformation/class-mapper/ClassMapper';
import {Newable} from '../../domain/Newable';

@Injectable()
export class HttpHandlerService {
  constructor(private readonly httpClient: HttpClient) {}

  public transformResponseTo<T>(
    classReference: Newable<T>,
    classMapper: ClassMapper<T> = new ClassMapper(classReference)
  ): HttpRequestTransformer<T> {
    return new HttpRequestTransformer<T>(
      this.httpClient,
      classMapper
    );
  }

  // TODO@DEV: Refactor classMapper to Service
  public transformResponseWith<T>(
    classMapper: Newable<ClassMapper<T>>
  ): HttpRequestTransformer<T> {
    return new HttpRequestTransformer<T>(this.httpClient, new classMapper());
  }

  public get<T>(...args: any[]): Observable<T> {
    return this.httpClient.get.call(this.httpClient, ...args).pipe(take(1));
  }

  public post<T>(...args: any[]): Observable<T> {
    return this.httpClient.post.call(this.httpClient, ...args).pipe(take(1));
  }

  public put<T>(...args: any[]): Observable<T> {
    return this.httpClient.put.call(this.httpClient, ...args).pipe(take(1));
  }

  public delete<T>(...args: any[]): Observable<T> {
    return this.httpClient.delete.call(this.httpClient, ...args).pipe(take(1));
  }
}
