import {ControlType} from './ControlType';
import {PersonDataCategoryType} from '../../data-category/PersonDataCategoryType';
import {ControlInputType} from './ControlInputType';
import {ScoreType} from './ScoreType';

export class ControlInput {
  controlType: ControlType;
  personDataCategoryType: PersonDataCategoryType;
  fields: ControlInputType[];
  scoreType: ScoreType
}
