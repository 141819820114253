import {Entity} from './Entity';
import {UtilsService} from '../services/utils.service';

export abstract class ClassedEntity extends Entity {
  public readonly '@class': string;

  public constructor(FQDN?: string) {
    super();
    if (FQDN) {
      this['@class'] = FQDN;
    }
  }

  public static className(entity: any): string {
    if (
      UtilsService.exists(entity) &&
      UtilsService.isString(entity['@class'])
    ) {
      return entity['@class'].split('.').pop();
    }

    return null;
  }
}
