import {NgModule} from '@angular/core';
import {CopyClipboardDirective} from './copy-clipboard.directive';
import {UserEmailIsValidValidatorDirective} from './validation/user-email-is-valid.validator.directive';
import {UniqueUserEmailValidatorDirective} from './validation/unique-user-email.validator.directive';
import {SirenIsValidValidatorDirective} from './validation/siren-is-valid.validator.directive';
import {UniqueSirenValidatorDirective} from './validation/unique-siren.validator.directive';
import {HideOnOverDirective} from './hide-on-over.directive';
import {HideOnScrollDownDirective} from './hide-on-scroll-down.directive';
import {InputNotContainsOnlySpaceDirective} from './validation/input-not-contains-only-space.directive';
import {UniqueDatasourceKeyValidatorDirective} from './validation/unique-datasource-key.validator.directive';
import {NotPriorToStartDateValidatorDirective} from './validation/not-prior-to-start-date-validator.directive';
import {NotPriorToTodayDateValidatorDirective} from './validation/not-prior-to-today-date-validator.directive';
import {ValueIsInRangeValidatorDirective} from './validation/value-is-in-range.validator.directive';
import {NumberIsIntValidatorDirective} from './validation/number-is-int.validator.directive';

const directives = [
  CopyClipboardDirective,
  UserEmailIsValidValidatorDirective,
  UniqueUserEmailValidatorDirective,
  SirenIsValidValidatorDirective,
  UniqueSirenValidatorDirective,
  HideOnOverDirective,
  HideOnScrollDownDirective,
  InputNotContainsOnlySpaceDirective,
  UniqueDatasourceKeyValidatorDirective,
  NotPriorToStartDateValidatorDirective,
  NotPriorToTodayDateValidatorDirective,
  ValueIsInRangeValidatorDirective,
  NumberIsIntValidatorDirective
];

@NgModule({
  imports: [],
  exports: [directives],
  declarations: [directives],
  providers: []
})
export class BaseDirectivesModule {}
