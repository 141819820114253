import {WebHookConfig} from '../../../../domain/WebHookConfig';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {PersonDataSource} from '../person/data-source/PersonDataSource';
import {Reference} from '../../../../domain/Reference';

export class CoreWebHookConfig extends WebHookConfig {
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  applicableDataSources: Reference<PersonDataSource>[] = [];
}
