import {Type} from 'class-transformer';
import {ApiType} from '../../../../../core-shared/domain/apiUser/ApiType';
import {ApiStatus} from './ApiStatus';

export class ExternalApiRequestStatus {
  apiType: ApiType;
  @Type(() => Date)
  lastSuccessfulRequest: Date;
  @Type(() => Date)
  lastRequest: Date;
  status: ApiStatus;
  message: string;
}
