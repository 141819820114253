import {Type} from 'class-transformer';
import {CompanyType} from './company-type/CompanyType';
import {CompanySubscriptionProposalData} from './CompanySubscriptionProposalData';

export class CompanySearchResult {
  public name: string;
  @Type(() => CompanyType)
  public type: CompanyType = new CompanyType();
  public authorizedModules: Array<string>;
  public toDelete: boolean;
  public isBillable: boolean;
  @Type(() => CompanySubscriptionProposalData)
  public lastSubscriptionProposalData: CompanySubscriptionProposalData;
}
