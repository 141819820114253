import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {PersonDataCategory} from '../data-category/PersonDataCategory';
import {PersonDataSource} from '../data-source/PersonDataSource';
import {Reference} from '../../../../../domain/Reference';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {NaturalPerson} from './natural/NaturalPerson';
import {LegalEntity} from './legal-entity/LegalEntity';
import {PersonType} from './PersonType';
import {ControlResult} from './control/ControlResult';
import {SelectableRef} from '../../../../../domain/SelectableRef';
import {PersonRiskStatus} from './risk/PersonRiskStatus';

export class Person extends ClassedEntity {
  @Type(() => PersonDataCategory)
  personDataCategories: {[key: string]: PersonDataCategory} = {};
  externalRefId: string;
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;
  editable = false;
  riskStatus: PersonRiskStatus;
  @Type(() => Date)
  lastControlDate: Date;
  personDataCategoriesToBeComputed: boolean = false;

  constructor(FQDN: string) {
    super(FQDN);
  }

  public static toSelectableRef(
    reference: Reference<Person>
  ): SelectableRef<Person> {
    if (reference.entity instanceof NaturalPerson) {
      const naturalReference = reference as Reference<NaturalPerson>;
      const selectableRef = new SelectableRef<NaturalPerson>();
      selectableRef.id = naturalReference;
      if (naturalReference.isResolved()) {
        selectableRef.__label =
          naturalReference.entity.identity.email +
          ' - ' +
          naturalReference.entity.identity.lastName +
          ' ' +
          naturalReference.entity.identity.firstName;
      } else {
        selectableRef.__label = naturalReference.id;
      }
      return selectableRef;
    } else if (reference.entity instanceof LegalEntity) {
      const selectableRef = new SelectableRef<LegalEntity>();
      const legalEntityReference = reference as Reference<LegalEntity>;
      selectableRef.id = legalEntityReference;
      if (legalEntityReference.isResolved()) {
        selectableRef.__label = legalEntityReference.entity.name;
      } else {
        selectableRef.__label = legalEntityReference.id;
      }
      return selectableRef;
    }
  }

  public toSelectableRef(): SelectableRef<Person> {
    if (this instanceof NaturalPerson) {
      const selectableRef = new SelectableRef<Person>();
      selectableRef.id = new Reference<Person>(this);
      selectableRef.__label =
        this.identity.email +
        ' - ' +
        this.identity.lastName +
        ' ' +
        this.identity.firstName;
      return selectableRef;
    } else if (this instanceof LegalEntity) {
      const selectableRef = new SelectableRef<LegalEntity>();
      selectableRef.id = new Reference<LegalEntity>(this);
      selectableRef.__label = this.name;
      return selectableRef;
    }
  }

  public getName(): string {
    if (this instanceof NaturalPerson) {
      let firstName = '';
      if (this.identity.firstName) {
        firstName = this.identity.firstName;
      }
      return `${firstName} ${this.identity.lastName}`;
    } else if (this instanceof LegalEntity) {
      return this.name;
    }
  }

  public getType(): string {
    if (this instanceof NaturalPerson) {
      return PersonType.NATURAL;
    } else if (this instanceof LegalEntity) {
      return PersonType.LEGAL;
    }
  }

  public getCreationDate(): string {
    if (this instanceof NaturalPerson && this.identity.birth.date) {
      return this.identity.birth.date.toISOString();
    } else if (this instanceof LegalEntity) {
      if (this.creationDate) {
        return this.creationDate.toISOString();
      } else if (this.companyExternalData.personCompanyInfo.creationDate) {
        return this.companyExternalData.personCompanyInfo.creationDate;
      }
    }
  }

  public getControlResults(): {[key: string]: ControlResult} {
    if (this instanceof NaturalPerson) {
      const cat = this.personDataCategories['MAIN_INFORMATION'];
      return cat ? cat.controlResults : null;
    } else if (this instanceof LegalEntity) {
      const cat = this.personDataCategories['LEGAL_ENTITY'];
      return cat ? cat.controlResults : null;
    }
  }
}
