import {ApiType} from './ApiType';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {Company} from '../../../core/domain/company/Company';
import {SelectableRef} from '../../../../domain/SelectableRef';

export class ApiUser extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'apiUser';

  login: string;
  password: string;
  apiType: ApiType;
  quotaUsed: number;
  isShared: boolean;
  authorizedCompanies: Array<Reference<Company>> = [];

  constructor() {
    super('com.beclm.core.api.apiUser.ApiUser');
  }

  public static toSelectableRef(
    reference: Reference<ApiUser>
  ): SelectableRef<ApiUser> {
    const selectableRef = new SelectableRef<ApiUser>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.login;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<ApiUser> {
    const selectableRef = new SelectableRef<ApiUser>();
    selectableRef.id = new Reference<ApiUser>(this);
    selectableRef.__label = this.login;
    return selectableRef;
  }
}
