import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PersonDataSourceService} from './person-data-source.service';
import {LegalEntitySearchParam} from '../../domain/person/person/legal-entity/LegalEntitySearchParam';
import {PaginatedLegalEntities} from '../../domain/person/person/legal-entity/PaginatedLegalEntities';
import {LegalEntity} from '../../domain/person/person/legal-entity/LegalEntity';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {SearchParams} from '../../../../domain/SearchParams';
import {LegalEntityModification} from '../../domain/person/person/legal-entity/LegalEntityModification';
import {Reference} from '../../../../domain/Reference';
import {PersonRiskStatus} from '../../domain/person/person/risk/PersonRiskStatus';

@Injectable()
export class LegalEntityService {
  private readonly baseUrl = '/api/legal-entities';
  private _currentLegalEntity = new BehaviorSubject<LegalEntity>(undefined);

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    private personDataSourceService: PersonDataSourceService
  ) {}

  public loadCurrentLegalEntity(person: LegalEntity) {
    this._currentLegalEntity.next(person);

    const subscription = timer(500, 1000).subscribe((t) => {
      this.getById(person.id).subscribe((personControlled) => {
        if (!personControlled.personDataCategoriesToBeComputed) {
          this._currentLegalEntity.next(personControlled);
          subscription.unsubscribe();
        }
      });
    });
  }

  public observeCurrentLegalEntity(): Observable<LegalEntity> {
    return this._currentLegalEntity;
  }

  public unloadCurrentLegalEntity(): void {
    this._currentLegalEntity.next(undefined);
  }

  public getCurrentOrLoadLegalEntity(id: string): Observable<LegalEntity> {
    if (
      this._currentLegalEntity.getValue() === undefined ||
      this._currentLegalEntity.getValue().id !== id
    ) {
      this.getById(id).subscribe((legalEntity) => {
        this.loadCurrentLegalEntity(legalEntity);
        return this._currentLegalEntity;
      });
    }
    return this._currentLegalEntity;
  }

  public findPaginated(
    param: LegalEntitySearchParam
  ): Observable<PaginatedLegalEntities> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedLegalEntities)
          .get<PaginatedLegalEntities>(url + '/paginated/', {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public find(param?: LegalEntitySearchParam): Observable<LegalEntity[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .get<LegalEntity[]>(url, {params: SearchParams.toHttpParams(param)})
        )
      );
  }

  public getById(id: string): Observable<LegalEntity> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .get<LegalEntity>(url + '/' + id)
        )
      );
  }

  public createLegalEntity(
    legalEntityModification: LegalEntityModification
  ): Observable<LegalEntity> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(LegalEntity)
          .post<LegalEntity>(url, legalEntityModification, {
            params: {
              updateFromUi: true
            }
          })
      )
    );
  }

  public deleteLegalEntity(id: string): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.delete<string>(url + '/' + id)));
  }

  public updateLegalEntity(
    legalEntityModification: LegalEntityModification
  ): Observable<LegalEntity> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(LegalEntity)
          .put<LegalEntity>(url, legalEntityModification, {
            params: {
              updateFromUi: true
            }
          })
      )
    );
  }

  public save(
    legalEntityModification: LegalEntityModification
  ): Observable<LegalEntity> {
    legalEntityModification.personDataSourceRef = new Reference(
      this.personDataSourceService.getCurrentLegalEntityDataSource()
    );
    if (legalEntityModification.id !== undefined) {
      return this.updateLegalEntity(legalEntityModification);
    } else {
      return this.createLegalEntity(legalEntityModification);
    }
  }

  public computeIdentificationCompleteness(
    legalEntity: LegalEntityModification
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Number)
            .post<number>(url + '/completeness/identification', legalEntity)
        )
      );
  }

  public manualQualification(
    legalEntityId: string,
    personRiskStatus: PersonRiskStatus
  ): Observable<LegalEntity> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(LegalEntity)
          .put<LegalEntity>(
            url + '/' + legalEntityId + '/qualification',
            null,
            {
              params: {
                personRiskStatus: personRiskStatus
              }
            }
          )
      )
    );
  }

  public getEnrichedLegalEntity(
    legalEntity: LegalEntityModification
  ): Observable<LegalEntity> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntityModification)
            .put<LegalEntity>(url + '/enriched/from-siren', legalEntity)
        )
      );
  }
}
