import {Component, Input} from '@angular/core';

@Component({
  selector: 'm-callout',
  templateUrl: './m-callout.component.html',
  styleUrls: ['./m-callout.component.scss']
})
export class MCalloutComponent {
  @Input()
  public type: Type = 'INFO';
  public fromType = fromType;

  @Input()
  public calloutSize: CalloutSize = 'DEFAULT';
  public fromCalloutSize = fromCalloutSize;

  @Input()
  public calloutTxtAlign: CalloutTxtAlign = 'LEFT';
  public fromCalloutTxtAlign = fromCalloutTxtAlign;
}

export type Type = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR' | 'NOTICE';

export function fromType(type: Type): string {
  switch (type) {
    case 'INFO':
      return '_info';
    case 'SUCCESS':
      return '_success';
    case 'WARNING':
      return '_warning';
    case 'ERROR':
      return '_error';
    case 'NOTICE':
      return '_info';
  }
}

export type CalloutSize = 'SMALL' | 'DEFAULT';

export function fromCalloutSize(calloutSize: CalloutSize): string {
  switch (calloutSize) {
    case 'SMALL':
      return '_small';
    case 'DEFAULT':
      return '_default';
  }
}

export type CalloutTxtAlign = 'CENTER' | 'LEFT' | 'RIGHT';

export function fromCalloutTxtAlign(calloutTxtAlign: CalloutTxtAlign): string {
  switch (calloutTxtAlign) {
    case 'CENTER':
      return '_center';
    case 'LEFT':
      return '_left';
    case 'RIGHT':
      return '_right';
  }
}
