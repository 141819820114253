import {EventEmitter} from '@angular/core';
import {FormStepData} from './FormStepData';

export abstract class StepFormComponent<T extends FormStepData> {
  public data: T;
  dataChange: EventEmitter<T> = new EventEmitter();
  validationState: EventEmitter<boolean> = new EventEmitter();
  validateIfDisabled = true;
  automaticNext = false;
  forceOnNext: EventEmitter<void> = new EventEmitter();
  hideParentFormButtons: EventEmitter<boolean> = new EventEmitter(true); // true -> async
  alreadySubmitted = false;
  hidePreviousButton = false;

  dataInitiated(): void {};

  dataReset(): void {};

  onPreviousHandler: (
    success: () => void,
    error: (showErrorButton: boolean) => void
  ) => void;

  onNextHandler: (
    success: () => void,
    error: (showErrorButton: boolean) => void
  ) => void;

  onValidateHandler: (
    success: () => void,
    error: () => void
  ) => void;
}
