import {VigilanceLevel} from './VigilanceLevel';
import {VigilanceRiskRateConfig} from './VigilanceRiskRateConfig';

export class VigilanceRiskRateLevel {
  level: VigilanceLevel;
  riskRate: VigilanceRiskRateConfig;
}

export class VigilanceRiskRateByLevelsConfig {
  low: VigilanceRiskRateConfig = new VigilanceRiskRateConfig();
  medium: VigilanceRiskRateConfig = new VigilanceRiskRateConfig();
  high: VigilanceRiskRateConfig = new VigilanceRiskRateConfig();
  maximal: VigilanceRiskRateConfig = new VigilanceRiskRateConfig();

  public toOrderedArray(): Array<VigilanceRiskRateLevel> {
    return [
      {level: VigilanceLevel.LOW, riskRate: this.low},
      {level: VigilanceLevel.MEDIUM, riskRate: this.medium},
      {level: VigilanceLevel.HIGH, riskRate: this.high},
      {level: VigilanceLevel.MAXIMAL, riskRate: this.maximal}
    ];
  }

  public findRateAndLevelByRatio(rate: number): VigilanceRiskRateLevel {
    let vigilanceRiskRateLevels = this.toOrderedArray();
    return vigilanceRiskRateLevels.find((vigilanceRiskRateLevel, index) => {
      if (vigilanceRiskRateLevel.level === VigilanceLevel.LOW) {
        return rate <= vigilanceRiskRateLevel.riskRate.maxRate
      } else {
        return vigilanceRiskRateLevels[index - 1].riskRate.maxRate < rate && rate <= vigilanceRiskRateLevel.riskRate.maxRate
      }
    });
  }
}
