import {Injectable} from '@angular/core';
import {HttpHandlerService, ModuleService} from 'base';
import {switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AstreeService {
  private readonly baseUrl = '/api/astree-conformity';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getConfig(): Observable<AstreeConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<AstreeConfig>(url + '/config')));
  }
}

export class AstreeConfig {
  webUrl: string;
}
