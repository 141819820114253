import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';

@Injectable()
export class RoleService {
  private readonly baseUrl = '/api/roles';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getBaseRoles(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<string[]>(`${url}/base`)));
  }

  public getLabRoles(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<string[]>(`${url}/lab`)));
  }

  public getBlacklistPepRoles(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) => this.http.get<string[]>(`${url}/blacklist-pep`))
      );
  }
}
