import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Module} from '../domain/Module';
import {HttpHandlerService} from './http/http-handler.service';
import {Router} from '@angular/router';
import {UiModule} from '../domain/UiModule';

@Injectable()
export class ModuleService {
  private readonly url = this.environment.apiBaseHref + '/api/modules';

  currentModule = new ReplaySubject<Module>();
  modules = new ReplaySubject<Module[]>();
  moduleByName = new ReplaySubject<{[name: string]: Module}>();

  constructor(
    private http: HttpHandlerService,
    @Inject('env') private environment,
    private router: Router
  ) {
    this.loadModules();
  }

  private getRemoteModules(): Observable<Module[]> {
    return this.http.transformResponseTo(Module).get(this.url);
  }

  private loadModules(): void {
    this.getRemoteModules().subscribe((modules) => {
      this.modules.next(modules);
      this.currentModule.next(
        modules.filter((module) => module.currentModule)[0]
      );
      const lModuleByName: {[name: string]: Module} = {};
      modules.forEach((module) => (lModuleByName[module.name] = module));
      this.moduleByName.next(lModuleByName);
    });
  }

  getCurrentModuleUi(): Observable<string> {
    return this.currentModule.pipe(
      map((module) => Object.values(module.uiModuleByName) as UiModule[]),
      map((uiModules: UiModule[]) => {
        return uiModules.filter(
          (uiModule) => this.router.url.startsWith(uiModule.name) != null
        );
      }),
      map((uiModule: UiModule[]) => uiModule[0]),
      map((uiModule: UiModule) => uiModule.name),
      take(1)
    );
  }

  moduleBackendUrl(moduleName: string, urlPart = ''): Observable<string> {
    return this.moduleByName.pipe(
      map((moduleByName) => {
        if (
          moduleByName[moduleName].uiOnBackend &&
          moduleByName[moduleName].currentModule
        ) {
          return urlPart;
        } else {
          return `${moduleByName[moduleName].backendUrl}${urlPart}`;
        }
      }),
      take(1)
    );
  }

  private moduleUiUrl(
    moduleName: string,
    urlPart = '',
    uiName: string = moduleName
  ): Observable<string> {
    return this.moduleByName.pipe(
      map((moduleByName) => {
        return `${moduleByName[moduleName].uiModuleByName[uiName].uiUrl}${urlPart}`;
      })
    );
  }

  userModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('core', urlPart, 'user');
  }

  adminModuleBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('admin', urlPart);
  }

  adminModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('admin', urlPart);
  }

  complianceBinderBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('compliance-binder', urlPart);
  }

  labBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('lab', urlPart);
  }

  labModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('lab', urlPart);
  }

  complianceBinderModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('compliance-binder', urlPart);
  }

  blacklistModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('blacklist-pep', urlPart, 'blacklist');
  }

  pepModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('blacklist-pep', urlPart, 'pep');
  }

  adverseMediaModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('blacklist-pep', urlPart, 'adverse-media');
  }

  blacklistOrPepOrAdverseMediaModuleUiUrl(urlPart = '', uiName: string): Observable<string> {
    return this.moduleUiUrl('blacklist-pep', urlPart, uiName);
  }

  blacklistPepBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('blacklist-pep', urlPart);
  }

  dashboardModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('dashboard', urlPart);
  }

  dashboardBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('dashboard', urlPart);
  }

  eLearningModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('e-learning', urlPart);
  }

  eLearningBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('e-learning', urlPart);
  }

  companyModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('core', urlPart, 'company');
  }

  coreModuleBackendUrl(urlPart = ''): Observable<string> {
    return this.moduleBackendUrl('core', urlPart);
  }

  personModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('core', urlPart, 'person');
  }

  productModuleUiUrl(urlPart = ''): Observable<string> {
    return this.moduleUiUrl('core', urlPart, 'product');
  }

  pingModule(module: Module): Observable<boolean> {
    return this.moduleBackendUrl(module.name, '/api/modules').pipe(
      switchMap((url) => this.http.transformResponseTo(Module).get(url)),
      catchError((_) => Observable.create(false)),
      map((obj) => obj !== false)
    );
  }
}
