<button
  class="button buttonRound"
  [ngClass]="fromSize(size)"
  [class._disabled]="disabled"
  [class._success]="validate"
  [class._alert]="alert"
  [class._white]="white"
  [class._collapse]="collapse"
  [class.hollow]="hollow"
  [type]="type"
  [disabled]="disabled"
  (click)="btnClick.emit($event)"
  [routerLink]="btnRouterLink"
  [queryParams]="btnRouterLinkQueryParams"
  [queryParamsHandling]="btnQueryParamsHandling"
>
  <i [ngClass]="faIcon"></i>
  <ng-content></ng-content>
</button>
