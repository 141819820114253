export class HierarchizedCode {
  parentCode: HierarchizedCode = null;
  level: number;
  name: string;
  defaultValue: string;
  otherValues: string[];

  public getParentCodesFromHierarchizedCode(): HierarchizedCode[] {
    let parentCodes: HierarchizedCode[] = [];
    let parentCode = this.parentCode;
    while (parentCode !== undefined && parentCode !== null) {
      parentCodes.push(parentCode);
      parentCode = parentCode.parentCode;
    }
    return parentCodes;
  }
}
