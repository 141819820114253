import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {PasswordStrengthValidatorDirective} from './password-strength.validator.directive';
import {PasswordConfirmationValidatorDirective} from './password-confirmation.validator.directive';
import {EmailCheckBlacklistValidatorDirective} from './email-check-blacklist.validator.directive';

const components = [
  PasswordStrengthValidatorDirective,
  PasswordConfirmationValidatorDirective,
  EmailCheckBlacklistValidatorDirective
];

@NgModule({
  exports: components,
  declarations: components,
  imports: [FormsModule]
})
export class DirectivesValidationModule {}
