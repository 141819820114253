<dp-date-picker
  #datePicker
  #datePickerModel="ngModel"
  [mode]="datePickerMode"
  [config]="config"
  [ngModel]="datePickerValue"
  (ngModelChange)="onModelChange($event)"
  [disabled]="disabled"
  tabindex="{{tabindex}}"
></dp-date-picker>

<span class="red error-message" *ngIf="isInvalid && datePickerModel.dirty && datePickerModel.touched">Date invalide</span>
