import {Component, OnInit} from '@angular/core';
import {CompanyRegistrationAddress} from '../../../../domain/registration/CompanyRegistration';
import {
  CompanyService,
  LegalEntity,
  PostalAddress,
  RegistrationProfile,
  SessionService,
  StepSingleFormComponent
} from 'base';
import {RegistrationService} from '../../../../services/registration.service';
import {StepData} from '../../register.component';

@Component({
  selector: 'm-registration-company-creation-step-three',
  templateUrl: './m-registration-company-creation-step-three.component.html',
  styleUrls: ['./m-registration-company-creation-step-three.component.scss']
})
export class MRegistrationCompanyCreationStepThreeComponent
  extends StepSingleFormComponent<StepData>
  implements OnInit {
  companyPublished = true;
  isCompanyAddressComplete = true;
  registrationProfileEnum = RegistrationProfile;
  hidePreviousButton = true;

  constructor(
    private registrationService: RegistrationService,
    private companyService: CompanyService,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadCompanyIfCreated();
  }

  loadCompanyIfCreated(): void {
    this.sessionService.currentUser().subscribe((user) => {
      if (user.userRegistrationProgress.companyRef != null) {
        this.companyService.current().subscribe((value) => {
          this.data.companyRegistration.name = value.name;
          this.data.companyRegistration.siren = value.siren;
          this.data.companyRegistration.headcount = value.legalEntity.headcount;
          this.data.companyRegistration.ageaNumber =
            value.legalEntity.ageaNumber;
          if (value.invoiceInfo.invoiceAddress != null) {
            this.data.companyRegistration.address =
              new CompanyRegistrationAddress();
            this.data.companyRegistration.address.address =
              value.invoiceInfo.invoiceAddress.address;
            this.data.companyRegistration.address.city =
              value.invoiceInfo.invoiceAddress.city;
            this.data.companyRegistration.address.zipCode =
              value.invoiceInfo.invoiceAddress.zipCode;
          }
        });
      }
    });
  }

  onNextHandler = (
    success: () => void,
    error: (showErrorButton: boolean) => void
  ): void => {
    if (this.companyPublished && this.isCompanyAddressComplete) {
      this.data.companyRegistration.address = null;
    }
    this.registrationService
      .createCompany(this.data.companyRegistration)
      .subscribe(
        () => {
          success();
        },
        () => {
          error(false);
        }
      );
  };

  public onSirenLegalEntityChange(legalEntity: LegalEntity): void {
    if (legalEntity) {
      this.companyPublished = legalEntity.companyExternalData.publicationStatus;
      const address = legalEntity.companyExternalData.personCompanyOffice.address;
      this.isCompanyAddressComplete = this.checkCompanyAddressCompletion(address);
      if (this.companyPublished) {
        this.data.companyRegistration.name =
          legalEntity.companyExternalData.personCompanyInfo.organizationName;
        this.data.companyRegistration.address =
          new CompanyRegistrationAddress();
        this.data.companyRegistration.address.address =
          address.address;
        this.data.companyRegistration.address.zipCode =
          address.zipCode;
        this.data.companyRegistration.address.city =
          address.city;
      }
      this.data.canAccessNextStep = true;
    } else {
      this.data.companyRegistration.name = null;
      this.data.companyRegistration.address = new CompanyRegistrationAddress();
      this.data.canAccessNextStep = false;
    }
  }

  private checkCompanyAddressCompletion(address: PostalAddress) {
    return address.address.trim() !== "" && address.zipCode.trim() !== "" && address.city.trim() !== "";
  }
}
