import {RegistrationStep} from './RegistrationStep';
import {RegistrationProfile} from './RegistrationProfile';
import {Company} from '../../company/Company';
import {Reference} from '../../../../../domain/Reference';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';

export class UserRegistrationProgress {
  currentStep: RegistrationStep;
  registrationProfile: RegistrationProfile;
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  isFinish: boolean;
}
