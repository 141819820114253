import {CodedValue} from '../../CodedValue';
import {Transform, Type} from 'class-transformer';
import {LocalDateTransformer} from '../../../../../../../domain/transformation/LocalDateTransformer';
import {LocalDate} from '../../../../../../../domain/LocalDate';

export class Birth {
  @Transform(LocalDateTransformer.transformTo())
  date: LocalDate;
  /* Variable is badly named as it used as birthCity in project */
  place: string;
  department: string;

  @Type(() => CodedValue)
  country: CodedValue;
  @Type(() => CodedValue)
  nationality: CodedValue;
}
