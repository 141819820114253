<form #form="ngForm" (keyup.enter)="onNext()">
  <div class="card overflow-visible force-space-bottom-0">
    <div class="grid-y">
      <div class="card-section cell shrink">
        <span #component></span>
      </div>

      <div class="card-footer shrink" *ngIf="!hiddenButtons">
        <div class="grid-x grid-padding-x align-right align-middle">
          <div class="confirmReset shrink cell" *ngIf="editable">
            <a-button-cancel
              (btnClick)="confirmReset()"
              labelKey="{{'multiStepForm.button.reset' | translate}}"
            >
            </a-button-cancel>
          </div>

          <div *ngIf="!isFirstStep && !hidePreviousButton" class="shrink cell">
            <a-button-loading
              [status]="previousLoadingButton"
              [baseClasses]="'button primary expanded'"
              [validClasses]="'primary'"
              [errorClasses]="'alert'"
              (btnClick)="onPrevious()"
            >
              <i class="fa fa-chevron-left"></i> {{'multiStepForm.button.previous' | translate}}
            </a-button-loading>
          </div>

          <div class="shrink cell" *ngIf="!isLastStep && !automaticNext">
            <a-button-loading
              [status]="nextLoadingButton"
              [disabled]="!canAccessNextStep || !this.validationState"
              [baseClasses]="'button primary expanded'"
              [validClasses]="'primary'"
              [errorClasses]="'alert'"
              (btnClick)="onNext()"
            >
              {{'multiStepForm.button.next' | translate}}  <i class="fa fa-chevron-right"></i>
            </a-button-loading>
          </div>

          <div
            class="shrink cell"
            *ngIf="isValidationStep && isSavable && editable"
          >
            <a-button-submit
              (btnClick)="onSave()"
              [validate]="true"
              labelKey="{{'multiStepForm.button.save' | translate}}"
            >
            </a-button-submit>
          </div>

          <div class="shrink cell" *ngIf="isValidationStep && (editable || forceShowValidationOnLastStep)">
            <a-button-loading
              [status]="validateLoadingButton"
              [baseClasses]="'button success expanded'"
              [validClasses]="'primary'"
              [errorClasses]="'alert'"
              [faIcon]="'fa fa-check'"
              [size]="'small'"
              (btnClick)="onValidate()"
              [disabled]="!validationState"
            >
              {{'multiStepForm.button.validate' | translate}}
            </a-button-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
