import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHandlerService} from './http/http-handler.service';
import {Config} from '../domain/Config';
import {Module} from '../domain/Module';
import {catchError} from 'rxjs/operators';
import {ModuleService} from './module.service';
import {IntResponse} from '../domain/IntResponse';

@Injectable()
export class ConfigService {
  private readonly url = this.environment.apiBaseHref + '/api/config';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    @Inject('env') private environment
  ) {}

  public getConfig(): Observable<Config> {
    return this.http
      .transformResponseTo(Module)
      .get(this.url)
      .pipe(
        catchError<Config, Observable<Config>>((error) => {
          if (error.status === 403) {
            // TODO FHE : Improve this because it's ugly
            let errorType = 'accessDenied';
            if (error.error.indexOf('has not finish registration') !== -1) {
              errorType = 'registrationNotFinished';
            }
            this.moduleService
              .userModuleUiUrl(`/login?${errorType}=true`)
              .subscribe((url) => (window.location.href = url));
          }
          throw error;
        })
      );
  }

  public getFileMaxSize(): Observable<IntResponse> {
    return this.http.get<IntResponse>(this.url + '/fileMaxSize');
  }
}
