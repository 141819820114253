import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {ModuleService} from './module.service';
import * as moment from 'moment';

@Injectable()
export class AuthenticationService {
  private readonly baseUrl = '/api/sessions';
  public readonly SESSION_AUTH_KEY = 'sessionAuthentification';
  public readonly SESSION_MAX_TIME_MINUTES = 1;

  constructor(
    private httpClient: HttpClient,
    private moduleService: ModuleService
  ) {}

  authenticate(loginToken: string): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.httpClient.post<void>(url + '/' + loginToken, {})
        )
      );
  }

  isCoreAuthenticated(): Observable<boolean> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.httpClient.get<void>(url + '/isAuthenticated').pipe(
          map(() => true),
          catchError(() => of(false))
        )
      )
    );
  }

  isAuthenticatedNotCached(): Observable<boolean> {
    return this.isCoreAuthenticated().pipe(take(1));
  }

  isAuthenticated(): Observable<boolean> {
    const value = localStorage.getItem(this.SESSION_AUTH_KEY);
    const currentTimestamp = moment().unix();
    if (value != null && Number.parseInt(value) > currentTimestamp) {
      return of(true);
    }
    return this.isCoreAuthenticated()
      .pipe(
        take(1),
        map((result) => {
          const expiration = moment()
            .add(this.SESSION_MAX_TIME_MINUTES, 'minutes')
            .unix();
          localStorage.setItem(this.SESSION_AUTH_KEY, String(expiration));
          return result;
        }),
        catchError(() => {
          localStorage.removeItem(this.SESSION_AUTH_KEY)
          return of(false);
        }),
      );
  }
}
