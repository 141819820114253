import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {LegalEntity} from '../../domain/person/person/legal-entity/LegalEntity';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {Reference} from '../../../../domain/Reference';

@Injectable()
export class CoreProductService {
  private readonly baseUrl: string = '/api/product';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public hasProductForLegalEntity(legalEntityRef: Reference<LegalEntity>): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(BooleanResponse)
            .get<BooleanResponse>(`${url}/supplier`, {
              params: {
                supplier: legalEntityRef
              }
            })
        )
      );
  }
}
