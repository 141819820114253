import {Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ModuleService} from './module.service';
import {HttpHandlerService} from './http/http-handler.service';
import {Observable} from 'rxjs';
import {DataImportResult} from '../domain/dataIntegration/DataImportResult';
import {SpreadSheetColumn} from '../domain/dataIntegration/SpreadSheetColumn';
import {NumberResponse} from '../domain/NumberResponse';
import {NavigationService} from "./navigation.service";

@Injectable()
export class DataIntegrationService {
  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    private navigationService: NavigationService,
  ) {}

  public getHeaders(
    module: string,
    name: string,
    format: string,
    context: {}
  ): Observable<SpreadSheetColumn[]> {
    return this.moduleService
      .moduleBackendUrl(
        module,
        `/api/data-integrations/${name}/${format}/headers?` +
          Object.keys(context)
            .map((k) => `${k}=${context[k]}`)
            .join('&')
      )
      .pipe(switchMap((url) => this.http.get<SpreadSheetColumn[]>(`${url}`)));
  }

  public export(
    module: string,
    name: string,
    fileName: string,
    format: string,
    context: {}
  ) {
    this.navigationService.navigateTo(
      `/${module}/api/data-integration/export?name=${name}&filename=${fileName}&format=${format}&` +
      Object.keys(context)
        .map((k) => `${k}=${context[k]}`)
        .join('&')
    );
  }

  public findExtractionLimit(
    module: string,
    name: string
  ): Observable<NumberResponse> {
    return this.moduleService
      .moduleBackendUrl(module, '/api/data-integration/export/limit')
      .pipe(
        switchMap((url) => this.http.get<NumberResponse>(`${url}?name=${name}`))
      );
  }

  public exportWithPreview(
    module: string,
    name: string,
    fileName: string,
    format: string,
    dataSelector: {}
  ): Observable<string> {
    return this.moduleService
      .moduleBackendUrl(module, '/api/data-integration/export')
      .pipe(
        switchMap((url) =>
          this.http.post<{value: string}>(
            `${url}/preview?name=${name}&filename=${fileName}&format=${format}&` +
              Object.keys(dataSelector)
                .map((k) => `${k}=${dataSelector[k]}`)
                .join('&')
          )
        ),
        map((resp) => resp.value)
      );
  }

  public resumeExport(
    module: string,
    previewSessionId: string,
    fileName: string,
    format: string
  ) {
    this.navigationService.navigateTo(
      `/${module}/api/data-integrations/resume-export/${previewSessionId}?filename=${fileName}&format=${format}`
    );
  }

  public resumeImport(
    module: string,
    previewSessionId: string
  ): Observable<DataImportResult> {
    return this.moduleService
      .moduleBackendUrl(
        module,
        `/api/data-integrations/resume-import/${previewSessionId}`
      )
      .pipe(switchMap((url) => this.http.post<DataImportResult>(`${url}`)));
  }

  public cancelImport(
    module: string,
    previewSessionId: string
  ): Observable<void> {
    return this.moduleService
      .moduleBackendUrl(
        module,
        `/api/data-integrations/cancel-import/${previewSessionId}`
      )
      .pipe(switchMap((url) => this.http.post<void>(`${url}`)));
  }
}
