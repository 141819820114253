import {UiModule} from './UiModule';

export class Module {
  name: string;
  backendUrl: string;
  uiModuleByName: Record<string, UiModule>;
  adminUiModule: UiModule;
  uiOnBackend: boolean;
  currentModule: boolean;
  restrictedAccess: boolean;
  displayedOnMenu: boolean;
}
