import {Injectable} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpHandlerService} from './http/http-handler.service';
import {ModuleService} from './module.service';
import {StringResponse} from '../domain/StringResponse';

@Injectable()
export class RecaptchaService {
  private readonly baseUrl = '/api/recaptcha';
  private _publicKey: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getPublicKey(): string {
    return this._publicKey.getValue();
  }

  public loadPublicKey(publicKey: string) {
    this._publicKey.next(publicKey);
  }

  public initPublicKey(): Observable<StringResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(StringResponse)
            .get<StringResponse>(url + '/publickey')
        )
      );
  }
}
