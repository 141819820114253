import {TransformationType} from 'class-transformer/TransformOperationExecutor';
import {Transformer} from './Transformer';
import {LocalDate} from '../LocalDate';

export class LocalDateTransformer extends Transformer<LocalDate> {
  public static transformTo(): (
    value: any,
    obj: any,
    transformationType: TransformationType
  ) => any {
    return new LocalDateTransformer().transform();
  }

  constructor() {
    super();
  }

  protected toClass(value: string): LocalDate {
    return LocalDate.fromString(value);
  }

  protected toPlain(value: any): any {
    if (value) {
      return LocalDate.fromAny(value).toISOString();
    }
    return null;
  }
}
