import {NgModule} from '@angular/core';
import {AppRootComponent} from './appRoot.component';
import {RouterModule} from '@angular/router';
import {loginRoutes} from './login/login.routes';
import {LoginModule} from './login/login.module';
import {TranslateModule} from '@ngx-translate/core';
import {ServicesModule} from '../services/services.module';
import {registerRoutes} from './register/register.routes';
import {RegisterModule} from './register/register.module';
import {BaseComponentsModule, BaseCoreServicesModule, BaseDirectivesModule} from 'base';

@NgModule({
  declarations: [AppRootComponent],
  imports: [
    RouterModule.forRoot(
      [
        {path: '', redirectTo: '/login', pathMatch: 'full'},
        {path: 'register', children: registerRoutes},
        {path: 'login', children: loginRoutes},
        {path: '', children: loginRoutes},
        {path: '**', redirectTo: '/home'}
      ],
      {useHash: true, onSameUrlNavigation: 'reload'}
    ),
    BaseComponentsModule,
    LoginModule,
    RegisterModule,
    TranslateModule,
    ServicesModule,
    BaseCoreServicesModule,
    BaseDirectivesModule
  ],
  exports: [AppRootComponent]
})
export class PagesModule {}
