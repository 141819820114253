<div class="grid-x grid-padding-x">
  <div class="cell small-24" *ngIf="paymentInformation">
    <a-title-view
      [titleKey]="'register.step.four.title' | translate:{productName: paymentInformation.productName} | uppercase"
    ></a-title-view>
  </div>

  <div class="cell small-24 grid-x">
    <a-tip-off-message
      *ngIf="successPayment"
      [type]="tipOffMessageType.SUCCESS"
    >
      <div class="grid-x grid-padding-x align-middle">
        <div class="cell shrink">
          <i class="fas fa-spinner fa-pulse fa-2x"></i>
        </div>
        <div class="cell auto">
          <span
            [innerHtml]="'register.step.four.payment.success' | translate"
          ></span>
        </div>
      </div>
    </a-tip-off-message>

    <a-tip-off-message *ngIf="cancelPayment" [type]="tipOffMessageType.ALERT">
      {{'register.step.four.payment.cancel' | translate}}
    </a-tip-off-message>

    <div class="paiementLoader" *ngIf="loadPaymentInformationSpinner">
      <a-loading-spinner></a-loading-spinner>
    </div>

    <form
      #form="ngForm"
      class="grid-x block-content"
      *ngIf="successPayment === false"
    >
      <div class="cell small-24 register-invoice">
        <table>
          <tbody>
            <tr *ngIf="paymentInformation?.couponName">
              <td>
                <div class="grid-x grid-padding-x align-middle">
                  <div class="cell shrink">
                    <a-button-delete
                      (btnClick)="deleteCoupon()"
                    ></a-button-delete>
                  </div>

                  <div class="cell shrink">
                    {{'register.step.four.referral.code' | translate}} : <br />
                    <spacer [size]="4"></spacer>
                    <strong
                      >{{paymentInformation.couponCode}} -
                      ({{paymentInformation.couponName}})</strong
                    >
                  </div>
                </div>
              </td>
              <td class="text-right">
                <strong>{{paymentInformation.couponPrice}}€</strong>
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="paymentInformation">
            <tr>
              <td>
                {{'register.step.four.total.price.excluded.taxes' | translate}}
              </td>
              <td class="text-right">
                {{paymentInformation.productPriceExcludingTaxes}}€
              </td>
            </tr>
            <tr>
              <td>
                {{'register.step.four.total.price.only.taxes' | translate}}
              </td>
              <td class="text-right">
                {{paymentInformation.productOnlyTaxes}}€
              </td>
            </tr>
            <tr>
              <td>
                <h5>{{'register.step.four.total.price' | translate}}</h5>
              </td>
              <td class="text-right">
                <h4>{{paymentInformation.totalPrice}}€</h4>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <spacer class="cell small-24" [size]="16"></spacer>

      <div class="cell small-24 grid-x grid-padding-x align-bottom">
        <a-input
          class="cell shrink"
          [(ngModel)]="coupon"
          name="coupon"
          [inputType]="'text'"
          [label]="'register.step.four.referral.code' | translate"
        >
        </a-input>
        <a-button-loading
          class="cell shrink"
          [status]="couponSearchStatus"
          [type]="'submit'"
          [disabled]="isFormInvalid()"
          [baseClasses]="'button medium primary expanded ripple'"
          [validClasses]="''"
          [loadingClasses]="''"
          [errorClasses]="'alert'"
          (btnClick)="couponIsValid()"
        >
          {{'register.step.four.coupon.submit' | translate}}
        </a-button-loading>
        <div class="cell small-24">
          <a-tip-off-message
            *ngIf="couponValid === false"
            [type]="tipOffMessageType.WARNING"
          >
            {{'register.step.four.coupon.invalid' | translate}}
          </a-tip-off-message>
        </div>
      </div>

      <spacer class="cell small-24" [size]="24"></spacer>

      <div class="card">
        <div class="card-section grid-x">
          <a-checkbox
            [label]="'register.step.four.rules' | translate"
            name="hasRulesValidated"
            [(ngModel)]="hasRulesValidated"
            [required]="true"
          >
          </a-checkbox>
          <a-button-round
            [faIcon]="'fa fa-download'"
            [hollow]="true"
            (click)="downloadRulesPdf()"
          >
          </a-button-round>
        </div>
      </div>
    </form>
  </div>
</div>
