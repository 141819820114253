import {SubscriptionProposalSyntecData} from './SubscriptionProposalSyntecData';
import {SubscriptionProposal} from './SubscriptionProposal';
import {Reference} from '../../../../domain/Reference';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';

export class SubscriptionProposalPreviousData {
  @Transform(ReferenceTransformer.transformTo(SubscriptionProposal))
  previousSubscriptionProposalRef: Reference<SubscriptionProposal>;
  @Type(() => SubscriptionProposalSyntecData)
  previousSynctecData: SubscriptionProposalSyntecData = new SubscriptionProposalSyntecData();
  previousPreTaxValue: number;
  previousVatValue: number;
  previousTaxedValue: number;
}
