import {FileItem} from 'ng2-file-upload';
import {ClassedEntity} from './ClassedEntity';
import {Company} from '../modules/core/domain/company/Company';

export class BlobItem{

  blobId: string;
  blobFileName: string;

}
