<ng-container *ngIf="!groupByFn">
  <ng-select
    [class._form_submitted]="formSubmitted"
    [required]="required && formSubmitted"
    [addTagText]="'ng-select.addTagText' | translate"
    [loadingText]="'ng-select.loading' | translate"
    [notFoundText]="'ng-select.notFound' | translate"
    [typeToSearchText]="'ng-select.typeSearch' | translate:{minTermLength:minTermLength}"
    [items]="items"
    [bindLabel]="'__label'"
    [ngModel]="selection"
    [disabled]="disabled"
    [multiple]="multiple"
    [maxSelectedItems]="maxSelectedItems"
    [clearable]="clearable"
    [appendTo]="appendTo"
    [searchFn]="searchFn"
    [hideSelected]="true"
    [minTermLength]="minTermLength"
    [compareWith]="compareFn"
    [class._fullDropdown]="fullDropdown"
    [addTag]="addTag"
    (ngModelChange)="onChange($event)"
  >
    <div *ngIf="translateKey">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span
          class="ng-value-icon left"
          (click)="clear(item)"
          aria-hidden="true"
        >
          ×
        </span>
        <span class="ng-value-label">
          {{ translateKey + '.' + item | translate}}
        </span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ translateKey + '.' + item | translate}}
      </ng-template>
    </div>
    <div *ngIf="!translateKey">
      <div *ngIf="labelTemplate">
        <ng-template let-clear="clear" let-item="item" ng-label-tmp>
          <ng-container
            *ngTemplateOutlet="labelTemplate; context:{item: item, clear: clear}"
          ></ng-container>
        </ng-template>
      </div>
      <div *ngIf="optionTemplate">
        <ng-template let-item="item" ng-option-tmp>
          <ng-container
            *ngTemplateOutlet="optionTemplate; context:{item: item}"
          ></ng-container>
        </ng-template>
      </div>
      <div *ngIf="tagTemplate">
        <ng-template let-search="searchTerm" ng-tag-tmp>
          <ng-container
            *ngTemplateOutlet="tagTemplate; context:{search: search}"
          ></ng-container>
        </ng-template>
      </div>
    </div>
  </ng-select>
</ng-container>
<ng-container *ngIf="groupByFn">
  <ng-select
    [class._form_submitted]="formSubmitted"
    [required]="required && formSubmitted"
    [addTagText]="'ng-select.addTagText' | translate"
    [loadingText]="'ng-select.loading' | translate"
    [notFoundText]="'ng-select.notFound' | translate"
    [typeToSearchText]="'ng-select.typeSearch' | translate:{minTermLength:minTermLength}"
    [items]="items"
    [bindLabel]="'__label'"
    [ngModel]="selection"
    [disabled]="disabled"
    [multiple]="multiple"
    [clearable]="true"
    [searchFn]="searchFn"
    [hideSelected]="true"
    [minTermLength]="minTermLength"
    [compareWith]="compareFn"
    [groupBy]="groupByFn"
    [groupValue]="groupValueFn"
    [class._fullDropdown]="fullDropdown"
    [addTag]="addTag"
    (ngModelChange)="onChange($event)"
  >
    <ng-template ng-optgroup-tmp let-item="item">
      <span style="vertical-align: middle"
        >{{item.name}} ({{ item.total }})</span
      >
    </ng-template>
  </ng-select>
</ng-container>
