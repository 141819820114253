import {Component, Input} from '@angular/core';
import {TipOffMessageType} from '../../../domain/TipOffMessageType';

@Component({
  selector: 'a-tip-off-message',
  templateUrl: './a-tip-off-message.component.html',
  styleUrls: ['./a-tip-off-message.component.scss']
})
export class ATipOffMessageComponent {
  @Input()
  type: TipOffMessageType = TipOffMessageType.NOTICE;

  @Input()
  shrink = false;

  public cssClass;
  public iconName;

  ngOnInit(): void {
    switch (this.type) {
      case TipOffMessageType.SUCCESS:
        this.cssClass = '_success';
        this.iconName = 'icon-checked';
        break;

      case TipOffMessageType.WARNING:
        this.cssClass = '_warning';
        this.iconName = 'icon-warning';
        break;

      case TipOffMessageType.ALERT:
        this.cssClass = '_alert';
        this.iconName = 'icon-exclamation-circle';
        break;

      case TipOffMessageType.NOTICE:
        this.cssClass = '_notice';
        this.iconName = 'icon-info-circle';
        break;
    }
  }
}
