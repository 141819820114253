import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DatePickerComponent, ECalendarMode, SingleCalendarValue} from 'ng2-date-picker';
import {LocalDate} from '../../../../domain/LocalDate';
import {DateType} from './DateType';

export function validateDate(c: FormControl) {
  if (
    c.value !== undefined &&
    c.value !== null &&
    !moment.isDate(c.value) &&
    !moment.isMoment(c.value) &&
    !(c.value instanceof LocalDate)
  ) {
    return {invalidFormat: {}};
  }

  return null;
}

const DATE_PICKER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useValue: validateDate,
  multi: true
};

const DATE_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ADatePickerComponent),
  multi: true
};

type Temporal = LocalDate | Date | Moment;

@Component({
  selector: 'a-date-picker',
  templateUrl: './a-date-picker.component.html',
  styleUrls: ['./a-date-picker.component.scss'],
  providers: [DATE_PICKER_VALUE_ACCESSOR, DATE_PICKER_VALIDATOR]
})
export class ADatePickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild('datePicker', {static: true, read: NgModel}) datePicker: NgModel;

  @Input() id: string;
  @Input() dateFormat?: string;
  @Input() tabindex?: string;
  @Input() dateType: DateType = 'DATE';
  @Input() minDate;
  @Input() maxDate;

  dateMoment: moment.Moment;
  datePickerMode = 'day';
  datePickerValue: moment.Moment | string | null | undefined;
  date: Temporal;
  disabled = false;
  invalidDateString = "invalid date"

  config = {
    format: 'DD/MM/YYYY',
    showTwentyFourHours: true,
    locale: 'fr',
    unSelectOnClick: false,
    isDayDisabledCallback: (date) => {
      let disabled = false;
      if (this.minDate) {
        disabled = disabled || date < this.toMoment(this.minDate);
      }
      if (this.maxDate) {
        disabled = disabled || date > this.toMoment(this.maxDate);
      }
      return disabled;
    }
  };

  isInvalid;

  ngOnInit(): void {
    if (this.dateType === 'DATETIME') {
      this.datePickerMode = 'daytime';
      this.config.format = 'DD/MM/YYYY HH:mm';
    }
  }

  writeValue(date: Temporal | string): void {
    let dateMoment: Moment;
    if (date && date.toString().toLowerCase() !== this.invalidDateString) {
      if (typeof date !== 'string') {
        dateMoment = this.toMoment(date);
      } else {
        const dateFromString = LocalDate.fromString(date);
        if (dateFromString.toISOString() !== this.invalidDateString) {
          dateMoment = this.toMoment(dateFromString)
        } else {
          dateMoment = undefined;
        }
      }
    } else {
      dateMoment = undefined;
    }
    if (!areDateEquals(dateMoment, this.dateMoment)) {
      this.dateMoment = dateMoment;
      this.datePickerValue = this.dateMoment;
      this.datePicker.reset(this.dateMoment);
    }
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  private dateChange(value: SingleCalendarValue) {
    if (moment.isMoment(value)) {
      this.dateMoment = value;
      if (this.dateType === 'LOCAL_DATE') {
        this.onChange(LocalDate.fromMoment(value));
      } else if (this.dateType === 'DATE' || this.dateType === 'DATETIME') {
        this.onChange(value.toDate());
      } else {
        this.onChange(value);
      }
      this.isInvalid = false;
    } else if (value && typeof value === 'string') {
      if (this.dateType === 'LOCAL_DATE') {
        this.onChange(LocalDate.fromString(value, this.config.format));
      } else {
        this.onChange(moment(value, this.config.format).toDate());
      }
      this.isInvalid = false;
    } else {
      this.isInvalid = !!(value == undefined || value);
      this.onChange(undefined);
    }
  }

  onModelChange(value: Moment | string | null | undefined) {
    // detect invalid date
    if (typeof value === 'string') {
      this.dateChange(undefined);
    } else if (moment.isMoment(value) || value === null || value === undefined) {
      this.dateChange(value as SingleCalendarValue);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private toMoment(date: Temporal): Moment {
    if (date instanceof LocalDate) {
      return date.toMoment();
    } else if (date instanceof Date) {
      return moment(date);
    } else {
      return date;
    }
  }
}

function areDateEquals(date1?: Moment, date2?: Moment): boolean {
  if (!date1) {
    return !date2;
  }
  if (!date2) {
    return !date1;
  }
  return date1.isSame(date2);
}
