import {HttpParameterCodec, HttpParams} from '@angular/common/http';
import {Dictionary, isArray, isDate, isNil} from 'lodash';
import {Reference} from './Reference';
import {LocalDate} from './LocalDate';
import {Params} from '@angular/router';

/**
 * Copy of https://github.com/angular/angular/blob/adfc3f0f95acf327d8c2ba80fb929f72a8226da4/packages/common/http/src/params.ts#L96
 * This fix the + not encoded correctly
 */
export class URIComponentCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return this.standardEncoding(key);
  }
  encodeValue(value: string): string {
    return this.standardEncoding(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  /**
   * Encode input string with standard encodeURIComponent and then un-encode specific characters.
   */
  private readonly STANDARD_ENCODING_REGEX = /%(\d[a-f0-9])/gi;
  private readonly STANDARD_ENCODING_REPLACEMENTS: {[x: string]: string} = {
    '40': '@',
    '3A': ':',
    '24': '$',
    '2C': ',',
    '3B': ';',
    '3D': '=',
    '3F': '?',
    '2F': '/'
  };

  private standardEncoding(v: string): string {
    return encodeURIComponent(v).replace(
      this.STANDARD_ENCODING_REGEX,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (s, t) => this.STANDARD_ENCODING_REPLACEMENTS[t] ?? s
    );
  }
}

export class SearchParams {
  private static readonly uriCodec = new URIComponentCodec();

  public static toHttpParams(
    originalParams: Dictionary<any> | any
  ): HttpParams {
    if (isNil(originalParams)) {
      return new HttpParams();
    }

    return Object.getOwnPropertyNames(originalParams)
      .filter((key: string): boolean => {
        const param = originalParams[key];
        return !isNil(param) && param !== '';
      })
      .reduce((params: HttpParams, key: string): HttpParams => {
        return SearchParams.aggregate(params, key, originalParams);
      }, new HttpParams({encoder: this.uriCodec}));
  }

  private static aggregate(
    params: HttpParams,
    key: string,
    originalParams: Dictionary<any>
  ): HttpParams {
    const param = originalParams[key];

    if (isDate(param)) {
      return params.set(key, param.toISOString());
    }

    if (param instanceof LocalDate) {
      return params.set(key, param.toISOString());
    }

    if (Reference.isReference(param)) {
      return params.set(key, param.uri.toString());
    }

    if (isArray(param)) {
      param.forEach((item: any) => {
        params = params.append(key, item);
      });

      return params;
    }

    return params.set(key, param);
  }

  public static toDictionary(originalParams: Dictionary<any> | any): Params {
    const httpParams = this.toHttpParams(originalParams);
    const params: {[key: string]: any} = {};
    httpParams.keys().forEach((key) => (params[key] = httpParams.get(key)));
    return params;
  }
}
