import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {Observable, Subject} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

const CB_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AComboBoxRefComponent),
  multi: true
};

/**
 * AComboBoxRefComponent
 *
 * AComboBoxRefComponent allow you to manage all the combo-box-ref
 * that use the reference from entity (Company, User etc...).
 * This component use ng-select library to exploit highly customizable select box
 * All .ts subcomponents of a-combo-box-ref extends BaseComboBoxRefComponent
 * to have basic ControlValueAccessor methods.
 * To manage String (enum, mails), please use a-combo-box.
 *
 * Base Usage : <a-combo-box-ref [items]="items"
                                 [ngModel]="selectedItems"
                                 (ngModelChange)="onChange($event)"
                                 [disabled]="disabled"></a-combo-box-ref>
 *
 * Below you will find the documented fields
 *
 */
@Component({
  selector: 'a-combo-box-ref',
  templateUrl: './a-combo-box-ref.component.html',
  styleUrls: ['./a-combo-box-ref.component.scss'],
  providers: [CB_VALUE_ACCESSOR]
})
export class AComboBoxRefComponent implements ControlValueAccessor, OnInit {
  // To have more information, please check ng-select documentation
  // All elements showed in ng-select, need SelectableRef Observable (check toSelectable in User or Company entity)
  @Input() items: Observable<SelectableRef<any>[]>;
  @Input() disabled = false;
  @Input() groupByFn: (item: SelectableRef<any>) => any;
  @Input() groupValueFn: (
    groupId: any,
    children: SelectableRef<any>[]
  ) => {name: string; total: number};
  // Enable multiple selectable elements
  @Input() multiple = false;
  // Enable to clear the combobox
  @Input() clearable = true;
  // Show spinner when data loading
  @Input() loading = false;
  // Enable asyncMode
  @Input() isAsyncSearch = false;
  // Minimal characters user need to start a search (very useful with asyncMode)
  @Input() minTermLength = 3;
  @Input() required = false;
  // Need Async : When user start enter some characters parents components start search and give to new items array
  @Output() updateAsyncInput: EventEmitter<string> = new EventEmitter<string>();

  selection: SelectableRef<any>[] = [];
  searchInput: Subject<string> = new Subject<string>();

  onChange = (_: any) => {};

  onTouched = () => {};

  ngOnInit(): void {
    if (this.isAsyncSearch) {
      this.asyncSearch();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.selection = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public compareFn = (a: SelectableRef<any>, b: SelectableRef<any>) => {
    return (
      a === b ||
      (a && b && a.id && b.id && (a.id.id === b.id.id || (!a.id && !b.id)))
    );
  };

  public asyncSearch() {
    this.searchInput
      .pipe(
        debounceTime(300),
        filter((value) => !!value)
      )
      .subscribe((input: string) => {
        this.updateAsyncInput.emit(input);
      });
  }
}
