import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PaginatedProductTypes} from '../../domain/product/product-type/PaginatedProductTypes';
import {ProductType} from '../../domain/product/product-type/ProductType';
import {ProductTypeSearchCriteria} from '../../domain/product/product-type/ProductTypeSearchCriteria';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class ProductTypeService {
  private readonly baseUrl = '/api/product-type';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getProductTypes(): Observable<PaginatedProductTypes> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedProductTypes)
            .get<PaginatedProductTypes>(url + '/paginated', {})
        )
      );
  }

  public getProductTypeById(id: string): Observable<ProductType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ProductType)
            .get<ProductType>(url + '/' + id)
        )
      );
  }

  createProductType(value: ProductType) {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ProductType)
            .post<ProductType>(url, value))
      );
  }

  updateProductType(value: ProductType) {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ProductType)
            .put<ProductType>(url, value)
        )
      );
  }

  deleteProductType(id: any) {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ProductType)
            .delete<ProductType>(url + '/' + id)
        )
      );
  }

  public findPaginated(
    params?: ProductTypeSearchCriteria
  ): Observable<PaginatedProductTypes> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedProductTypes)
          .get<PaginatedProductTypes>(url + '/paginated', {
            params: SearchParams.toHttpParams(params)
          })
      )
    );
  }

  public find(
    searchCriteria?: ProductTypeSearchCriteria
  ): Observable<ProductType[]> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.transformResponseTo(ProductType).get<ProductType[]>(url, {
          params: SearchParams.toHttpParams(searchCriteria)
        })
      )
    );
  }
}
