import {AbstractControl, FormArray, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {
  static multipleCheckboxRequireOne(fa: FormArray) {
    let valid = false;

    for (let x = 0; x < fa.length; ++x) {
      if (fa.at(x).value) {
        valid = true;
        break;
      }
    }
    return valid
      ? null
      : {
        multipleCheckboxRequireOne: true
      };
  }

  static buildError(key: string, val: boolean): any {
    const error = {};
    error[key] = val;
    return error;
  }

  static emailIsValid(email: string) {
    if (email) {
      const regexPattern = new RegExp(
        /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/
      );
      return email.match(regexPattern);
    }
    return true;
  }

  static containsOnlySpace(control: AbstractControl) {
    if (control.value == null || control.value === '' || typeof control.value == 'number') {
      return false;
    }

    return control.value.trim().length === 0;
  }

  static vimeoUrlIsValid(url: string) {
    if (url) {
      const regexPattern = new RegExp(
        /^((https)?:\/\/((www\.|player\.?)vimeo\.com))\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
      );
      return url.match(regexPattern);
    }
    return true;
  }

  static validDate(format: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      return !abstractControl.value ||
      moment(abstractControl.value, format, true).isValid()
        ? null
        : CustomValidators.buildError('dateNotValid', true);
    };
  }

  static dateBeforeDate(
    beforeControl: AbstractControl,
    afterControl: AbstractControl,
    format = 'dd/MM/yyyy',
    errorKey = 'notBefore'
  ): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const before = moment(beforeControl.value, format, true);
      const after = moment(afterControl.value, format, true);

      return !before.isValid() || !after.isValid() || before.isBefore(after)
        ? null
        : CustomValidators.buildError(errorKey, true);
    };
  }

  static numberIsInferior(control: number, maxValue: number) {
    if (maxValue !== null && maxValue !== undefined
      && control !== null && control !== undefined
      && control.toString() !== "") {
      return control <= maxValue
    } else {
      return true;
    }
  }

  static numberIsSuperior(control: number, minValue: number) {
    if (minValue !== null && minValue !== undefined
      && control !== null && control !== undefined
      && control.toString() !== "") {
      return minValue <= control
    } else {
      return true;
    }
  }
}
