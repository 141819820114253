import {Type} from 'class-transformer';
import {CodedValue} from '../../CodedValue';

export class PostalAddress {
  address: string;
  zipCode: string;
  city: string;

  @Type(() => CodedValue)
  country: CodedValue;
  building: string;
}
