<button
  [ngClass]="classMap"
  [attr.type]="type != '' ? type : ''"
  [disabled]="disabled || (status == loadingStatus)"
  (click)="btnClick.emit($event)"
  id="{{id}}"
>
  <div *ngIf="(status == errorStatus)">{{'common.error' | translate}}</div>
  <div *ngIf="(status != errorStatus)" id="{{id}}-button-content">
    <i [ngClass]="faIconOnLoading" *ngIf="faIconOnLoading && status === loadingStatus"></i>
    <i [ngClass]="faIcon" *ngIf="faIcon"></i>
    <ng-content></ng-content>
  </div>
</button>
