import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHandlerService} from './http/http-handler.service';

@Injectable()
export class CharsetsService {

  private readonly url = this.environment.apiBaseHref + '/api/enums/charsets';

  constructor(
    private http: HttpHandlerService,
    @Inject('env') private environment
  ) {}

  public getCharsets(): Observable<string[]> {
    return this.http.get(this.url);
  }
}
