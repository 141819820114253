import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ButtonsModule} from './atoms/buttons/buttons.module';
import {UiFormsModule} from './atoms/forms/ui-forms.module';
import {LinksModule} from './atoms/links/links.module';
import {ATitleViewComponent} from './atoms/a-title-view/a-title-view.component';
import {AResultNumberComponent} from './atoms/a-result-number/a-result-number.component';
import {ABreadcrumbItemComponent} from './atoms/a-breadcrumb-item/a-breadcrumb-item.component';
import {ATitleSectionComponent} from './atoms/a-title-section/a-title-section.component';
import {AComboBoxComponent} from './atoms/forms/a-combo-box/a-combo-box.component';
import {ASelectComponent} from './atoms/forms/a-select/a-select.component';
import {ACheckboxSelectorComponent} from './atoms/forms/a-checkbox-selector/a-checkbox-selector.component';
import {ATabComponent} from './atoms/a-tab/a-tab.component';
import {ABadgeComponent} from './atoms/a-badge/a-badge.component';
import {AMessageComponent} from './atoms/a-message/a-message.component';
import {AReadOnlyComponent} from './atoms/a-read-only/a-read-only.component';
import {AAvatarComponent} from './atoms/a-avatar/a-avatar.component';
import {AMouseflow} from './atoms/a-mouseflow/a-mouseflow.component';
import {ASorterComponent} from './atoms/a-sorter/a-sorter.component';
import {MMultiStepFormComponent} from './molecules/multi-step-form/m-multi-step-form/m-multi-step-form.component';
import {MFormStepThreadComponent} from './molecules/multi-step-form/m-form-step-thread/m-form-step-thread.component';
import {MFormStepDetailComponent} from './molecules/multi-step-form/m-form-step-detail/m-form-step-detail.component';
import {AMentionComponent} from './atoms/a-mention/a-mention.component';
import {AComboBoxRefComponent} from './atoms/forms/a-combo-box-ref/a-combo-box-ref.component';
import {ATipOffMessageComponent} from './atoms/a-tip-off-message/a-tip-off-message.component';
import {AOpenableBlockComponent} from './atoms/a-openable-block/a-openable-block.component';
import {AModulesNavBarMenuItemsComponent} from './atoms/a-modules-nav-bar-menu-items/a-modules-nav-bar-menu-items.component';
import {AModulesNavMenuItemsComponent} from './atoms/a-modules-nav-menu-items/a-modules-nav-menu-items.component';
import {ACellTextOverflowedComponent} from './atoms/a-cell-text-overflowed/a-cell-text-overflowed.component';
import {ADisplayNullableValueComponent} from './atoms/a-display-nullable-value/a-display-nullable-value.component';
import {ASeparatorComponent} from './atoms/a-separator/a-separator.component';
import {SpacerComponent} from './spacer/spacer.component';
import {MBreadcrumbComponent} from './molecules/m-breadcrumb/m-breadcrumb.component';
import {MTabsComponent} from './molecules/m-tabs/m-tabs.component';
import {MDropdownListComponent} from './molecules/m-dropdown-list/m-dropdown-list.component';
import {MPopupComponent} from './molecules/m-popup/m-popup.component';
import {MEditableTextComponent} from './molecules/m-editable-text/m-editable-text.component';
import {MCalloutComponent} from './molecules/m-callout/m-callout.component';
import {MSirenCheckInputComponent} from './molecules/m-siren-check-input/m-siren-check-input.component';
import {MSelectableItemTableComponent} from './molecules/m-selectable-item-table/m-selectable-item-table.component';
import {MServiceDeskButtonPopinComponent} from './molecules/m-service-desk-button-popin/m-service-desk-button-popin.component';
import {MPaginatedTableComponent} from './molecules/m-paginated-table/m-paginated-table.component';
import {MLocalPaginatedTableComponent} from './molecules/m-local-paginated-table/m-local-paginated-table.component';
import {MEditableTableComponent} from './molecules/m-editable-table/m-editable-table.component';
import {MRightHeaderDropdownMenuComponent} from './molecules/m-right-header-dropdown-menu/m-right-header-dropdown-menu.component';
import {MModulesNavMenuComponent} from './molecules/m-modules-nav-menu/m-modules-nav-menu.component';
import {MFilterDataComponent} from './molecules/m-filter-data/m-filter-data.component';
import {RouterModule} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {NgSelectModule} from '@ng-select/ng-select';
import {BaseDirectivesModule} from '../directives/base.directives.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore
} from '@ngx-translate/core';
import {BaseCoreServicesModule} from '../modules/core/services/base-core.services.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {AComplementaryMessageComponent} from './atoms/a-complementary-message/a-complementary-message.component';
import {MHeaderCompanySelectComponent} from './molecules/m-header-company-select/m-header-company-select.component';
import {AProgressBarComponent} from './atoms/a-progress-bar/a-progress-bar.component';
import {ACompanySelectComponent} from './atoms/forms/a-company-select/a-company-select.component';
import {ACompanyTypeSelectComponent} from './atoms/forms/a-company-type-select/a-company-type-select.component';
import {FileUploadModule} from 'ng2-file-upload';
import {MLogoEditComponent} from './molecules/m-logo-edit/m-logo-edit.component';
import {ATextAreaComponent} from './atoms/a-text-area/a-text-area.component';
import {MConfirmPopupComponent} from './molecules/m-popup/m-confirm-popup/m-confirm-popup.component';
import {MDeletePopupComponent} from './molecules/m-popup/m-delete-popup/m-delete-popup.component';
import {MGenericPopupComponent} from './molecules/m-popup/m-generic-popup/m-generic-popup.component';
import {ABooleanDisplayComponent} from './atoms/a-boolean-display/a-boolean-display.component';
import {AEditorComponent} from './atoms/a-editor/a-editor.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {MDeleteConfirmPopupComponent} from './molecules/m-popup/m-delete-confirm-popup/m-delete-confirm-popup.component';
import {MViewTabsComponent} from './molecules/m-view-header/m-view-tabs/m-view-tabs.component';
import {MViewTitleComponent} from './molecules/m-view-header/m-view-title/m-view-title.component';
import {MViewHeaderComponent} from './molecules/m-view-header/m-view-header.component';
import {MImportFileComponent} from './molecules/m-import-file/m-import-file.component';
import {MImportReportComponent} from './molecules/m-import-report/m-import-report.component';
import {MSpreadsheetSessionEditorComponent} from './molecules/m-spreadsheet-session-editor/m-spreadsheet-session-editor.component';
import {MDataIntegrationPopupComponent} from './molecules/m-popup/m-data-integration-popup/m-data-integration-popup.component';
import {MDataIntegrationComponent} from './molecules/m-data-integration/m-data-integration.component';
import {MIntegrationFormatComponent} from './molecules/m-integration-format/m-integration-format.component';
import {MFooterFormComponent} from './molecules/m-footer-form/m-footer-form.component';
import {MHelpMessageComponent} from './molecules/m-help-message/m-help-message.component';
import {ABreadcrumbStepComponent} from './atoms/a-breadcrumb-step/a-breadcrumb-step.component';
import {MWebHookConfigEditComponent} from './molecules/m-webhook-config-edit/m-web-hook-config-edit.component';
import {APaginatedTableLimitExceededNoticeComponent} from './atoms/a-paginated-table-limit-exceeded-notice/a-paginated-table-limit-exceeded-notice.component';
import {MPaginatedTableNavigationBarComponent} from './molecules/m-paginated-table-navigation-bar/m-paginated-table-navigation-bar.component';
import {ALoadingSpinnerComponent} from './atoms/a-loading-spinner/a-loading-spinner.component';
import { MEnvBannerComponent } from './molecules/m-env-banner/m-env-banner.component';
import {MMappingSelectComponent} from './molecules/m-mapping-select/m-mapping-select.component';
import {MFileMappingFieldConfigComponent} from './molecules/m-file-mapping-field-config/m-file-mapping-field-config.component';
import {AFileMappingTypeSelectComponent} from './atoms/a-file-mapping-type-select/a-file-mapping-type-select.component';
import {DragulaModule} from 'ng2-dragula';
import {AClipboardContentComponent} from './atoms/a-clipboard-content/a-clipboard-content.component';
import {
  AConfigurationActiveStatusComponent
} from './atoms/a-configuration-active-status/a-configuration-active-status.component';
import {
  AServiceDeskWorkspaceSelectComponent
} from './atoms/a-service-desk-workspace-select/a-service-desk-workspace-select.component';

const components: any[] = [
  ASorterComponent,
  AResultNumberComponent,
  ATitleViewComponent,
  ABreadcrumbItemComponent,
  ABreadcrumbStepComponent,
  ATitleSectionComponent,
  AComboBoxComponent,
  AComboBoxRefComponent,
  ASelectComponent,
  ACheckboxSelectorComponent,
  ATabComponent,
  ABadgeComponent,
  AMessageComponent,
  AMentionComponent,
  AReadOnlyComponent,
  AAvatarComponent,
  ATipOffMessageComponent,
  AOpenableBlockComponent,
  AModulesNavBarMenuItemsComponent,
  AModulesNavMenuItemsComponent,
  ACellTextOverflowedComponent,
  ADisplayNullableValueComponent,
  AComplementaryMessageComponent,
  ASeparatorComponent,
  MDropdownListComponent,
  MBreadcrumbComponent,
  MTabsComponent,
  MMultiStepFormComponent,
  MFormStepThreadComponent,
  MFormStepDetailComponent,
  SpacerComponent,
  MEditableTextComponent,
  MCalloutComponent,
  MPopupComponent,
  MConfirmPopupComponent,
  MDeletePopupComponent,
  MGenericPopupComponent,
  MSirenCheckInputComponent,
  MSelectableItemTableComponent,
  MServiceDeskButtonPopinComponent,
  MPaginatedTableComponent,
  MPaginatedTableNavigationBarComponent,
  MLocalPaginatedTableComponent,
  MEditableTableComponent,
  MRightHeaderDropdownMenuComponent,
  MModulesNavMenuComponent,
  MFilterDataComponent,
  MHeaderCompanySelectComponent,
  AProgressBarComponent,
  ACompanySelectComponent,
  ACompanyTypeSelectComponent,
  MLogoEditComponent,
  ATextAreaComponent,
  ABooleanDisplayComponent,
  AEditorComponent,
  MDeleteConfirmPopupComponent,
  AEditorComponent,
  MViewTabsComponent,
  MViewTitleComponent,
  MViewHeaderComponent,
  MImportFileComponent,
  MImportReportComponent,
  MSpreadsheetSessionEditorComponent,
  MDataIntegrationPopupComponent,
  MDataIntegrationComponent,
  MIntegrationFormatComponent,
  MWebHookConfigEditComponent,
  APaginatedTableLimitExceededNoticeComponent,
  ALoadingSpinnerComponent,
  AMouseflow,
  MMappingSelectComponent,
  MFileMappingFieldConfigComponent,
  ATipOffMessageComponent,
  AFileMappingTypeSelectComponent,
  AClipboardContentComponent,
  AConfigurationActiveStatusComponent,
  AServiceDeskWorkspaceSelectComponent
];

const subModules: any = [UiFormsModule, ButtonsModule, LinksModule];

function translateLoaderFactory(http: HttpClient, environment: any) {
  return {
    getTranslation(lang: string): any {
      const headers: HttpHeaders = new HttpHeaders();
      headers.append('Accept-Language', lang);
      return http
        .get('' + environment.apiBaseHref + '/api/i18n/labels-base', {
          headers: headers
        })
        .pipe(map((res) => res));
    }
  };
}

@NgModule({
  declarations: [
    components,
    MDataIntegrationComponent,
    MFooterFormComponent,
    MHelpMessageComponent,
    MEnvBannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, 'env']
      },
      defaultLanguage: 'fr'
    }),
    subModules,
    RouterModule,
    NgSelectModule,
    BaseDirectivesModule,
    BaseCoreServicesModule,
    TooltipModule,
    EditorModule,
    FileUploadModule,
    DragulaModule
  ],
    exports: [
        components,
        subModules,
        MFooterFormComponent,
        MHelpMessageComponent,
        MEnvBannerComponent
    ],
  providers: [TranslateStore]
})
export class BaseComponentsModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<BaseComponentsModule> {
    return {
      ngModule: BaseComponentsModule,
      providers: [
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}
