import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {ConfigService} from './config.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PlatformLocation} from '@angular/common';

@Injectable()
export class SentryService {
  private alreadyCreated = false;

  constructor(
    private configService: ConfigService,
    private platformLocation: PlatformLocation
  ) {}

  public tryInitSentry(
    userId: string,
    login: string,
    email: string
  ): Observable<boolean> {
    return this.configService.getConfig().pipe(
      map((config) => {
        const ui = this.platformLocation
          .getBaseHrefFromDOM()
          .replace(/\//g, '');
        if (
          config.sentryConfigs[ui] &&
          config.sentryConfigs[ui].enabled &&
          !this.alreadyCreated
        ) {
          this.alreadyCreated = !this.alreadyCreated;
          Sentry.init({
            dsn: config.sentryConfigs[ui].dsn,
            release: 'beclm-' + config.moduleName + '@' + config.version.number,
            environment: config.env,
            debug: true
          });
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: userId,
              username: login,
              email
            });
          });
          Sentry.setTag('ui', 'true');
          return true;
        }
        return false;
      })
    );
  }
}
