import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {CompanyCreationModel} from '../../domain/companyCreationModel/CompanyCreationModel';

@Injectable()
export class CompanyCreationModelService {
  protected readonly baseUrl = '/api/companyCreationModelsDefault';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findAll(): Observable<CompanyCreationModel[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyCreationModel)
            .get<CompanyCreationModel[]>(`${url}`)
        )
      );
  }
}
