import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CaptchaAction,
  CoreConfigService,
  ModuleService,
  NotificationService,
  SecurityPolicy,
  SecurityPolicyService,
  ServiceDeskService,
  Session,
  SessionService,
  snakeToCamel,
  Token,
  UiModule,
  UserCredential,
  UserCredentialService,
  UserService,
  VersionService
} from 'base';
import {Md5} from 'ts-md5';
import {catchError, finalize, map} from 'rxjs/operators';
import {of} from 'rxjs';
import * as _ from 'lodash';
import {AstreeService} from '../../services/astree.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
  forgotPasswordMode = false;
  renewPasswordMode = false;
  badLogin = false;
  lockedUser = false;
  samePreviousPassword = false;
  passwordExpired = false;
  accessDenied = false;
  registrationNotFinished = false;

  signInTab = LoginTab.SIGN_IN;
  selectedTab = LoginTab.SIGN_IN;

  lastName: string;
  login: string;
  password: string;
  passwordConfirmation: string;
  passwordRenewalToken: string;
  redirect: string;
  securityPolicy: SecurityPolicy;
  astreeUrl: string;
  serviceDeskUrl: string;
  modules: UiModule[] = [];
  selectedModule: UiModule;

  public currentSentence = 1;

  registrationEnabled = false;
  currentYear = new Date().getFullYear();
  loginCaptchaToken: string;

  envName: string;
  showEnvBanner = false;

  show = false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private userService: UserService,
    private userCredentialService: UserCredentialService,
    private moduleService: ModuleService,
    private versionService: VersionService,
    private astreeService: AstreeService,
    private serviceDeskService: ServiceDeskService,
    private securityPolicyService: SecurityPolicyService,
    private coreConfigService: CoreConfigService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadRegistrationStatus();
    this.loadVersion();
    this.loadRoute();
    this.loadModulesToDisplay();
    this.loadAstreeServiceUrl();
    this.loadServiceDeskUrl();
    this.animatedSentence();
  }

  private loadRoute() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('passwordRenewalToken')) {
        this.userCredentialService
          .checkPasswordRenewalToken(queryParams.get('passwordRenewalToken'))
          .subscribe((userCredential) => {
            if (userCredential) {
              this.passwordRenewalToken = queryParams.get(
                'passwordRenewalToken'
              );
              this.renewPasswordMode = true;

              this.securityPolicyService
                .getByPasswordRenewalToken(this.passwordRenewalToken)
                .subscribe((value) => {
                  this.securityPolicy = value;
                });
            }
          });
      }
      if (queryParams.get('redirect')) {
        this.redirect = atob(queryParams.get('redirect'));
        this.sessionService.currentSession(true).subscribe((session) => {
          if (session) {
            if (this.redirect) {
              this.redirectToOrigin(session, this.redirect);
            }
          }
        });
      }
      if (queryParams.get('logout')) {
        this.sessionService.logout().subscribe(() => {});
      }
      if (queryParams.get('accessDenied')) {
        this.accessDenied = true;
      }
      if (queryParams.get('registrationNotFinished')) {
        this.router.navigate(['/register']);
      }
    });
  }

  private loadAstreeServiceUrl() {
    this.astreeService
      .getConfig()
      .subscribe(({webUrl}) => (this.astreeUrl = webUrl));
  }

  private loadServiceDeskUrl() {
    this.serviceDeskService
      .getBaseUrl()
      .subscribe((baseUrl) => (this.serviceDeskUrl = baseUrl));
  }

  private loadRegistrationStatus() {
    this.coreConfigService.getRegistrationEnabled().subscribe((boolean) => {
      this.registrationEnabled = boolean;
    });
  }

  private loadVersion() {
    this.versionService.getVersion().subscribe((version) => {

      this.envName = version.env;
      this.showEnvBanner = version.isNotProdVersion() && version.isNotProdClientVersion()

      console.log(
        'version: ' +
          version.number +
          ' ' +
          version.commit +
          '/' +
          version.date +
          '@' +
          version.env
      );
    });
  }

  private loadModulesToDisplay() {
    this.moduleService.modules
      .pipe(
        map((modules) => {
          return _(modules)
            .filter((module) => !!module.uiModuleByName)
            .flatMap(
              (module) =>
                Object.keys(module.uiModuleByName).map(
                  (key) => module.uiModuleByName[key]
                ) as UiModule[]
            )
            .value();
        }),
        map((modules) =>
          modules.filter(
            (module) =>
              module.uiUrl &&
              module.name !== 'user' &&
              module.name !== 'admin' &&
              module.name !== 'dashboard'
          )
        )
      )
      .subscribe((uiModules) => {
        this.modules = uiModules;
      });
  }

  logoutAndSignIn(): void {
    this.sessionService
      .logout()
      .pipe(finalize(() => this.submitSignIn()))
      .subscribe(() => this.submitSignIn());
  }

  private submitSignIn() {
    this.recaptchaV3Service.execute(CaptchaAction.LOGIN).subscribe(
      (value) => {
        this.loginCaptchaToken = value;
        this.signIn();
      },
      (error) => {
        console.dir(error);
        this.notificationService
          .error('common.google.recaptcha.error')
          .subscribe();
      }
    );
  }

  private signIn() {
    this.sessionService
      .authenticateFromLoginAndPassword(this.login, this.password, this.loginCaptchaToken)
      .pipe(
        catchError((err) => {
          if (err.status === 423) {
            this.lockedUser = true;
          } else {
            if (err.error.indexOf('password expired') !== -1) {
              this.passwordExpired = true;
            } else {
              this.badLogin = true;
            }
          }
          return of<Session>();
        })
      )
      .subscribe((session) => {
        this.sessionService.reloadSession(() => {
          if (this.redirect) {
            this.redirectToOrigin(session, this.redirect);
          } else {
            this.moduleService.moduleByName.subscribe((moduleByName) => {
              window.location.href =
                moduleByName['dashboard'].uiModuleByName['dashboard'].uiUrl +
                '/home';
            });
          }
        });
      });
  }

  private redirectToOrigin(session, redirect) {
    const regexpResult = redirect.match('^(https?://[^#]+)(#(.*))?');
    if (regexpResult[3]) {
      window.location.href =
        regexpResult[1] +
        '#/login?navigateTo=' +
        btoa(regexpResult[3]) +
        '&loginToken=' +
        session.loginToken;
    } else {
      window.location.href =
        regexpResult[1] +
        '#/login?navigateTo=&loginToken=' +
        session.loginToken;
    }
  }

  forgotPassword(): void {
    this.userCredentialService
      .requestRenewalPassword(this.login)
      .subscribe(() => (this.forgotPasswordMode = false));
  }

  renewPassword(): void {
    const userCredential = new UserCredential();
    userCredential.login = '';
    userCredential.password = Md5.hashStr(this.password);
    userCredential.passwordRenewalToken = new Token(this.passwordRenewalToken);
    this.userCredentialService.renewPassword(userCredential).subscribe(
      () => {
        this.renewPasswordMode = false;
      },
      (error) => {
        if (error.status === 409) {
          this.samePreviousPassword = true;
        }
      }
    );
    this.password = undefined;
    this.passwordConfirmation = undefined;
  }

  passwordMatchWithSecurityPolicy() {
    if (this.securityPolicy != null) {
      return (
        this.password !== undefined &&
        this.password.match(this.securityPolicy.pwdPattern)
      );
    }
  }

  selectModule(module: UiModule): void {
    this.redirect = module.uiUrl;
    this.selectedModule = module;
  }

  getModuleCssClass(module: UiModule): string {
    return '_' + snakeToCamel(module.name);
  }

  getModuleLogo(module: UiModule): string {
    const imgPath = 'common/assets/images/svg/modules-icons/';

    switch (module.name) {
      case 'company':
        return imgPath + 'beclm_company_logo_white_small.svg';

      case 'lab':
        return imgPath + 'beclm_lab_logo_white_small.svg';

      case 'compliance-binder':
        return imgPath + 'beclm_compliance_logo_white_small.svg';

      case 'e-learning':
        return imgPath + 'beclm_elearning_logo_white_small.svg';

      case 'blacklist':
        return imgPath + 'beclm_blacklist_logo_white_small.svg';

      case 'pep':
        return imgPath + 'beclm_pep_logo_white_small.svg';

      case 'dashboard':
        return imgPath + 'beclm_dashboard_logo_white_small.svg';

      case 'person':
        return imgPath + 'beclm_person_logo_white_small.svg';

      case 'product':
        return imgPath + 'beclm_product_logo_white_small.svg';
    }
  }

  animatedSentence(): void {
    setTimeout(() => {
      if (this.currentSentence === 3) {
        this.currentSentence = 1;
      } else {
        this.currentSentence = this.currentSentence + 1;
      }
      this.animatedSentence();
    }, 3000);
  }

  changePasswordVisibility(): void {
    this.show = !this.show
  }
}

enum LoginTab {
  SIGN_IN = 'LOGIN',
  SIGN_UP = 'SIGN'
}
