import {Transform, Type} from 'class-transformer';
import {CodedValue} from '../CodedValue';
import {NaturalPerson} from '../natural/NaturalPerson';
import {CompanyExternalData} from '../company-siren/CompanyExternalData';
import {PostalAddress} from '../natural/main-information/PostalAddress';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../../domain/Reference';
import {Person} from '../Person';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {SelectableRef} from '../../../../../../domain/SelectableRef';
import {Contract} from '../natural/main-information/Contract';
import {HeadcountRange} from '../../../../../core-shared/domain/person/HeadcountRange';
import {ExternalApiRequestStatus} from '../api-request-status/ExternalApiRequestStatus';
import {ApiType} from '../../../../../core-shared/domain/apiUser/ApiType';
import {InsurerContract} from '../natural/main-information/InsurerContract';
import {PersonMetaData} from '../../../../../blacklist-pep/domain/person/PersonMetaData';
import {PersonAdditionalInformation} from '../../../../../blacklist-pep/domain/person/PersonAdditionalInformation';
import {AccidentEvent} from '../../../../../blacklist-pep/domain/person/AccidentEvent';
import {LegalEntityExtraData} from './LegalEntityExtraData';

export class LegalEntity extends Person {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'legalEntity';

  sirenCode: string;
  name: string;
  oriasId: string;
  @Transform(LocalDateTransformer.transformTo())
  creationDate: LocalDate;
  @Transform(LocalDateTransformer.transformTo())
  registrationDate: LocalDate;
  issuingOffice: string;

  @Type(() => CodedValue)
  naf: CodedValue[] = new Array<CodedValue>();
  @Type(() => PostalAddress)
  postalAddress: PostalAddress = new PostalAddress();

  @Type(() => CompanyExternalData)
  companyExternalData: CompanyExternalData = new CompanyExternalData();

  @Type(() => Contract)
  contract: Contract = new Contract();

  @Transform(ReferenceTransformer.transformTo(NaturalPerson))
  recipientRefs: Reference<NaturalPerson>[];

  @Type(() => ExternalApiRequestStatus)
  apiRequestsStatus: Map<ApiType, ExternalApiRequestStatus> = new Map<
    ApiType,
    ExternalApiRequestStatus
  >();

  legalCategory: string;
  headcount: number;
  headcountRange: HeadcountRange;
  ageaNumber: string;
  @Type(() => InsurerContract)
  contracts: InsurerContract[] = [];
  @Type(() => PersonMetaData)
  personMetaData: PersonMetaData;
  @Type(() => PersonAdditionalInformation)
  personAdditionalInformation: PersonAdditionalInformation;
  @Type(() => AccidentEvent)
  accidentEvents: AccidentEvent[] = [];
  legalEntityExtraData: LegalEntityExtraData = new LegalEntityExtraData();


  constructor() {
    super('com.beclm.core.api.person.legalEntity.LegalEntity');
  }

  public static toSelectableRef(
    reference: Reference<LegalEntity>
  ): SelectableRef<LegalEntity> {
    const selectableRef = new SelectableRef<LegalEntity>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<LegalEntity> {
    const selectableRef = new SelectableRef<LegalEntity>();
    selectableRef.id = new Reference<LegalEntity>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
