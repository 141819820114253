import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {classToPlain} from 'class-transformer';
import {UtilsService} from '../../services/utils.service';

export abstract class HttpRequestHandler<T> {
  private readonly httpClient: HttpClient;

  protected constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public get<X>(url: string, options?: any): Observable<X> {
    return this.callHTTPMethodTransformer<X>('get', url, options);
  }

  public put<X extends T | Array<T>>(
    url: string,
    body: any | null,
    options?: any
  ): Observable<X> {
    return this.callHTTPMethodTransformer<X>(
      'put',
      url,
      classToPlain(body),
      options
    );
  }

  public post<X extends T | Array<T>>(
    url: string,
    body: any | null,
    options?: any
  ): Observable<X> {
    return this.callHTTPMethodTransformer<X>(
      'post',
      url,
      classToPlain(body),
      options
    );
  }

  public delete<X>(url: string, options?: any): Observable<X | T> {
    return this.callHTTPMethodTransformer<X | T>('delete', url, options);
  }

  private callHTTPMethodTransformer<Y>(
    method: string,
    ...args: any[]
  ): Observable<Y> {
    // To avoid error TS2556, see https://github.com/Microsoft/TypeScript/issues/4130
    return this.httpClient[method].call(this.httpClient, ...args).pipe(
      map((data: Y) => {
        if (UtilsService.isArray(data)) {
          return (<any>data).map((item: any) => this.handleValue(item));
        }

        return this.handleValue(data);
      }),
      take(1)
    );
  }

  protected abstract handleValue(value: any): any;
}
