import {Component, Inject, Input, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'a-mouseflow',
  templateUrl: './a-mouseflow.component.html',
  styleUrls: ['./a-mouseflow.component.scss']
})
export class AMouseflow implements OnInit {

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  public ngOnInit() {

    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
            window._mfq = window._mfq || [];
            (function() {
              var mf = document.createElement("script");
              mf.type = "text/javascript"; mf.defer = true;
              mf.src = "//cdn.mouseflow.com/projects/c5a551d4-ec6c-4b12-8d97-7ffe3e6e85fb.js";
              document.getElementsByTagName("head")[0].appendChild(mf);
            })();
        `;

    this._renderer2.appendChild(this._document.body, script);
  }
}
