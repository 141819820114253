import {Transform, Type} from 'class-transformer';
import {LegalEntity} from './LegalEntity';
import {ClassedEntity} from '../../../../../../domain/ClassedEntity';
import {PostalAddress} from '../natural/main-information/PostalAddress';
import {CodedValue} from '../CodedValue';
import {PersonDataCategory} from '../../data-category/PersonDataCategory';
import {PersonDataSource} from '../../data-source/PersonDataSource';
import {Reference} from '../../../../../../domain/Reference';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {NaturalPerson} from '../natural/NaturalPerson';
import {CompanyExternalData} from '../company-siren/CompanyExternalData';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {Contract} from '../natural/main-information/Contract';
import {HeadcountRange} from '../../../../../core-shared/domain/person/HeadcountRange';
import {ExternalApiRequestStatus} from '../api-request-status/ExternalApiRequestStatus';
import {ApiType} from '../../../../../core-shared/domain/apiUser/ApiType';
import {InsurerContract} from '../natural/main-information/InsurerContract';
import {PersonMetaData} from '../../../../../blacklist-pep/domain/person/PersonMetaData';
import {PersonAdditionalInformation} from '../../../../../blacklist-pep/domain/person/PersonAdditionalInformation';
import {AccidentEvent} from '../../../../../blacklist-pep/domain/person/AccidentEvent';
import {LegalEntityExtraData} from './LegalEntityExtraData';

export class LegalEntityModification extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'legalEntity';

  public id: string;

  sirenCode: string;
  name: string;
  oriasId: string;
  externalRefId: string;
  @Transform(LocalDateTransformer.transformTo())
  creationDate: LocalDate;
  @Transform(LocalDateTransformer.transformTo())
  registrationDate: LocalDate;
  issuingOffice: string;

  @Type(() => CodedValue)
  naf: CodedValue[] = new Array<CodedValue>();

  @Type(() => PostalAddress)
  postalAddress: PostalAddress = new PostalAddress();

  @Type(() => PersonDataCategory)
  personDataCategories: {[key: string]: PersonDataCategory} = {};

  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;

  @Type(() => CompanyExternalData)
  companyExternalData: CompanyExternalData = new CompanyExternalData();

  @Type(() => Contract)
  contract: Contract = new Contract();

  @Transform(ReferenceTransformer.transformTo(NaturalPerson))
  recipientRefs: Reference<NaturalPerson>[];

  @Type(() => ExternalApiRequestStatus)
  apiRequestsStatus: Map<ApiType, ExternalApiRequestStatus> = new Map<
    ApiType,
    ExternalApiRequestStatus
  >();

  legalCategory: string;
  headcount: number;
  headcountRange: HeadcountRange;
  personDataCategoriesToBeComputed = false;
  @Type(() => InsurerContract)
  contracts: InsurerContract[] = [];
  @Type(() => PersonMetaData)
  personMetaData: PersonMetaData;
  @Type(() => PersonAdditionalInformation)
  personAdditionalInformation: PersonAdditionalInformation;
  @Type(() => AccidentEvent)
  accidentEvents: AccidentEvent[] = [];
  legalEntityExtraData: LegalEntityExtraData = new LegalEntityExtraData();

  constructor() {
    super('com.beclm.core.api.person.legalEntity.LegalEntityModification');
  }

  public static fromLegalEntity(
    legalEntity: LegalEntity
  ): LegalEntityModification {
    const legalEntityModification = new LegalEntityModification();
    legalEntityModification.id = legalEntity.id;
    legalEntityModification.sirenCode = legalEntity.sirenCode;
    legalEntityModification.name = legalEntity.name;
    legalEntityModification.postalAddress = legalEntity.postalAddress;
    legalEntityModification.creationDate = legalEntity.creationDate;
    legalEntityModification.registrationDate = legalEntity.registrationDate;
    legalEntityModification.issuingOffice = legalEntity.issuingOffice;
    legalEntityModification.oriasId = legalEntity.oriasId;
    legalEntityModification.naf = legalEntity.naf;
    legalEntityModification.personDataCategories =
      legalEntity.personDataCategories;
    legalEntityModification.personDataSourceRef =
      legalEntity.personDataSourceRef;
    legalEntityModification.companyExternalData =
      legalEntity.companyExternalData;
    legalEntityModification.contract = legalEntity.contract;
    legalEntityModification.recipientRefs = legalEntity.recipientRefs;
    legalEntityModification.externalRefId = legalEntity.externalRefId;
    legalEntityModification.legalCategory = legalEntity.legalCategory;
    legalEntityModification.headcount = legalEntity.headcount;
    legalEntityModification.headcountRange = legalEntity.headcountRange;
    legalEntityModification.personDataCategoriesToBeComputed =
      legalEntity.personDataCategoriesToBeComputed;
    legalEntityModification.apiRequestsStatus = legalEntity.apiRequestsStatus;
    legalEntityModification.contracts = legalEntity.contracts;
    legalEntityModification.personMetaData = legalEntity.personMetaData;
    legalEntityModification.personAdditionalInformation = legalEntity.personAdditionalInformation;
    legalEntityModification.accidentEvents = legalEntity.accidentEvents;

    return legalEntityModification;
  }
}
