import {Type} from 'class-transformer';
import {_PrincipalType, Principal} from './Principal';

export function _SessionType() {
  return Session;
}

export class Session {
  @Type(_PrincipalType)
  principal: Principal = new Principal();
  loginToken: string;
}
