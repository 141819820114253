import {AbstractClassMapper} from './AbstractClassMapper';
import {Newable} from '../../Newable';

export class ClassMapper<T> extends AbstractClassMapper<T> {
  constructor(
    defaultImplem: Newable<T>,
    mapping: {[key: string]: Newable<T>} = {}
  ) {
    super('@class', defaultImplem, mapping);
  }
}
