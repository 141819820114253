export enum DataSourceType {
  BLACKLIST = 'BLACKLIST',
  PEP = 'PEP',
  PERSONS = 'PERSONS',
  ADVERSE_MEDIA = 'ADVERSE_MEDIA'
}

export function toModuleName(dataSourceType: DataSourceType): string {
  return dataSourceType.toLowerCase().replace('_', '-');
}
