<div
  [class]="'callout ' + fromType(type) + ' ' + fromCalloutSize(calloutSize) + ' ' + fromCalloutTxtAlign(calloutTxtAlign)"
>
  <div class="callout-icon">
    <i class="fa"></i>
  </div>
  <div class="callout-content">
    <div class="callout-content-txt">
      <ng-content select="[txt]"></ng-content>
    </div>

    <div class="callout-content-action">
      <ng-content select="[btn]"></ng-content>
    </div>
  </div>
</div>
