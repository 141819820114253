import {Person} from './Person';
import {NaturalPerson} from './natural/NaturalPerson';
import {ClassMapper} from '../../../../../domain/transformation/class-mapper/ClassMapper';
import {LegalEntity} from './legal-entity/LegalEntity';

export class PersonClassMappers {

  public static personClassMapper: ClassMapper<Person> = new ClassMapper(Person, {
    'com.beclm.core.api.person.naturalPerson.NaturalPerson': NaturalPerson,
    'com.beclm.core.api.person.legalEntity.LegalEntity': LegalEntity
  })
}
