import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../services/http/http-handler.service';
import {ModuleService} from '../../../services/module.service';
import {Reference} from '../../../domain/Reference';
import {Product} from '../domain/product/Product';
import {Company} from '../../core/domain/company/Company';

@Injectable()
export class ProductService {
  private readonly baseUrl: string = '/api/products';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findForCompany(companyRef: Reference<Company>): Observable<Product[]> {
    return this.moduleService
      .labBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Product)
            .get<Product[]>(`${url}/company/${companyRef.id}`)
        )
      );
  }
}
