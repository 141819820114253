import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Directive, Injectable, Input, SimpleChanges} from '@angular/core';
import {CustomValidators} from '../../utils/CustomValidators';

@Directive({
  selector: '[valueIsInRange]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValueIsInRangeValidatorDirective,
      multi: true
    }
  ]
})
@Injectable()
export class ValueIsInRangeValidatorDirective implements Validator {
  @Input() value: number;
  @Input() minValue;
  @Input() maxValue;

  private control: AbstractControl;

  validate(control: AbstractControl) {
    if (control) {
      this.control = control;
    }

    const isInferior = CustomValidators.numberIsInferior(
      this.value ? this.value : control.value,
      this.maxValue
    );
    const isSuperior = CustomValidators.numberIsSuperior(
      this.value ? this.value : control.value,
      this.minValue
    );

    let error = null;
    if (!isInferior || !isSuperior) {
      error = {};
    }
    if (!isInferior) {
      error['valueIsNotInRange'] = true;
      error['max'] = true;
    }
    if (!isSuperior) {
      error['valueIsNotInRange'] = true;
      error['min'] = true;
    }

    return error;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.minValue || changes.maxValue) && this.control) {
      this.control.updateValueAndValidity();
    }
  }
}
