import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {plainToClass} from 'class-transformer';
import {ExceptionError} from '../../domain/ExceptionError';

/**
 * BeclmErrorInterceptor catch all error instead of BeclmErrorHandler who will not catch all error http code.
 * We use it to catch 422 error code to send toastr on UI.
 */
@Injectable()
export class BeclmErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(Injector) private injector: Injector) {}

  private get notificationService(): NotificationService {
    return this.injector.get(NotificationService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        this.showUnprocessableEntityError(error);
        return throwError(error);
      })
    );
  }

  private showUnprocessableEntityError(error) {
    if (error instanceof HttpErrorResponse && error.status === 422) {
      const exceptionError = plainToClass(ExceptionError, error.error);
      this.notificationService
        .error('exception.code.' + exceptionError.code, {
          reference: exceptionError.reference
        })
        .subscribe();
    }
  }
}
