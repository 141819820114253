import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {NaturalPersonSearchParam} from '../../domain/person/person/natural/NaturalPersonSearchParam';
import {PaginatedNaturalPersons} from '../../domain/person/person/natural/PaginatedNaturalPersons';
import {SearchParams} from '../../../../domain/SearchParams';
import {NaturalPerson} from '../../domain/person/person/natural/NaturalPerson';
import {NaturalPersonModification} from '../../domain/person/person/natural/NaturalPersonModification';
import {Reference} from '../../../../domain/Reference';
import {FinancialSituation} from '../../domain/person/person/natural/financial/FinancialSituation';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {PersonDataSourceService} from './person-data-source.service';
import {PersonRiskStatus} from '../../domain/person/person/risk/PersonRiskStatus';

@Injectable()
export class NaturalPersonService {
  private readonly baseUrl = '/api/natural-persons';
  private _currentPerson = new BehaviorSubject<NaturalPerson>(undefined);

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    private personDataSourceService: PersonDataSourceService
  ) {}

  public loadCurrentPerson(person: NaturalPerson) {
    this._currentPerson.next(person);

    const subscription = timer(500, 1000).subscribe((t) => {
      this.getById(person.id).subscribe((personControlled) => {
        if (!personControlled.personDataCategoriesToBeComputed) {
          this._currentPerson.next(personControlled);
          subscription.unsubscribe();
        }
      });
    });
  }

  public observeCurrentPerson(): Observable<NaturalPerson> {
    return this._currentPerson;
  }

  public unloadCurrentPerson(): void {
    this._currentPerson.next(undefined);
  }

  public getCurrentOrLoadPerson(id: string): Observable<NaturalPerson> {
    if (
      this._currentPerson.getValue() === undefined ||
      this._currentPerson.getValue().id !== id
    ) {
      this.getById(id).subscribe((person) => {
        this.loadCurrentPerson(person);
        return this._currentPerson;
      });
    }
    return this._currentPerson;
  }

  public find(
    param: NaturalPersonSearchParam
  ): Observable<PaginatedNaturalPersons> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedNaturalPersons)
          .get<PaginatedNaturalPersons>(url, {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public getById(id: string): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .get<NaturalPerson>(url + '/' + id)
        )
      );
  }

  public createPerson(
    personModification: NaturalPersonModification
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .post<NaturalPerson>(url, personModification)
        )
      );
  }

  public deletePerson(id: string): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.delete<string>(url + '/' + id)));
  }

  public updatePerson(
    personModification: NaturalPersonModification
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .put<NaturalPerson>(url, personModification)
        )
      );
  }

  public updatePersonPepStatus(
    personModification: NaturalPersonModification
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .put<NaturalPerson>(`${url}?updatePepStatus=${true}`, personModification)
        )
      );
  }

  public save(
    personModification: NaturalPersonModification
  ): Observable<NaturalPerson> {
    personModification.personDataSourceRef = new Reference(
      this.personDataSourceService.getCurrentNaturalPersonDataSource()
    );
    if (personModification.id !== undefined) {
      return this.updatePerson(personModification);
    } else {
      return this.createPerson(personModification);
    }
  }

  public getFinancialSituationEstimation(
    financialSituation: FinancialSituation
  ): Observable<FinancialSituation> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(FinancialSituation)
            .post<FinancialSituation>(
              url + '/financial-situation/compute',
              financialSituation
            )
        )
      );
  }

  public computeFinancialSituationCompleteness(
    person: NaturalPersonModification
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Number)
            .post<number>(url + '/completeness/financial-situation', person)
        )
      );
  }

  public computeMainInformationCompleteness(
    person: NaturalPersonModification
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Number)
            .post<number>(url + '/completeness/main-information', person)
        )
      );
  }

  public computeRedflagCompleteness(
    person: NaturalPersonModification
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Number)
            .post<number>(url + '/completeness/redflag', person)
        )
      );
  }

  public computeEmployeeAdditionalDataCompleteness(
    person: NaturalPersonModification
  ): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Number)
            .post<number>(
              url + '/completeness/employee-additional-data',
              person
            )
        )
      );
  }

  public externalRefIdIsRegistered(
    externalRefId: string,
    personDataSourceRef: string
  ): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/${externalRefId}/registered`, {
          params: SearchParams.toHttpParams({
            personDataSourceRef: personDataSourceRef
          })
        })
      )
    );
  }

  public manualQualification(
    naturalPersonId: string,
    personRiskStatus: PersonRiskStatus
  ): Observable<NaturalPerson> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(NaturalPerson)
          .put<NaturalPerson>(
            url + '/' + naturalPersonId + '/qualification',
            null,
            {
              params: {
                personRiskStatus: personRiskStatus
              }
            }
          )
      )
    );
  }
}
