import {Transform} from 'class-transformer';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../domain/Reference';
import {Company} from '../../company/Company';
import {ScaledEvaluationScore} from './ScaledEvaluationScore';

export class ControlScoresEvaluationConfig extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'scoringConfig';

  @Transform(ReferenceTransformer.transformTo(Company))
  public companyRef: Reference<Company>
  public evaluationConfigByDataCategoryType: {[key: string]: ScaledEvaluationScore} = {}

  constructor() {
    super(
      'com.beclm.core.api.controlScoreEvaluationConfig.controlScoreEvaluationConfig'
    );
  }
}
