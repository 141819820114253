import {Component, Inject, OnInit} from '@angular/core';
import {Config, ConfigService} from 'base';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './appRoot.component.html',
  styleUrls: ['./appRoot.component.scss']
})
export class AppRootComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private readonly doc,
    private readonly configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.configService.getConfig().subscribe((config) => {
      if (config.tagManagerActive) {
        this.setGTagManager(config);
      }
    });
  }

  private setGTagManager(config: Config) {
    const script = this.doc.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML =
      '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
      'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
      'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
      '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl+ \'&gtm_auth=' +
      config.tagManagerAuth +
      '&gtm_preview=' +
      config.tagManagerEnv +
      '&gtm_cookies_win=x\';f.parentNode.insertBefore(j,f);\n' +
      '})(window,document,\'script\',\'dataLayer\',\'' +
      config.tagManagerId +
      '\');';
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}
