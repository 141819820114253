import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {SecurityPolicy} from '../../domain/user/security-policy/SecurityPolicy';

@Injectable()
export class SecurityPolicyService {
  private readonly baseUrl = '/api/securitypolicies';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public save(securityPolicy: SecurityPolicy): Observable<SecurityPolicy> {
    if (securityPolicy.id !== undefined) {
      return this.update(securityPolicy);
    } else {
      return this.create(securityPolicy);
    }
  }

  public create(securityPolicy: SecurityPolicy): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .post<SecurityPolicy>(url, securityPolicy)
        )
      );
  }

  public update(securityPolicy: SecurityPolicy): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .put<SecurityPolicy>(url, securityPolicy)
        )
      );
  }

  public delete(id: string): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .delete<SecurityPolicy>(url + '/' + id)
        )
      );
  }

  public getById(id: string): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .get<SecurityPolicy>(url + '/' + id)
        )
      );
  }

  public getByCompanyId(companyId: string): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .get<SecurityPolicy>(url + '/company/' + companyId)
        )
      );
  }

  public getDefaultSecurityPolicy(): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .get<SecurityPolicy>(url + '/default')
        )
      );
  }

  public findAll(): Observable<SecurityPolicy[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .get<SecurityPolicy[]>(url)
        )
      );
  }

  public getByPasswordRenewalToken(
    passwordRenewalToken: string
  ): Observable<SecurityPolicy> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SecurityPolicy)
            .get<SecurityPolicy>(
              `${url}/password-renewal-token/${passwordRenewalToken}`
            )
        )
      );
  }
}
