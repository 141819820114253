export class MonthlyIncome {
  wagesAndSalaries: number;
  industrialAndCommercialIncome: number;
  nonCommercialIncome: number;
  agriculturalIncome: number;
  predialIncome: number;
  movableAssetIncome: number;
  companyLeaderIncome: number;
  capitalGain: number;
}
