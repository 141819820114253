import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class OperationType extends ClassedEntity {
  public static readonly MODULE: string = 'lab';
  public static readonly COLLECTION: string = 'operationType';

  public name: string;

  constructor() {
    super('com.beclm.lab.domain.operation.OperationType');
  }
}
