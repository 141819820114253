import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AButtonCancelComponent} from './a-button-cancel/a-button-cancel.component';
import {AButtonDefaultComponent} from './a-button-default/a-button-default.component';
import {AButtonSubmitComponent} from './a-button-submit/a-button-submit.component';
import {AButtonSearchComponent} from './a-button-search/a-button-search.component';
import {AButtonSearchGoogleComponent} from './a-button-search-google/a-button-search-google.component';
import {AButtonRoundComponent} from './a-button-round/a-button-round.component';
import {AButtonTextComponent} from './a-button-text/a-button-text.component';
import {AButtonAddComponent} from './a-button-add/a-button-add.component';
import {AButtonDeleteComponent} from './a-button-delete/a-button-delete.component';
import {AButtonNextComponent} from './a-button-next/a-button-next.component';
import {AButtonBackComponent} from './a-button-back/a-button-back.component';
import {AButtonNavComponent} from './a-button-nav/a-button-nav.component';
import {AButtonLoadingComponent} from './a-button-loading/a-button-loading.component';
import {AButtonExportComponent} from './a-button-export/a-button-export.component';
import {AButtonImportComponent} from './a-button-import/a-button-import.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'ng2-tooltip-directive';
import {AButtonResetComponent} from './a-button-reset/a-button-reset.component';

const components = [
  AButtonCancelComponent,
  AButtonDefaultComponent,
  AButtonSubmitComponent,
  AButtonSearchComponent,
  AButtonSearchGoogleComponent,
  AButtonRoundComponent,
  AButtonTextComponent,
  AButtonAddComponent,
  AButtonDeleteComponent,
  AButtonNextComponent,
  AButtonLoadingComponent,
  AButtonBackComponent,
  AButtonNavComponent,
  AButtonExportComponent,
  AButtonImportComponent,
  AButtonResetComponent
];

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, RouterModule, TooltipModule],
  declarations: [components, AButtonNavComponent, AButtonSearchGoogleComponent],
  exports: components
})
export class ButtonsModule {}
