import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive, Input} from '@angular/core';
import {PasswordStrengthValidator} from '../../pages/login/password-strength.validator';

@Directive({
  selector: '[password-strength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordStrengthValidatorDirective,
      multi: true
    }
  ]
})
export class PasswordStrengthValidatorDirective implements Validator {
  @Input()
  passwordRegex: string;

  validate(control: AbstractControl): ValidationErrors {
    return PasswordStrengthValidator.validPasswordStrength(this.passwordRegex)(control);
  }
}
