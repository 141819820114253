import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonType} from '../../domain/person/person/PersonType';

@Injectable()
export class PersonDataCategoryService {
  private readonly baseUrl = '/api/personDataCategoryTypes';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getPersonDataCategories(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.get<string[]>(url)));


  }

  public getPersonDataCategoryTypesByPersonType(personType: PersonType): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
        this.http.get<string[]>(`${url}/person-type/${personType}`)));
  }


}
