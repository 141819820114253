import {ModuleWithProviders, NgModule} from '@angular/core';
import {TranslationService} from './translation.service';
import {HttpHandlerService} from './http/http-handler.service';
import {ModuleService} from './module.service';
import {ConfigService} from './config.service';
import {SentryService} from './sentry.service';
import {VersionService} from './version.service';
import {AuthenticationService} from './authentication.service';
import {AuthorizedModuleService} from './authorized-module.service';
import {ToastrModule} from 'ngx-toastr';
import {NotificationService} from './notification.service';
import {SessionStorageService} from './session-storage.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SessionService} from './session.service';
import {ModalService} from './modal.service';
import {DataIntegrationService} from './data-integration.service';
import {PreviewService} from './preview.service';
import {CharsetsService} from './charsets.service';
import {NavigationService} from './navigation.service';
import {RecaptchaService} from './recaptcha.service';

@NgModule({
  imports: [ToastrModule.forRoot(), BrowserAnimationsModule],
  providers: [
    TranslationService,
    HttpHandlerService,
    AuthorizedModuleService,
    ModuleService,
    ConfigService,
    SentryService,
    VersionService,
    AuthenticationService,
    NotificationService,
    SessionStorageService,
    SessionService,
    ModalService,
    DataIntegrationService,
    PreviewService,
    CharsetsService,
    NavigationService,
    RecaptchaService
  ]
})
export class BaseServicesModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<BaseServicesModule> {
    return {
      ngModule: BaseServicesModule,
      providers: [
        ModuleService,
        {
          provide: 'env',
          useValue: environment
        },
        AuthorizedModuleService,
        {
          provide: 'env',
          useValue: environment
        },
        ConfigService,
        {
          provide: 'env',
          useValue: environment
        },
        VersionService,
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}
