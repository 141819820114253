import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'm-env-banner',
  templateUrl: './m-env-banner.component.html',
  styleUrls: ['./m-env-banner.component.scss']
})
export class MEnvBannerComponent {
  @Input() envName: string;
  @Input() closeBtn: boolean = true;

  @Output() bannerClosed: EventEmitter<void> = new EventEmitter<void>();
  onCloseClick() {this.bannerClosed.emit();}
}
