import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Company} from '../../company/Company';
import {Reference} from '../../../../../domain/Reference';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Flow} from '../../../../blacklist-pep/domain/flow/Flow';
import {PersonToggleFeature} from './PersonToggleFeature';
import {TrialModeConfig} from './TrialModeConfig';
import {NeedMatchConfig} from './NeedMatchConfig';
import {VigilanceRiskRateByLevelsConfig} from './VigilanceRiskRateByLevelsConfig';
import {ApiUser} from '../../../../core-shared/domain/apiUser/ApiUser';
import {User} from '../../user/user/User';
import {PersonControlResultArchiverConfig} from './PersonControlResultArchiverConfig';
import {CompanyInpiApiConfig} from './CompanyInpiApiConfig';
import {CoreWebHookConfig} from '../../webhook/CoreWebHookConfig';

export class CoreCompanyConfig extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'companyConfig';

  @Transform(ReferenceTransformer.transformTo(Company))
  public companyRef: Reference<Company>;
  @Transform(ReferenceTransformer.transformTo(Flow))
  public blacklistPepFlowRef: Reference<Flow>;
  public toggleFeatures: string[] = [];
  public sftpUserName: string;
  @Type(() => TrialModeConfig)
  public trialModeConfig = new TrialModeConfig();
  @Type(() => NeedMatchConfig)
  public needMatchConfig = new NeedMatchConfig();
  @Type(() => CoreWebHookConfig)
  public coreWebHookConfigs: CoreWebHookConfig[] = [];
  @Type(() => VigilanceRiskRateByLevelsConfig)
  public vigilanceRiskRates: VigilanceRiskRateByLevelsConfig = new VigilanceRiskRateByLevelsConfig();
  @Type(() => ApiUser)
  public apiUsers: Map<string, ApiUser> = new Map<string, ApiUser>();
  @Type(() => CompanyInpiApiConfig)
  public companyInpiApiConfig = new CompanyInpiApiConfig();
  @Type(() => PersonControlResultArchiverConfig)
  public pcrArchiverConfig: PersonControlResultArchiverConfig = new PersonControlResultArchiverConfig();

  constructor() {
    super('com.beclm.person.api.companyConfig.coreCompanyConfig');
  }

  public hasPersonToggleFeature(): boolean {
    return this.toggleFeatures.indexOf(PersonToggleFeature.PERSON) !== -1;
  }

  public hasNeedExpressionToggleFeature(): boolean {
    return (
      this.toggleFeatures.indexOf(PersonToggleFeature.NEED_EXPRESSION) !== -1
    );
  }

  public hasOperationsShowToggleFeature(): boolean {
    return (
      this.toggleFeatures.indexOf(PersonToggleFeature.OPERATIONS_SHOW) !== -1
    );
  }

  public isPcrAllowed(user: User): boolean {
    const pcrAccessEnabled =
      this.pcrArchiverConfig.pcrAccessToCompanyAdmin && (user.isCompanyAdminOrHigher() || user.isAuditor());
    const pcrAccessDisabled =
      !this.pcrArchiverConfig.pcrAccessToCompanyAdmin && user.isAdminOrRestxAdmin();
    return pcrAccessEnabled || pcrAccessDisabled;
  }
}
