export class PaymentInformation {
  productName: string;
  defaultProductPriceExcludingTaxes: number;
  productPriceExcludingTaxes: number;
  productOnlyTaxes: number;
  totalPrice: number;
  couponName: string;
  couponCode: string;
  couponPrice: number;
}
