import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {CompanyType} from '../company/company-type/CompanyType';
import {PersonDataSource} from '../person/data-source/PersonDataSource';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';

export class CompanyCreationModel extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'companyCreationModel';

  public modelName: string;
  public modules: string[] = [];
  @Transform(ReferenceTransformer.transformTo(CompanyType))
  public companyTypeRef: Reference<CompanyType>;
  public userRoles: string[] = [];
  public personDataSources: PersonDataSource[] = [];
}
