import {Injectable} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {ModuleService} from './module.service';
import {HttpHandlerService} from './http/http-handler.service';
import {Observable} from 'rxjs';
import {SpreadSheetPreviewData} from '../domain/preview/SpreadSheetPreviewData';
import {PaginatedSpreadSheetPreviewData} from '../domain/preview/PaginatedSpreadSheetPreviewData';
import {Pagination} from '../domain/Pagination';

@Injectable()
export class PreviewService {
  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public saveSpreadSheetPreviewData(
    module: string,
    previewData: SpreadSheetPreviewData
  ): Observable<SpreadSheetPreviewData> {
    return this.moduleService
      .moduleBackendUrl(module, '/api/spreadsheet-preview-data')
      .pipe(
        switchMap((url) =>
          this.http.put<SpreadSheetPreviewData>(`${url}`, previewData)
        )
      );
  }

  public deleteSpreadSheetPreviewData(
    module: string,
    previewData: SpreadSheetPreviewData
  ): Observable<void> {
    return this.moduleService
      .moduleBackendUrl(
        module,
        '/api/spreadsheet-preview-data/' + previewData.id
      )
      .pipe(switchMap((url) => this.http.delete<void>(`${url}`)));
  }

  public findPaginatedSpreadSheetPreviewData(
    module: string,
    sessionId: string,
    startWith: string,
    pagination: Pagination
  ): Observable<PaginatedSpreadSheetPreviewData> {
    return this.moduleService
      .moduleBackendUrl(module, '/api/spreadsheet-preview-data/paginated')
      .pipe(
        switchMap((url) =>
          this.http.get<PaginatedSpreadSheetPreviewData>(
            `${url}?sessionId=${sessionId}&startWith=${startWith}&currentPage=${pagination.currentPage}&nbOfResultsPerPage=${pagination.nbOfResultsPerPage}`
          )
        )
      );
  }
}
