import {RegistrationProfile} from 'base';

export class UserRegistration {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  captchaToken: string;
  registrationProfile: RegistrationProfile;
}
