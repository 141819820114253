import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Reference} from '../../../../domain/Reference';
import {Company} from '../../domain/company/Company';
import {CorePersonIndicator} from '../../domain/person/corePersonIndicator/CorePersonIndicator';

@Injectable()
export class CorePersonIndicatorService {
  protected readonly baseUrl = '/api/core-person-indicator';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findByCompanyRef(
    companyRef: Reference<Company>
  ): Observable<CorePersonIndicator> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(CorePersonIndicator)
          .get<CorePersonIndicator>(`${url}/companyRef`, {
            params: {companyRef: companyRef}
          })
      )
    );
  }
}
