<button
  type="submit"
  class="button btnSubmit _primary"
  [class.expanded]="expanded"
  [class._success]="validate"
  [class._small]="small"
  [disabled]="disabled || loading"
  (click)="btnClick.emit($event)"
  [routerLink]="btnRouterLink"
  [queryParams]="btnRouterLinkQueryParams"
>
  <i [ngClass]="faIcon" *ngIf="faIcon"></i>
  <i class="fa fa-check" *ngIf="validate"></i>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>
