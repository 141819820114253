export type Visibility = 'HIGH' | 'HIGHREVERT' | 'MEDIUM' | 'LOW' | 'LOWREVERT';

export function fromVisibility(visibility: Visibility): string {
  switch (visibility) {
    case 'HIGHREVERT':
      return '_highRevert';
    case 'HIGH':
      return '_high';
    case 'MEDIUM':
      return '_medium';
    case 'LOWREVERT':
      return '_lowRevert';
    case 'LOW':
      return '_low';
  }
}

export type Size = 'XLARGE' | 'LARGE' | 'DEFAULT' | 'SMALL';

export function fromSize(size: Size): string {
  switch (size) {
    case 'XLARGE':
      return '_xlarge';
    case 'LARGE':
      return '_large';
    case 'DEFAULT':
      return '_default';
    case 'SMALL':
      return '_small';
  }
}

export type PositionIcon = 'RIGHT' | 'LEFT';

export function fromPositionIcon(positionIcon: PositionIcon): string {
  switch (positionIcon) {
    case 'RIGHT':
      return '_right';
    case 'LEFT':
      return '_left';
  }
}
