import {NgModule} from '@angular/core';
import {SafePipe} from './safe.pipe';

const components = [SafePipe];

@NgModule({
  exports: components,
  declarations: components,
  imports: []
})
export class PipesModule {}
