import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OperationType} from '../domain/operation/OperationType';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../services/http/http-handler.service';
import {Reference} from '../../../domain/Reference';
import {ModuleService} from '../../../services/module.service';
import {Company} from '../../core/domain/company/Company';

@Injectable()
export class OperationTypeService {
  private readonly baseUrl: string = '/api/operation-types';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findForCompany(
    companyRef: Reference<Company>
  ): Observable<OperationType[]> {
    return this.moduleService
      .labBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(OperationType)
            .get<OperationType[]>(`${url}/company/${companyRef.id}`)
        )
      );
  }
}
