import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Transform} from 'class-transformer';
import {Company} from '../../../core/domain/company/Company';
import {Reference} from '../../../../domain/Reference';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {PersonType} from '../../../core/domain/person/person/PersonType';

export class QualificationSummaryQuestion extends ClassedEntity {
  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'qualificationSummaryQuestion';

  constructor() {
    super('com.beclm.blacklist.pep.api.qualificationQuestion.QualificationSummaryQuestion');
  }

  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  personType: PersonType;
  question: string;
}
