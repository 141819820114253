<div class="grid-x register">
  <div class="cell auto register-left cell-block-y">
    <div class="grid-y registerForm">
      <div
        class="
          registerForm-head
          grid-x grid-padding-x
          align-middle align-justify
        "
        *ngIf="!alreadyRegistered"
      >
        <div class="cell shrink">
          <a-button-cancel
            [labelKey]="'common.cancel' | translate"
            (btnClick)="redirectToLogin()"
          >
          </a-button-cancel>
        </div>
        <div class="cell shrink text-right">
          <ng-container *ngIf="currentStep === registrationStep.STEP_ONE">
            {{'register.already.has.account' | translate}}
            <a-link-default (click)="redirectToLogin()" size="large">
              {{'register.already.has.account.login' | translate}}
            </a-link-default>
          </ng-container>
        </div>
      </div>
      <div class="registerForm-content"></div>
      <div class="cell small-24 large-12 xlarge-12">
        <div class="grid-x">
          <div class="cell auto"></div>
          <div class="cell small-24 medium-16 large-14 xlarge-12 xxlarge-10">
            <spacer [size]="34"></spacer>

            <div class="cell small-24" *ngIf="alreadyRegistered">
              <spacer [size]="8"></spacer>
              <m-callout [calloutTxtAlign]="'LEFT'">
                <ng-container txt>
                  {{'register.already.registered' | translate}}
                </ng-container>
              </m-callout>
              <spacer [size]="16"></spacer>
            </div>

            <div class="cell small-24" *ngIf="!alreadyRegistered">
              <spacer [size]="8"></spacer>
              <m-help-message>
                {{'register.already.company.exist' | translate}}
              </m-help-message>
              <spacer [size]="16"></spacer>
            </div>

            <m-multi-step-form
              *ngIf="!alreadyRegistered"
              [activeFormStepIndex]="currentIndexStep"
              [stepsConfig]="stepsConfig"
              [(data)]="stepData"
              [isSavable]="false"
              [editable]="false"
              [canAccessNextStep]="stepData.canAccessNextStep"
              [loadLastStep]="false"
              [forceShowValidationOnLastStep]="true"
            >
              <ng-template #header></ng-template>
            </m-multi-step-form>
          </div>
          <div class="cell auto"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="cell small-24 large-12 xlarge-10 register-right show-for-large">
    <div class="grid-y registerIntro grid-frame">
      <div class="cell shrink text-right registerIntro-logo">
        <img src="assets/images/svg/logo_beclm_white.svg" />
      </div>
      <div class="cell auto registerIntro-container">
        <div class="registerIntro-title">
          <b>{{'register.presentation.header.title' | translate}}</b><br />
          <span
            class="registerIntro-title-sub"
            [innerHTML]="'register.presentation.header.subtitle' | translate"
          ></span>
          <span class="registerIntro-title-separator"></span>
        </div>
        <div
          class="grid-x grid-padding-x align-middle registerIntro-testimonial"
        >
          <div class="cell auto text-right">
            <span class="registerIntro-testimonial-title">
              <img src="assets/images/svg/guillement-start.svg" />
              {{'register.presentation.customer.001.intro' | translate}}
              <img src="assets/images/svg/guillement-end.svg" />
            </span>
            <strong
              >{{'register.presentation.customer.001.name' | translate}}</strong
            >
          </div>
          <div class="cell shrink">
            <img src="assets/images/png/avatar-1.png" width="84" />
          </div>
        </div>
        <div
          class="grid-x grid-padding-x align-middle registerIntro-testimonial"
        >
          <div class="cell auto text-right">
            <span class="registerIntro-testimonial-title">
              <img src="assets/images/svg/guillement-start.svg" />
              {{'register.presentation.customer.002.intro' | translate}}
              <img src="assets/images/svg/guillement-end.svg" />
            </span>
            <strong>{{'register.presentation.customer.002.name' | translate}}</strong>
          </div>
          <div class="cell shrink">
            <img src="assets/images/png/avatar-2.png" width="84" />
          </div>
        </div>
      </div>
      <div class="cell shrink grid-y grid-padding-y registerIntro-customer">
        <div class="cell shrink text-center">
          <div class="registerIntro-customer-title">
            <b>{{'register.presentation.footer.title' | translate}}<br />
              <span class="registerIntro-customer-title-sub">{{'register.presentation.footer.subtitle' | translate}}</span>
            </b>
          </div>
        </div>
        <div class="cell shrink">
          <ul class="registerIntro-customer-list">
            <li>
              <a href="http://www.agea.fr/" title="Agea" target="_blank"
                ><img src="assets/images/png/logo_agea.png"
              /></a>
            </li>
            <li>
              <a
                href="https://francefintech.org/"
                title="Fin Tech"
                target="_blank"
                ><img src="assets/images/png/logo_fintech.png"
              /></a>
            </li>
            <li>
              <a
                href="https://www2.deloitte.com/fr/fr/pages/home.html"
                title="Deloitte"
                target="_blank"
                ><img src="assets/images/png/logo_deloitte.png"
              /></a>
            </li>
            <li>
              <a
                href="https://kpmg.com/fr/fr/home.html"
                title="KPMG"
                target="_blank"
                ><img src="assets/images/png/logo_kpmg.png"
              /></a>
            </li>
            <li>
              <a href="https://www.xerfi.com/" title="Xerfi" target="_blank"
                ><img src="assets/images/png/logo_xerfi.png"
              /></a>
            </li>
            <li>
              <a
                href="https://lafrenchtech.com/fr/"
                title="French Tech"
                target="_blank"
                ><img src="assets/images/png/logo_french_tech.png"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
