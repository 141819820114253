import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CoreCompanyConfig} from '../../domain/person/company-config/CoreCompanyConfig';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {ApiType} from '../../../core-shared/domain/apiUser/ApiType';

@Injectable()
export class CoreCompanyConfigService {
  private readonly baseUrl = '/api/companyConfigs';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findByCurrentCompany(): Observable<CoreCompanyConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CoreCompanyConfig)
            .get<CoreCompanyConfig>(url)
        )
      );
  }

  public findByCompanyId(companyId: string): Observable<CoreCompanyConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CoreCompanyConfig)
            .get<CoreCompanyConfig>(`${url}/${companyId}`)
        )
      );
  }

  public update(
    coreCompanyConfig: CoreCompanyConfig
  ): Observable<CoreCompanyConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CoreCompanyConfig)
            .put<CoreCompanyConfig>(`${url}`, coreCompanyConfig)
        )
      );
  }

  public updateVigilanceRiskRates(
    coreCompanyConfig: CoreCompanyConfig
  ): Observable<CoreCompanyConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CoreCompanyConfig)
            .put<CoreCompanyConfig>(
              `${url}/vigilance-risk-rates`,
              coreCompanyConfig
            )
        )
      );
  }

  public updateToggleFeatures(
    coreCompanyConfig: CoreCompanyConfig
  ): Observable<CoreCompanyConfig> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(CoreCompanyConfig)
          .put<CoreCompanyConfig>(
            `${url}/toggle-features`,
            coreCompanyConfig.toggleFeatures,
            {
              params: {
                companyRef: coreCompanyConfig.companyRef
              }
            }
          )
      )
    );
  }

  public checkTrialModeLimit(): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(BooleanResponse)
            .get<BooleanResponse>(`${url}/isLimitReached`)
        )
      );
  }

  public isApiUserConfiguredOnCurrentCompany(
    apiType: ApiType
  ): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(BooleanResponse)
            .get<BooleanResponse>(`${url}/isApiUserConfigured/${apiType}`)
        )
      );
  }
}
