import {Component, OnInit} from '@angular/core';
import {
  AButtonStatus,
  ModuleService,
  NavigationService,
  RuleService,
  StepSingleFormComponent,
  TipOffMessageType,
  User,
  UserService
} from 'base';
import {StepData} from '../../register.component';
import {RegistrationService} from '../../../../services/registration.service';
import {PaymentInformation} from '../../../../domain/registration/PaymentInformation';
import {PaymentRegistration} from '../../../../domain/registration/PaymentRegistration';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, timer} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'm-registration-paiement-step-four',
  templateUrl: './m-registration-paiement-step-four.component.html',
  styleUrls: ['./m-registration-paiement-step-four.component.scss']
})
export class MRegistrationPaiementStepFourComponent
  extends StepSingleFormComponent<StepData>
  implements OnInit
{
  paymentRegistration: PaymentRegistration = new PaymentRegistration();
  coupon: string;
  paymentInformation: PaymentInformation;
  loadPaymentInformationSpinner = false;
  couponSearchStatus: AButtonStatus = AButtonStatus.VALID;
  successPayment = false;
  cancelPayment = false;
  tipOffMessageType = TipOffMessageType;
  _currentUser = new BehaviorSubject<User>(undefined);
  couponValid: boolean = undefined;
  hasRulesValidated = false;

  constructor(
    private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private moduleService: ModuleService,
    private router: Router,
    private ruleService: RuleService,
    private navigationService: NavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadParamsFromCurrentRoute();
  }

  private loadParamsFromCurrentRoute(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['successPayment'] != null) {
        this.successPayment = true;
        this.hideParentFormButtons.emit(true);
        this.loadCurrentUserToCheckFinishPayment();
      } else if (params['cancelPayment'] != null) {
        this.cancelPayment = true;
        this.loadPaymentInformation();
      } else {
        this.loadPaymentInformation();
      }
    });
  }

  private loadPaymentInformation(): void {
    this.loadPaymentInformationSpinner = true;
    this.registrationService
      .getPaymentInformation(this.paymentRegistration.coupon)
      .pipe(
        finalize(() => {
          this.loadPaymentInformationSpinner = false;
        })
      )
      .subscribe((paymentInformation) => {
        this.paymentInformation = paymentInformation;
      });
  }

  couponIsValid(): void {
    this.couponValid = undefined;
    this.couponSearchStatus = AButtonStatus.LOADING;
    this.paymentRegistration.coupon = this.coupon;
    this.registrationService
      .couponIsValid(this.paymentRegistration.coupon)
      .subscribe(
        (booleanResponse) => {
          this.couponValid = booleanResponse.value;
          this.loadPaymentInformation();
          this.couponSearchStatus = AButtonStatus.VALID;
          this.coupon = undefined;
        },
        () => {
          this.couponSearchStatus = AButtonStatus.ERROR;
        }
      );
  }

  deleteCoupon(): void {
    this.paymentRegistration.coupon = null;
    this.loadPaymentInformation();
  }

  onPreviousHandler = (
    success: () => void,
    error: (showErrorButton) => void
  ): void => {
    this.data.canAccessNextStep = false;
    success();
  };

  onValidateHandler = (success: () => void, error: () => void): void => {
    this.registrationService.payment(this.paymentRegistration).subscribe(
      (stringResponse) => {
        window.location.href = stringResponse.value;
        success();
      },
      () => {
        error();
      }
    );
  };

  private loadCurrentUserToCheckFinishPayment() {
    const subscription = timer(500, 1000).subscribe((counter) => {
      // To avoid too many request we disabled timer if user not ok after 100 requests and redirect to login page.
      if (counter >= 100) {
        subscription.unsubscribe();
        this.redirectToLoginPage();
      }
      this.userService.current().subscribe((user) => {
        if (user.userRegistrationProgress.isFinish) {
          this._currentUser.next(user);
          subscription.unsubscribe();
          this.redirectToComplianceBinderUi();
        }
      });
    });
  }

  private redirectToLoginPage(): void {
    this.router.navigate(['login']);
  }

  private redirectToComplianceBinderUi(): void {
    this.moduleService
      .complianceBinderModuleUiUrl('/')
      .subscribe((uiComplianceBinderUrl) => {
        window.location.href = uiComplianceBinderUrl;
      });
  }

  public isFormInvalid(): boolean {
    return (
      this.coupon === undefined ||
      this.coupon === null ||
      this.coupon?.length === 0
    );
  }

  public downloadRulesPdf(): void {
    this.ruleService.getDownloadRulesUrl().subscribe((ruleDownloadUrl) => {
      this.navigationService.navigateTo(ruleDownloadUrl);
    });
  }
}
