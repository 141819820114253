<button
  type="button"
  class="button buttonDelete hollow _alert"
  [ngClass]="fromSize(size)"
  [class._withLabel]="labelKey"
  [class._disabled]="disabled"
  [class.hollow]="hollow"
  [class._white]="white"
  [disabled]="disabled"
  (click)="btnClick.emit($event)"
  [routerLink]="btnRouterLink"
  [queryParams]="btnRouterLinkQueryParams"
>
  <i class="fa fa-trash"></i>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>
