import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RegisterComponent} from './register.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.validation.module';
import {BrowserModule} from '@angular/platform-browser';
import {BaseComponentsModule, BaseCoreServicesModule, BaseDirectivesModule} from 'base';
import {environment} from '../../../environments/environment';
import {DirectivesValidationModule} from '../../directives/validation/directives.validation.module';
import {
  MRegistrationUserCreationStepTwoComponent
} from './components/m-registration-user-creation-step-two/m-registration-user-creation-step-two.component';
import {
  MRegistrationCompanyCreationStepThreeComponent
} from './components/m-registration-company-creation-step-three/m-registration-company-creation-step-three.component';
import {
  MRegistrationPaiementStepFourComponent
} from './components/m-registration-paiement-step-four/m-registration-paiement-step-four.component';
import {
  MRegistrationProfileSelectStepOneComponent
} from './components/m-registration-profile-select-step-one/m-registration-profile-select-step-one.component';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
  declarations: [
    RegisterComponent,
    MRegistrationProfileSelectStepOneComponent,
    MRegistrationUserCreationStepTwoComponent,
    MRegistrationCompanyCreationStepThreeComponent,
    MRegistrationPaiementStepFourComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    RouterModule,
    BaseComponentsModule.forRoot(environment),
    BaseCoreServicesModule,
    BaseDirectivesModule,
    DirectivesValidationModule,
    DirectivesModule
  ]
})
export class RegisterModule {}
