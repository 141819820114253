import {Type} from 'class-transformer';
import {PersonCompanyOffice} from './PersonCompanyOffice';
import {PersonCompanyInfo} from './PersonCompanyInfo';

export class CompanyExternalData {
  @Type(() => PersonCompanyInfo)
  personCompanyInfo: PersonCompanyInfo = new PersonCompanyInfo();

  @Type(() => PersonCompanyOffice)
  personCompanyOffice: PersonCompanyOffice = new PersonCompanyOffice();

  publicationStatus: boolean
}
