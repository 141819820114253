import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';
import {CustomValidators} from '../../utils/CustomValidators';

@Directive({
  selector: '[userEmailIsValid]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UserEmailIsValidValidatorDirective,
      multi: true
    }
  ]
})
@Injectable()
export class UserEmailIsValidValidatorDirective implements Validator {
  @Input('userEmailIsValid') email = '';

  validate(control: AbstractControl) {
    return !CustomValidators.emailIsValid(control.value)
      ? CustomValidators.buildError('emailIsNotValid', true)
      : null;
  }
}
