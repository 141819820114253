import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Token} from '../../../../../domain/Token';

export class UserCredential extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'userCredential';

  login: string;
  password: string;
  passwordRenewalToken: Token;
  permanentLoginToken: Token;

  constructor() {
    super('com.beclm.core.api.user.UserCredential');
  }
}
