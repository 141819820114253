import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'a-button-loading',
  templateUrl: './a-button-loading.component.html',
  styleUrls: ['./a-button-loading.component.scss']
})
export class AButtonLoadingComponent implements OnInit, OnChanges {
  @Input() status: AButtonStatus;
  @Input() type = '';
  @Input() disabled = false;
  @Input() baseClasses = '';
  @Input() validClasses = '';
  @Input() loadingClasses = '';
  @Input() errorClasses = 'alert';
  @Input() id = '';
  @Input() faIcon: string;
  @Input() faIconOnLoading: string = 'spinner-btn';
  @Output() btnClick = new EventEmitter<MouseEvent>();
  validStatus = AButtonStatus.VALID;
  loadingStatus = AButtonStatus.LOADING;
  errorStatus = AButtonStatus.ERROR;
  classMap: {};

  ngOnInit(): void {
    if (this.status === undefined) {
      this.status = AButtonStatus.VALID;
    }
    this.buildClassMap();
  }

  buildClassMap() {
    const regExp = RegExp(/\s+/);
    this.classMap = {};
    this.baseClasses
      .split(regExp)
      .forEach((value) => (this.classMap[value] = true));
    if (this.status === AButtonStatus.VALID) {
      this.validClasses
        .split(regExp)
        .forEach((value) => (this.classMap[value] = true));
    } else if (this.status === AButtonStatus.LOADING) {
      this.classMap['has-icon-left'] = true;
      this.loadingClasses
        .split(regExp)
        .forEach((value) => (this.classMap[value] = true));
    } else {
      this.errorClasses
        .split(regExp)
        .forEach((value) => (this.classMap[value] = true));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.buildClassMap();
    }
  }
}

export enum AButtonStatus {
  VALID,
  LOADING,
  ERROR
}
