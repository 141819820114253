import {Injectable} from '@angular/core';
import {BooleanResponse, HttpHandlerService, ModuleService, StringResponse} from 'base';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UserRegistration} from '../domain/registration/UserRegistration';
import {CompanyRegistration} from '../domain/registration/CompanyRegistration';
import {PaymentInformation} from '../domain/registration/PaymentInformation';
import {PaymentRegistration} from '../domain/registration/PaymentRegistration';

@Injectable()
export class RegistrationService {
  private readonly baseUrl = '/api/registrations';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public createUser(userRegistration: UserRegistration): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post<void>(url + '/users', userRegistration)
        )
      );
  }

  public createCompany(
    companyRegistration: CompanyRegistration
  ): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post<void>(url + '/companies', companyRegistration)
        )
      );
  }

  public getPaymentInformation(coupon: string): Observable<PaymentInformation> {
    let params = {};
    if (coupon != null) {
      params = {
        coupon: coupon
      };
    }
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaymentInformation)
          .get<PaymentInformation>(url + '/payments', {
            params: params
          })
      )
    );
  }

  public payment(
    paymentRegistration: PaymentRegistration
  ): Observable<StringResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post<StringResponse>(url + '/payments', paymentRegistration)
        )
      );
  }

  public couponIsValid(coupon: string): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(BooleanResponse)
          .get<BooleanResponse>(url + '/payments/coupon', {
            params: {
              coupon: coupon
            }
          })
      )
    );
  }
}
