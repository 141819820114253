import {RealEstate} from './RealEstate';
import {FinancialWealth} from './FinancialWealth';
import {LifeInsurance} from './LifeInsurance';
import {MonthlyIncome} from './MonthlyIncome';
import {MonthlyExpenses} from './MonthlyExpenses';
import {MonthlyTaxes} from './MonthlyTaxes';
import {Type} from 'class-transformer';
import {Saving} from './Saving';
import {SavingCapacity} from './SavingCapacity';
import {FinancialAssets} from './FinancialAssets';
import {FinancialFlows} from './FinancialFlows';

export class FinancialSituation {
  @Type(() => RealEstate)
  realEstate: RealEstate = new RealEstate();
  @Type(() => FinancialWealth)
  financialWealth: FinancialWealth = new FinancialWealth();
  @Type(() => LifeInsurance)
  lifeInsurances: LifeInsurance[] = new Array<LifeInsurance>();
  @Type(() => MonthlyIncome)
  monthlyIncomes: MonthlyIncome = new MonthlyIncome();
  @Type(() => MonthlyExpenses)
  monthlyExpenses: MonthlyExpenses = new MonthlyExpenses();
  @Type(() => MonthlyTaxes)
  monthlyTaxes: MonthlyTaxes = new MonthlyTaxes();
  @Type(() => FinancialAssets)
  financialAssets: FinancialAssets = new FinancialAssets();
  @Type(() => FinancialFlows)
  financialFlows: FinancialFlows = new FinancialFlows();

  @Type(() => SavingCapacity)
  monthlySavingCapacity: SavingCapacity = new SavingCapacity();
  @Type(() => SavingCapacity)
  annualSavingCapacity: SavingCapacity = new SavingCapacity();

  @Type(() => Saving)
  totalLifeInsurance: Saving = new Saving();
  @Type(() => Saving)
  totalFinancialWealthSavings: Saving = new Saving();
  realEstateEstimation: number;

  public static fromFinancialSituation(
    situation: FinancialSituation
  ): FinancialSituation {
    const financialSituation = new FinancialSituation();
    financialSituation.realEstate = situation.realEstate;
    financialSituation.financialWealth = situation.financialWealth;
    financialSituation.lifeInsurances = situation.lifeInsurances;
    financialSituation.monthlyIncomes = situation.monthlyIncomes;
    financialSituation.monthlyExpenses = situation.monthlyExpenses;
    financialSituation.monthlyTaxes = situation.monthlyTaxes;
    financialSituation.financialAssets = situation.financialAssets;
    financialSituation.financialFlows = situation.financialFlows;
    financialSituation.monthlySavingCapacity = situation.monthlySavingCapacity;
    financialSituation.annualSavingCapacity = situation.annualSavingCapacity;
    financialSituation.totalLifeInsurance = situation.totalLifeInsurance;
    financialSituation.totalFinancialWealthSavings =
      situation.totalFinancialWealthSavings;
    financialSituation.realEstateEstimation = situation.realEstateEstimation;
    return financialSituation;
  }
}
