<select
  [ngModel]="operationTypeRefSelected"
  (ngModelChange)="valueSelected($event)"
  [compareWith]="compareOperationTypeRef"
  [disabled]="disabled"
>
  <option></option>
  <option
    [ngValue]="operationTypeRef"
    *ngFor="let operationTypeRef of operationTypeRefs"
  >
    {{operationTypeRef.entity.name}}
  </option>
</select>
