<div class="readOnly"
     [copy-clipboard]="valueKey"
     [clipboardMode]="clipboardMode"
     (copied)="notify()"
     [class._lightMode]="lightMode"
     [class._horizontal]="horizontalLayout"
     [class._smallWidthLabel]="smallWidthLabel"
     [class._clipboardMode]="clipboardMode"
     [class._separator]="separator">
  <div class="readOnly-infos" [class._noWrap]="noWrap">
    <label>{{labelKey}}</label>
    <p *ngIf="valueKey" [class.breakWord]="breakWord" [innerText]="valueKey"></p>
  </div>
  <span
    *ngIf="clipboardMode"
    class="copyValue">
    <i class="fa fa-copy"></i></span>
</div>
