import {Transform} from 'class-transformer';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../domain/Reference';
import {Company} from '../../company/Company';

export class SecurityPolicy extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'securityPolicy';

  @Transform(ReferenceTransformer.transformTo(Company))
  public companyRef: Reference<Company>;
  public pwdPattern: string;
  public pwdPatternDescription: string;
  public delayRenew: number;
  public allowSamePreviousPassword: boolean;

  constructor() {
    super('com.beclm.core.api.securityPolicy.SecurityPolicy');
  }
}
