import {TransformationType} from 'class-transformer/TransformOperationExecutor';
import {plainToClass} from 'class-transformer';
import {AbstractClassMapper} from './class-mapper/AbstractClassMapper';
import {ClassMapper} from './class-mapper/ClassMapper';
import {Transformer} from './Transformer';
import {Entity} from '../Entity';
import {Reference} from '../Reference';
import {Newable} from '../Newable';
import {Uri} from '../Uri';
import {UtilsService} from '../../services/utils.service';
import {Company} from '../../modules/core/domain/company/Company';
import {ClassedEntity} from '../ClassedEntity';
import {LegalEntity} from '../../modules/core/domain/person/person/legal-entity/LegalEntity';

export class ReferenceTransformer<T extends Entity> extends Transformer<
  Reference<T>
> {
  public static transformTo<T extends Entity>(
    classReference: Newable<T>,
    classMapper: AbstractClassMapper<T> = new ClassMapper(classReference)
  ): (value: any, obj: any, transformationType: TransformationType) => any {
    if (classReference === undefined) {
      console.warn(
        'Undefined type during decorator phase, probably circular dep problem'
      );
    }
    return new ReferenceTransformer<T>(classMapper).transform();
  }

  public static transformWith<T extends Entity>(
    classMapper: Newable<AbstractClassMapper<T>>
  ): (value: any, obj: any, transformationType: TransformationType) => any {
    return new ReferenceTransformer<T>(new classMapper()).transform();
  }

  constructor(private classMapper: AbstractClassMapper<T>) {
    super();
  }

  protected toClass(
    value: T | Array<T> | string | Array<string>
  ): Reference<T> | Array<Reference<T>> {
    if (!UtilsService.exists(value)) {
      return new Reference<T>();
    }

    if (UtilsService.isArray(value)) {
      return (<any>value).map((item: T) => this.toClass(item));
    }
    if (UtilsService.isObject(value)) {
      let clazz = <any>this.classMapper.getClass(<T>value);
      // TypeWrapper is use to wrap a type which cannot be resolve during decorator phase because a circular dependency problem
      if (Object.getPrototypeOf(clazz) === TypeWrapper) {
        clazz = new clazz().type();
      }

      return new Reference<T>(plainToClass(clazz, value));
    }

    return new Reference<T>(Uri.deserialize(<string>value));
  }

  protected toPlain(value: Reference<T> | Array<Reference<T>>): any {
    if (UtilsService.isArray(value)) {
      return (<any>value).map((item: Reference<T>) => this.toPlain(item));
    }

    if (Reference.hasUri(value) && value.id !== undefined) {
      return Uri.toString(value.uri);
    }

    return null;
  }
}

export class TypeWrapper extends ClassedEntity {
  type(): any {
    return Object;
  }
}

export class CompanyTypeWrapper extends TypeWrapper {
  public type(): any {
    return Company;
  }
}

export class LegalEntityWrapper extends TypeWrapper {
  public type(): any {
    return LegalEntity;
  }
}
