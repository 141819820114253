import {Component, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';

@Component({
  selector: 'a-checkbox',
  templateUrl: './a-checkbox.component.html',
  styleUrls: ['./a-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ACheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ACheckboxComponent),
      multi: true
    }
  ]
})
export class ACheckboxComponent implements ControlValueAccessor, Validator {
  private onNgChange: (value: boolean) => void;
  private onNgTouched: () => void;

  public disabled: boolean;
  public checked: boolean;

  @Input()
  public label: string;

  @Input()
  public subLabel: string;

  @Input()
  public tooltipLabel: string;

  @Input()
  public size = 'medium';

  @Input()
  public margin;

  @Input()
  public titleLabel: boolean;

  @Input()
  public required = false;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: boolean): void {
    this.checked = obj;
  }

  public onCheckedChange(checked: boolean): void {
    this.onNgChange(checked);
    this.checked = checked;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required) {
      return Validators.requiredTrue(control);
    }

    return null;
  }
}
