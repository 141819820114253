import {Exclude, Expose} from 'class-transformer';
import {UtilsService} from '../services/utils.service';

export class Uri {
  private static URI_REGEXP = new RegExp('ref://([^/]+)/([^/]+)/(.*)');

  @Exclude() private readonly _id: string;
  @Exclude() private readonly _collection: string;
  @Exclude() private readonly _module: string;

  constructor(module: string, collection: string, id: string) {
    this._id = id;
    this._collection = collection;
    this._module = module;
  }

  @Expose()
  public get id(): string {
    return this._id;
  }

  @Expose()
  public get collection(): string {
    return this._collection;
  }

  @Expose()
  public get module(): string {
    return this._module;
  }

  public static serialize(
    module: string,
    collection: string,
    id: string
  ): string {
    return `ref://${module?.trim()}/${collection?.trim()}/${id?.trim()}`;
  }

  public static deserialize(uri: string): Uri {
    if (!UtilsService.isString(uri) || uri.trim().length === 0) {
      return null;
    }

    const match = uri.match(this.URI_REGEXP);

    if (!UtilsService.exists(match) || match.length < 4) {
      throw new Error(`unknown uri format: ${uri}`);
    }

    return new Uri(match[1], match[2], match[3]);
  }

  public static toString(uri: Uri): string {
    return Uri.serialize(uri.module, uri.collection, uri.id);
  }

  public toString(): string {
    return Uri.toString(this);
  }
}
