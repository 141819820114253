import {Company} from './Company';
import {Reference} from '../../../../domain/Reference';

export class CompanyDenomination {
  public id: string;
  public name: string;
  public authorizedModules: Array<string>;

  public static toCompanyRef(company: CompanyDenomination): Reference<Company> {
    const companyLight = new Company();
    companyLight.id = company.id;
    companyLight.name = company.name;
    companyLight.authorizedModules = company.authorizedModules;
    return new Reference<Company>(companyLight);
  }
}
