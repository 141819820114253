import {MatchingConfig} from './MatchingConfig';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {DataSource} from '../../data-source/DataSource';
import {Reference} from '../../../../../domain/Reference';

export class ControlConfig {
  @Transform(ReferenceTransformer.transformTo(DataSource))
  public controlSources: Reference<DataSource>[] = [];
  public controlSourceTags: string[] = [];
  @Type(() => MatchingConfig)
  public matchingConfig: MatchingConfig = new MatchingConfig();
}
