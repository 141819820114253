import {NaturalPersonCompany} from './NaturalPersonCompany';
import {Type} from 'class-transformer';

export class PersonCompanyInfo {
  organizationName: string;
  creationDate: string;
  active: boolean;
  mainActivity: string;
  companyCategory: string;
  legalCategory: string;
  @Type(() => NaturalPersonCompany)
  naturalPerson: NaturalPersonCompany;
}
