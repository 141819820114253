import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {
  PersonControlResultSearchFilter
} from '../../domain/adapter/pcr-archiver/person-control-result/PersonControlResultSearchFilter';
import {SearchParams} from '../../../../domain/SearchParams';
import {
  PersonControlResultSearchDownload
} from '../../domain/adapter/pcr-archiver/person-control-result/PersonControlResultSearchDownload';
import {
  PaginatedPersonControlResultSearch
} from '../../domain/adapter/pcr-archiver/person-control-result/PaginatedPersonControlResultSearch';
import {NavigationService} from "../../../../services/navigation.service";

@Injectable()
export class CorePersonControlResultArchiverSecurityService {
  private readonly baseUrl = '/api/person-control-result-archiver';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService,
    private navigationService: NavigationService,
  ) {}

  public searchPcr(
    personControlResultFilter: PersonControlResultSearchFilter
  ): Observable<PaginatedPersonControlResultSearch> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedPersonControlResultSearch)
          .get<PaginatedPersonControlResultSearch>(url + '/search', {
            params: SearchParams.toHttpParams(personControlResultFilter)
          })
      )
    );
  }

  public downloadPcr(
    personControlResultSearchDownload: PersonControlResultSearchDownload
  ) {
    this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) => {
          this.navigationService.navigateTo(
            url +
              '/download?pcrId=' +
              personControlResultSearchDownload.pcrId +
              '&sourceId=' +
              personControlResultSearchDownload.sourceId +
              '&externalRefId=' +
              personControlResultSearchDownload.externalRefId +
              '&personControlResultType=' +
              personControlResultSearchDownload.personControlResultType,
              false
          );
          return url;
        })
      )
      .subscribe();
  }
}
