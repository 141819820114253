export class UtilsService {
  public static isDefined(value: any): boolean {
    return typeof value !== 'undefined';
  }

  public static exists(value: any): boolean {
    return UtilsService.isNotNull(value) && UtilsService.isDefined(value);
  }

  public static isNull(value: any): value is null {
    return value === null && typeof value === 'object';
  }

  public static isNotNull(value: any): boolean {
    return !UtilsService.isNull(value);
  }

  public static isString(value: any): value is string {
    return typeof value === 'string';
  }

  public static isDate(value: any): value is Date {
    return toString.call(value) === '[object Date]';
  }

  public static isNumber(value: any): value is number {
    return typeof value === 'number' && Number(value) === value;
  }

  public static isInt(value: any): boolean {
    return this.isNumber(value) && value % 1 === 0;
  }

  public static isFunction(value: any): value is Function {
    return typeof value === 'function';
  }

  public static isArray(value: any): value is Array<any> {
    return Array.isArray(value);
  }

  public static isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  public static returnStringIfUndefined(value: any) {
    if (value == undefined) {
      return '-';
    }
    return value;
  }
}
