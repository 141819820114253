import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Directive} from '@angular/core';
import {PasswordConfirmationValidator} from '../../pages/login/password-confirmation.validator';

@Directive({
  selector: '[password-confirmation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordConfirmationValidatorDirective,
      multi: true
    }
  ]
})
export class PasswordConfirmationValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.get('password') && control.get('password-confirmation')) {
      return PasswordConfirmationValidator.validConfirmationStrength(
        control.get('password'),
        control.get('password-confirmation')
      )(control);
    }
    return {};
  }
}
