import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ControlInput} from '../../domain/person/person/control/ControlInput';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonDataCategoryType} from '../../domain/person/data-category/PersonDataCategoryType';
import {ControlType} from '../../domain/person/person/control/ControlType';
import {PersonDataSource} from '../../domain/person/data-source/PersonDataSource';
import {Reference} from '../../../../domain/Reference';
import {ScoreType} from '../../domain/person/person/control/ScoreType';
import {ScoreEvaluationFilter} from '../../domain/person/control-scores-evaluation-config/ScoreEvaluationFilter';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable({
  providedIn: 'root'
})
export class ControlTypeService {
  private readonly baseUrl: string = '/api/control-types';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getAllControls(): Observable<ControlInput[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlInput)
            .get<ControlInput[]>(
              `${url}/control-inputs`
            )
        )
      );
  }

  public getControlInputsForCategoryType(personDataCategoryType: PersonDataCategoryType): Observable<ControlInput[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlInput)
            .get<ControlInput[]>(
              `${url}/control-inputs/personDataCategoryType/${personDataCategoryType}`
            )
        )
      );
  }


  public getControlInputsForScoreTypeAndCategoryType(scoreEvaluationFilter: ScoreEvaluationFilter): Observable<ControlInput[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlInput)
            .get<ControlInput[]>(
              `${url}/control-inputs/scoreType`,
              {
                params: SearchParams.toHttpParams(scoreEvaluationFilter)
              }
            )
        )
      );
  }

  public getControlTypesForCategoryType(personDataCategoryType: PersonDataCategoryType): Observable<ControlType[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .get<ControlType[]>(
              `${url}/personDataCategoryType/${personDataCategoryType}`
            )
        )
      );
  }

  public getActivatedControlTypesForCategoryType(personDataCategoryType: PersonDataCategoryType,
                                                 personDataSourceRef: Reference<PersonDataSource>): Observable<ControlType[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .get<ControlType[]>(
              `${url}/activated`,
              {
                params: {
                  personDataCategoryType: personDataCategoryType,
                  personDataSourceRef: personDataSourceRef
                }
              }
            )
        )
      );
  }

}
