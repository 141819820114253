import {Type} from 'class-transformer';
import {Identity} from './main-information/Identity';
import {ProfessionalInformation} from './main-information/ProfessionalInformation';
import {FinancialSituation} from './financial/FinancialSituation';
import {PostalAddress} from './main-information/PostalAddress';
import {Person} from '../Person';
import {ProofOfId} from './main-information/ProofOfId';
import {InsurerContract} from './main-information/InsurerContract';
import {Reference} from '../../../../../../domain/Reference';
import {SelectableRef} from '../../../../../../domain/SelectableRef';
import {PoliticalExposure} from './redflag/PoliticalExposure';
import {PersonMetaData} from '../../../../../blacklist-pep/domain/person/PersonMetaData';
import {PersonAdditionalInformation} from '../../../../../blacklist-pep/domain/person/PersonAdditionalInformation';
import {AccidentEvent} from '../../../../../blacklist-pep/domain/person/AccidentEvent';

export class NaturalPerson extends Person {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'naturalPerson';

  @Type(() => Identity)
  identity: Identity = new Identity();
  @Type(() => PostalAddress)
  postalAddress: PostalAddress = new PostalAddress();
  @Type(() => ProfessionalInformation)
  professionalInformation: ProfessionalInformation = new ProfessionalInformation();
  @Type(() => FinancialSituation)
  financialSituation: FinancialSituation = new FinancialSituation();
  @Type(() => ProofOfId)
  proofOfId: ProofOfId = new ProofOfId();
  @Type(() => InsurerContract)
  contracts: InsurerContract[] = [];
  @Type(() => PoliticalExposure)
  politicalExposure: PoliticalExposure = new PoliticalExposure();
  @Type(() => PersonMetaData)
  personMetaData: PersonMetaData;
  @Type(() => PersonAdditionalInformation)
  personAdditionalInformation: PersonAdditionalInformation;
  @Type(() => AccidentEvent)
  accidentEvents: AccidentEvent[] = [];

  constructor() {
    super('com.beclm.core.api.person.naturalPerson.NaturalPerson');
  }

  public static toSelectableRef(
    reference: Reference<NaturalPerson>
  ): SelectableRef<NaturalPerson> {
    const selectableRef = new SelectableRef<NaturalPerson>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label =
        reference.entity.identity.email +
        ' - ' +
        reference.entity.identity.lastName +
        ' ' +
        reference.entity.identity.firstName;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<NaturalPerson> {
    const selectableRef = new SelectableRef<NaturalPerson>();
    selectableRef.id = new Reference<NaturalPerson>(this);
    selectableRef.__label =
      this.identity.email +
      ' - ' +
      this.identity.lastName +
      ' ' +
      this.identity.firstName;
    return selectableRef;
  }
}
