import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AppLoadService {
  constructor(private translateService: TranslateService) {}

  initializeApp() {
    this.translateService.setDefaultLang('fr');
    this.translateService.use('fr');
  }
}
