import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {StringResponse} from '../../../../domain/StringResponse';
import {HubSpotChatConfig} from '../../domain/config/HubSpotChatConfig';

@Injectable()
export class CoreConfigService {
  protected readonly baseUrl = '/api/config';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getRegistrationEnabled(): Observable<boolean> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Boolean)
            .get<boolean>(`${url}/registrationEnabled`)
        )
      );
  }

  public getHubspotChatConfig(): Observable<HubSpotChatConfig> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(HubSpotChatConfig)
            .get<HubSpotChatConfig>(`${url}/hubspot/chat`)
        )
      );
  }

  public getRedirectionModuleUrl(moduleName: string): Observable<StringResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(StringResponse)
            .get<StringResponse>(`${url}/redirect/url/${moduleName}`)
        )
      );
  }
}
