import {Type} from '@angular/core';
import {StepFormComponent} from './step-form-component';
import {FormStepData} from './FormStepData';

export class FormStep {
  constructor(
    public labelKey: string,
    public component: Type<StepFormComponent<FormStepData>>
  ) {}
}
