import {Component, OnInit} from '@angular/core';
import {
  CaptchaAction,
  NotificationService,
  SecurityPolicy,
  SecurityPolicyService,
  SessionService,
  StepSingleFormComponent
} from 'base';
import {RegistrationService} from '../../../../services/registration.service';
import {UserRegistration} from '../../../../domain/registration/UserRegistration';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {StepData} from '../../register.component';
import {Router} from '@angular/router';

@Component({
  selector: 'm-registration-user-creation-step-two',
  templateUrl: './m-registration-user-creation-step-two.component.html',
  styleUrls: ['./m-registration-user-creation-step-two.component.scss']
})
export class MRegistrationUserCreationStepTwoComponent
  extends StepSingleFormComponent<StepData>
  implements OnInit
{
  userRegistration: UserRegistration = new UserRegistration();
  password: string;
  passwordConfirmation: string;
  securityPolicy: SecurityPolicy;

  constructor(
    private registrationService: RegistrationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    private securityPolicyService: SecurityPolicyService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadDefaultSecurityPolicy();
  }

  loadDefaultSecurityPolicy(): void {
    this.securityPolicyService
      .getDefaultSecurityPolicy()
      .subscribe((securityPolicy) => {
        this.securityPolicy = securityPolicy;
      });
  }

  onNextHandler = (
    success: () => void,
    error: (showErrorButton: boolean) => void
  ): void => {
    this.reCaptchaV3Service.execute(CaptchaAction.REGISTRATION).subscribe(
      (captchaToken) => {
        this.userRegistration.registrationProfile =
          this.data.registrationProfile;
        this.userRegistration.captchaToken = captchaToken;
        this.registerUser(success, error);
      },
      () => {
        this.notificationService
          .error('common.google.recaptcha.error')
          .subscribe();
        error(true);
      }
    );
  };

  registerUser(
    success: () => void,
    error: (showErrorButton: boolean) => void
  ): void {
    this.userRegistration.password = this.password;
    this.registrationService.createUser(this.userRegistration).subscribe(
      () => {
        this.loginAfterRegistration(success, error);
      },
      () => {
        error(true);
      }
    );
  }

  loginAfterRegistration(
    success: () => void,
    error: (showErrorButton: boolean) => void
  ): void {
    this.reCaptchaV3Service
      .execute(CaptchaAction.LOGIN)
      .subscribe((captchaToken) => {
        this.sessionService
          .authenticateFromLoginAndPassword(
            this.userRegistration.email,
            this.userRegistration.password,
            captchaToken
          )
          .subscribe(
            () => {
              this.sessionService.reloadSession(() => {
                success();
              });
            },
            () => {
              error(true);
              this.redirectToLoginPage();
            }
          );
      });
  }

  private redirectToLoginPage(): void {
    this.router.navigate(['login']);
  }
}
