import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {LegalEntity} from '../../domain/person/person/legal-entity/LegalEntity';
import {ModuleService} from '../../../../services/module.service';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class SireneService {
  private readonly baseUrl = '/api/sirene';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getSiretsWithSiren(siren: string): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(String)
            .get<string[]>(url + '/siren/' + siren + '/sirets')
        )
      );
  }

  public getCompanyInfoWithSiren(siren: string): Observable<LegalEntity> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .get<LegalEntity>(url + '/siren/' + siren)
        )
      );
  }

  public getCompanyOfficeWithSiret(siret: string): Observable<LegalEntity> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .get<LegalEntity>(url + '/siret/' + siret)
        )
      );
  }

  public legalEntitySirenIsRegistered(
    siren: string,
    personDataSourceName: string
  ): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl('/api/legal-entities').pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/${siren}/registered`, {
          params: SearchParams.toHttpParams({
            personDataSourceName: personDataSourceName
          })
        })
      )
    );
  }

  public companySirenIsRegistered(
    siren: string,
  ): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl('/api/companies').pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/${siren}/registered`)
      )
    );
  }

  public isSirenNafAllowedForRegistration(siren: string): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/registration/siren/${siren}/naf`)
      )
    );
  }
}
