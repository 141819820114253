import {Injectable} from '@angular/core';
import {BooleanResponse, HttpHandlerService, ModuleService} from 'base';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class BlacklistMailService {
  private readonly baseUrl = '/api/blacklist-mail';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public emailIsValid(email: string): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(BooleanResponse)
            .get<BooleanResponse>(url + '/' + email)
        )
      );
  }
}
