import {Injectable} from '@angular/core';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SearchParams} from '../../../../domain/SearchParams';
import {ScoredCodedValueSearchParam} from '../../domain/person/person/ScoredCodedValueSearchParam';
import {PaginatedScoredCodedValue} from '../../domain/person/person/PaginatedScoredCodedValue';
import {ScoredCodedValue} from '../../domain/person/person/ScoredCodedValue';
import {StringResponse} from '../../../../domain/StringResponse';
import {NumberResponse} from '../../../../domain/NumberResponse';
import {ScoreType} from '../../domain/person/person/control/ScoreType';

@Injectable({
  providedIn: 'root'
})
export class ScoredCodedValueService {
  private readonly baseUrl = '/api/scored-coded-value';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getTypeBySourceName(sourceName: String): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(StringResponse)
            .get<StringResponse>(url + '/' + sourceName + '/type')
        ),
        map(response => response.value)
      );
  }

  public getScoreTypeBySourceName(sourceName: String): Observable<ScoreType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.get<ScoreType>(url + '/' + sourceName + '/scoreType')
        ));
  }

  public getDefaultScoreForValue(sourceName: String, value: string): Observable<number> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NumberResponse)
            .get<NumberResponse>(url + '/default/' + sourceName + '/' + value)
        ),
        map(response => response.value)
      );
  }

  public getDefaultScoredCodedValuesBySourceName(sourceName: string): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .get<ScoredCodedValue[]>(url + '/default/all/' + sourceName)
        ));
  }

  public getCurrentCompanyScoredCodedValuesBySourceName(sourceName: string): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .get<ScoredCodedValue[]>(url + '/current/all/' + sourceName)
        ));
  }

  public paginatedGetDefaultValuesBySourceName(
    scoredCodedValueSearchParam: ScoredCodedValueSearchParam
  ): Observable<PaginatedScoredCodedValue> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedScoredCodedValue)
            .get<PaginatedScoredCodedValue>(url + '/paginated/default', {
              params: SearchParams.toHttpParams(scoredCodedValueSearchParam)
            })
        ));
  }

  public paginatedGetCurrentCompanyValuesBySourceName(
    scoredCodedValueSearchParam: ScoredCodedValueSearchParam
  ): Observable<PaginatedScoredCodedValue> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedScoredCodedValue)
            .get<PaginatedScoredCodedValue>(url + '/paginated/current', {
              params: SearchParams.toHttpParams(scoredCodedValueSearchParam)
            })
        ));
  }

  public updateDefaultScoredValues(
    sourceName: string, scoredCodedValues: ScoredCodedValue[]
  ): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .put<ScoredCodedValue[]>(`${url}/default/` + sourceName, scoredCodedValues)
        )
      );
  }

  public updateCurrentCompanyScoredValues(
    sourceName: string, scoredCodedValues: ScoredCodedValue[]
  ): Observable<ScoredCodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ScoredCodedValue)
            .put<ScoredCodedValue[]>(`${url}/current/` + sourceName, scoredCodedValues)
        )
      );
  }
}
