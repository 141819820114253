import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class ExportHistoric extends ClassedEntity {
  public taskId: string;
  public name: string;
  public description: string;
  public creationDate: Date;
  public startDate: Date;
  public endDate: Date;
  public format: string;
  public lines: number;
  public status: string;
  constructor() {
    super();
  }
}
