import {NgModule} from '@angular/core';
import {
  BaseServicesModule,
  HttpHandlerService,
  ModuleService,
  SireneService,
  TranslationService,
  UserCredentialService,
  UserService
} from 'base';
import {environment} from '../../environments/environment';
import {AstreeService} from './astree.service';
import {BlacklistMailService} from './blacklist-mail.service';
import {RegistrationService} from './registration.service';

@NgModule({
  imports: [BaseServicesModule.forRoot(environment)],
  providers: [
    TranslationService,
    HttpHandlerService,
    SireneService,
    UserService,
    UserCredentialService,
    ModuleService,
    AstreeService,
    BlacklistMailService,
    RegistrationService
  ]
})
export class ServicesModule {}
