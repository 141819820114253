import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {StepFormComponent} from '../step-form-component';
import {FormStep} from '../FormStep';
import {FormStepData} from '../FormStepData';
import {AButtonStatus} from '../../../atoms/buttons/a-button-loading/a-button-loading.component';

@Component({
  selector: 'm-form-step-detail',
  templateUrl: './m-form-step-detail.component.html',
  styleUrls: ['./m-form-step-detail.component.scss']
})
export class MFormStepDetailComponent implements AfterViewInit, OnChanges {
  @Input()
  formStep: FormStep;
  @Input()
  data: FormStepData;
  @Input()
  isFirstStep: boolean;
  @Input()
  isLastStep: boolean;
  @Input()
  isValidationStep: boolean;
  @Input()
  isSavable: boolean;
  @Input()
  editable: boolean;
  @Input()
  forceShowValidationOnLastStep: boolean;
  @Input()
  canAccessNextStep = true;

  @ViewChild('component', {static: false, read: ViewContainerRef})
  injectionElement: ViewContainerRef;

  @Output()
  dataChange: EventEmitter<any> = new EventEmitter();
  @Output()
  previous: EventEmitter<any> = new EventEmitter();
  @Output()
  next: EventEmitter<any> = new EventEmitter();
  @Output()
  save: EventEmitter<any> = new EventEmitter();
  @Output()
  validate: EventEmitter<any> = new EventEmitter();
  @Output()
  reset: EventEmitter<any> = new EventEmitter();

  validationState = false;
  hiddenButtons: boolean;
  currentComponent: StepFormComponent<FormStepData>;
  previousLoadingButton = AButtonStatus.VALID;
  nextLoadingButton = AButtonStatus.VALID;
  validateLoadingButton = AButtonStatus.VALID;
  automaticNext = false;
  hidePreviousButton = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.formStep &&
      changes.formStep.currentValue &&
      this.injectionElement
    ) {
      this.loadComponent();
    }
  }

  ngAfterViewInit(): void {
    // TODO: Search for a better solution
    setTimeout(() => {
      this.loadComponent();
    }, 0);
  }

  loadComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        this.formStep.component
      );
    this.injectionElement.clear();
    const componentRef =
      this.injectionElement.createComponent(componentFactory);

    this.currentComponent = componentRef.instance;
    this.currentComponent.data = this.data;
    this.currentComponent.dataChange = this.dataChange;
    this.currentComponent.dataInitiated();
    this.currentComponent.validationState
      .asObservable()
      .subscribe((validationState) => {
        // TODO FHE : Check with MRU for better solution (without timeout we have ExpressionChangedAfterItHasBeenCheckedError)
        setTimeout(() => {
          this.validationState = validationState;
        }, 1);
      });
    this.automaticNext = this.currentComponent.automaticNext;
    this.hidePreviousButton = this.currentComponent.hidePreviousButton;
    this.currentComponent.forceOnNext.asObservable().subscribe(() => {
      this.onForceNext();
    });
    this.currentComponent.hideParentFormButtons
      .asObservable()
      .subscribe((hide) => (this.hiddenButtons = hide));
  }

  onPrevious(): void {
    if (this.currentComponent.onPreviousHandler != undefined) {
      this.currentComponent.onPreviousHandler(
        () => {
          this.previousLoadingButton = AButtonStatus.VALID;
          this.previous.emit();
        },
        (showErrorButton) => {
          if (showErrorButton) {
            this.previousLoadingButton = AButtonStatus.ERROR;
          } else {
            this.previousLoadingButton = AButtonStatus.VALID;
          }
        }
      );
    } else {
      this.previous.emit();
    }
  }

  onNext(): void {
    if (this.validationState === true) {
      this.onForceNext();
    } else {
      this.currentComponent.alreadySubmitted = true;
    }
  }

  onForceNext(): void {
    if (this.currentComponent.onNextHandler != undefined) {
      this.nextLoadingButton = AButtonStatus.LOADING;
      this.currentComponent.onNextHandler(
        () => {
          this.nextLoadingButton = AButtonStatus.VALID;
          this.next.emit();
        },
        (showErrorButton) => {
          if (showErrorButton) {
            this.nextLoadingButton = AButtonStatus.ERROR;
          } else {
            this.nextLoadingButton = AButtonStatus.VALID;
          }
        }
      );
    } else {
      this.next.emit();
    }
    this.currentComponent.alreadySubmitted = true;
  }

  onValidate(): void {
    if (this.validationState === true) {
      this.onForceValidate();
      this.currentComponent.alreadySubmitted = true;
    } else {
      this.currentComponent.alreadySubmitted = true;
    }
  }

  onForceValidate(): void {
    if (this.currentComponent.onValidateHandler != undefined) {
      this.validateLoadingButton = AButtonStatus.LOADING;
      this.currentComponent.onValidateHandler(
        () => {
          this.validateLoadingButton = AButtonStatus.VALID;
          this.validate.emit();
        },
        () => {
          this.validateLoadingButton = AButtonStatus.ERROR;
        }
      );
    } else {
      this.validate.emit();
    }
    this.currentComponent.alreadySubmitted = true;
  }

  onSave(): void {
    if (this.validationState === true) {
      this.save.emit();
    } else {
      this.currentComponent.alreadySubmitted = true;
    }
  }

  confirmReset(): void {
    this.reset.emit();
    this.currentComponent.dataReset();
  }
}
