import {MatchingTypesConfig} from './MatchingTypesConfig';

export class MatchingConfig {
  public threshold: number;
  public keepBestScoreOnSameProperty = false;
  public ignoreScoreIfMissingValue = false;
  public normalize = false;
  public normalizeOnLegalEntity = false;
  public excludeParticleOnLegalEntityName = false;
  public excludeParticleOnNaturalPersonName = false;
  public minimalThresholdCompute: number;
  public usePreviousMatchingScores = false;
  public forcePotentialRiskIfPersonUpdated = false;
  public keepMatchingStatusIfScoreIsEqual = false;
  public birthDateScoreDropInValue: number;
  public normalizeDescriptionInMatchingRecord = false;
  public matchingTypesConfig: MatchingTypesConfig = new MatchingTypesConfig();
  public ignoreLastNameScoresWithMaxDistance: number;
  public ignoreFirstNameScoresWithMaxDistance: number;
  public ignoreDoubleMetaphoneScoresWithMaxDistance: number;
  public keepMatchStatus: boolean = false;
}
