import {Directive, HostListener, Self} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({selector: '[noNegativeValue]'})
export class NoNegativeValue {

  constructor(@Self() private ngModel: NgModel) {}

  @HostListener('ngModelChange')
  public correctValue() {
    setTimeout(() => {
      const value = this.ngModel.value as number;
      if (this.ngModel.value && value.toString() !== "" && value <= 0) {
        this.ngModel.control.setValue(1);
      }
    });
  }
}
