import {Reference} from '../../../../../domain/Reference';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Company} from '../../company/Company';
import {PersonType} from '../person/PersonType';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {SelectableRef} from '../../../../../domain/SelectableRef';

export class PersonLinkType extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'personLinkType';

  name: string;
  sourceLabel: string;
  destLabel: string;
  authorizedPersonLinkType = new Map<PersonType, PersonType[]>();
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;

  constructor() {
    super('com.beclm.core.api.person.personLinkType.PersonLinkType');
  }

  public static toSelectableRef(
    reference: Reference<PersonLinkType>
  ): SelectableRef<PersonLinkType> {
    const selectableRef = new SelectableRef<PersonLinkType>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<PersonLinkType> {
    const selectableRef = new SelectableRef<PersonLinkType>();
    selectableRef.id = new Reference<PersonLinkType>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
