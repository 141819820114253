import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CoreCompanyConfig} from '../../domain/person/company-config/CoreCompanyConfig';
import {SessionService} from '../../../../services/session.service';
import {CoreCompanyConfigService} from './core-company-config.service';
import {first} from 'rxjs/operators';

@Injectable()
export class SessionCoreCompanyConfigService {
  private readonly currentCoreCompanyConfig =
    new BehaviorSubject<CoreCompanyConfig>(undefined);

  constructor(
    private coreCompanyConfigService: CoreCompanyConfigService,
    private sessionService: SessionService
  ) {
    this.sessionService.selectedCompany().subscribe(() => this.reloadCompanyConfig());
  }

  get coreCompanyConfig$(): Observable<CoreCompanyConfig> {
    return this.currentCoreCompanyConfig.asObservable();
  }

  get currentCompanyConfig$(): Observable<CoreCompanyConfig> {
    return this.currentCoreCompanyConfig.pipe(
      first((coreCompanyConfig) => coreCompanyConfig !== undefined)
    );
  }

  reloadCompanyConfig() {
    this.coreCompanyConfigService.findByCurrentCompany()
      .subscribe(companyConfig => this.currentCoreCompanyConfig.next(companyConfig));
  }
}
