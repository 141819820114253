import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {CompanyType} from '../../domain/company/company-type/CompanyType';
import {CompanyTypeSearchCriteria} from '../../domain/company/company-type/CompanyTypeSearchCriteria';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class CompanyTypeService {
  private readonly baseUrl = '/api/company-types';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public save(companyType: CompanyType): Observable<CompanyType> {
    if (companyType.id !== undefined) {
      return this.update(companyType);
    } else {
      return this.create(companyType);
    }
  }

  public create(companyType: CompanyType): Observable<CompanyType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyType)
            .post<CompanyType>(url, companyType)
        )
      );
  }

  public update(companyType: CompanyType): Observable<CompanyType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyType)
            .put<CompanyType>(url, companyType)
        )
      );
  }

  public delete(id: string): Observable<CompanyType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyType)
            .delete<CompanyType>(url + '/' + id)
        )
      );
  }

  public getById(id: string): Observable<CompanyType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyType)
            .get<CompanyType>(url + '/' + id)
        )
      );
  }

  public search(params?: CompanyTypeSearchCriteria): Observable<CompanyType[]> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.transformResponseTo(CompanyType).get<CompanyType[]>(url, {
          params: SearchParams.toHttpParams(params)
        })
      )
    );
  }

  public searchByName(companyTypeName: string): Observable<CompanyType> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CompanyType)
            .get<CompanyType>(url + '/name/' + companyTypeName)
        )
      );
  }
}
