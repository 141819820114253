import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {CompanyModification} from '../../domain/company/CompanyModification';
import {Company} from '../../domain/company/Company';
import {SearchParams} from '../../../../domain/SearchParams';
import {CompanySearchCriteria} from '../../domain/company/CompanySearchCriteria';
import {PaginatedCompanies} from '../../domain/company/PaginatedCompanies';
import {PaginatedCompanySearchResults} from '../../domain/company/PaginatedCompanySearchResults';

@Injectable()
export class CompanyService {
  protected readonly baseUrl = '/api/companies';

  private _companyReusablePaymentMethodChanged = new Subject<boolean>();

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public save(companyModification: CompanyModification): Observable<Company> {
    if (companyModification.id !== undefined) {
      return this.update(companyModification);
    } else {
      return this.create(companyModification);
    }
  }

  public create(companyModification: CompanyModification): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Company)
            .post<Company>(url, companyModification)
        )
      );
  }

  public createFromOther(
    companyId: string,
    companyModification: CompanyModification
  ): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Company)
            .post<Company>(url + '/' + companyId, companyModification)
        )
      );
  }

  public update(companyModification: CompanyModification): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Company)
            .put<Company>(url, companyModification)
        )
      );
  }

  public getById(id: string): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(Company).get<Company>(url + '/' + id)
        )
      );
  }

  public search(
    params?: CompanySearchCriteria
  ): Observable<PaginatedCompanies> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedCompanies)
          .get<PaginatedCompanies>(url, {
            params: SearchParams.toHttpParams(params)
          })
      )
    );
  }

  public searchPaginatedCompanySearchResults(
    params?: CompanySearchCriteria
  ): Observable<PaginatedCompanySearchResults> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedCompanySearchResults)
          .get<PaginatedCompanySearchResults>(`${url}/search-results`, {
            params: SearchParams.toHttpParams(params)
          })
      )
    );
  }

  public searchByName(companyName: string): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Company)
            .get<Company>(url + '/name/' + companyName)
        )
      );
  }

  public searchByToggleFeatures(
    searchCriteria: CompanySearchCriteria
  ): Observable<Company[]> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(Company)
          .get<Company[]>(url + '/toggleFeatures', {
            params: SearchParams.toHttpParams(searchCriteria)
          })
      )
    );
  }

  public current(): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(Company).get<Company>(url + '/current')
        )
      );
  }

  selectCompany(companyId: string): Observable<Company> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(Company)
            .post<Company>(url + `/current/${companyId}`, null)
        )
      );
  }

  public deleteLogo(id: string): Observable<Blob> {
    return this.moduleService
      .coreModuleBackendUrl('/api/blobs')
      .pipe(
        switchMap((url) =>
          this.http.transformResponseTo(Blob).delete<Blob>(url + '/' + id)
        )
      );
  }

  public getUrlToDownloadBlob(): Observable<string> {
    return this.moduleService.coreModuleBackendUrl('/api/blobs/download/');
  }

  public removeReusablePaymentMethodForCurrentCompany(): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.delete<void>(
            `${url}/current/remove-reusable-payment-method`
          )
        )
      );
  }

  public loadCompanyReusablePaymentMethodChange(
    hasReusablePaymentMethod: boolean
  ) {
    this._companyReusablePaymentMethodChanged.next(hasReusablePaymentMethod);
  }

  public getCompanyReusablePaymentMethodChange(): Observable<boolean> {
    return this._companyReusablePaymentMethodChanged.asObservable();
  }
}
