export class Version {
  public number: string;
  public date: string;
  public commit: string;
  public env: string;

  public isProdClient() {
    return ['prod-assurant'].includes(this.env)
  }

  public isProdVersion() {
    return ['prod', 'prod-gcp'].includes(this.env)
  }

  public isNotProdVersion() {
    return !this.isProdVersion()
  }

  public isNotProdClientVersion() {
    return !this.isProdClient()
  }
}
