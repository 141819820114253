import {Transform, Type} from 'class-transformer';
import {LocalDate} from '../../../../../../../domain/LocalDate';
import {LocalDateTransformer} from '../../../../../../../domain/transformation/LocalDateTransformer';

export class ProofOfId {
  number: string;
  @Transform(LocalDateTransformer.transformTo())
  issueDate: LocalDate;
  type: string;
  issuer: string;
}
