import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {UserCredential} from '../../domain/user/user-credential/UserCredential';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class UserCredentialService {
  private readonly baseUrl = '/api/user-credentials';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public update(userCredential: UserCredential): Observable<UserCredential> {
    if (
      userCredential.permanentLoginToken.value == null ||
      userCredential.permanentLoginToken.value == ''
    ) {
      userCredential.permanentLoginToken = null;
    }

    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(UserCredential)
            .put<UserCredential>(url, userCredential)
        )
      );
  }

  public getByLogin(login: string): Observable<UserCredential> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(UserCredential)
            .get<UserCredential>(url + '?login=' + encodeURIComponent(login))
        )
      );
  }

  public requestRenewalPassword(email: string): Observable<void> {
    const userCredential = new UserCredential();
    userCredential.login = email;
    userCredential.password = '';

    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.post<void>(`${url}/renewal`, userCredential)
        )
      );
  }

  public checkPasswordRenewalToken(token: string): Observable<boolean> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(UserCredential)
          .get<UserCredential>(`${url}/renewal/${token}`)
      ),
      map((result: any) => result.value)
    );
  }

  public renewPassword(userCredential: UserCredential): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http.put<void>(`${url}/byRenewal`, userCredential)
        )
      );
  }

  public doesLoginTokenExists(loginToken: String, checkedUserLogin: String): Observable<BooleanResponse> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) =>
          this.http.get<BooleanResponse>(`${url}/login-token/${loginToken}/exists`, {
            params: SearchParams.toHttpParams({checkedUserLogin: checkedUserLogin})
          })
        )
      );
  }
}
