import {Reference} from '../../../../../domain/Reference';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../company/Company';

export class CorePersonIndicator extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'corePersonIndicator';

  public id: string;

  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;

  totalPerson: number;

  constructor() {
    super('com.beclm.person.api.corePersonIndicator.CorePersonIndicator');
  }
}
