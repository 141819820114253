import {AuditData} from './AuditData';

export class Entity {
  public id: string;
  public auditData: AuditData;

  public isNew(): boolean {
    return this.id == null;
  }
}
