import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({selector: '[copy-clipboard]'})
export class CopyClipboardDirective {
  @Input('copy-clipboard')
  public payload: string;

  @Input('context')
  public context: string;

  @Input('clipboardMode')
  public isClipboardModeActivated = true

  @Output('copied')
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.isClipboardModeActivated) {
      event.preventDefault();
      const value = this.cleanPayload();
      if (!value) return;

      const listener = (e: ClipboardEvent) => {
        const clipboard = e.clipboardData || window['clipboardData'];
        clipboard.setData('text', value);
        e.preventDefault();
        this.copied.emit(value);
      };

      document.addEventListener('copy', listener, false);
      document.execCommand('copy');
      document.removeEventListener('copy', listener, false);
    }
  }

  private cleanPayload() {
    return this.payload
      .toString()
      .replace(/<[^>]*>/g, '')
      .trim();
  }
}
