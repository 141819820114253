import {Transform} from 'class-transformer';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {LocalDateTransformer} from '../../../../domain/transformation/LocalDateTransformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {LocalDate} from '../../../../domain/LocalDate';
import {Reference} from '../../../../domain/Reference';
import {Company} from './Company';

export class Subscription extends ClassedEntity {
  module: string;
  @Transform(LocalDateTransformer.transformTo())
  endDate: LocalDate;
  maxRecordNumber: number;
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;
  automaticModuleDisabling: boolean;
  invoiceSubscriptionId: string;
  lastSubscriptionProposalRef: string;

  constructor() {
    super('com.beclm.core.api.subscription.Subscription');
  }
}
