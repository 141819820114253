import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-button-text',
  templateUrl: './a-button-text.component.html',
  styleUrls: ['./a-button-text.component.scss'],
  host: {class: 'grid-x align-middle'}
})
export class AButtonTextComponent {
  @Input()
  labelKey: string;

  @Input()
  loading = false;
}
