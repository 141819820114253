import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fromSize, Size} from '../buttons-utils';

@Component({
  selector: 'a-button-delete',
  templateUrl: './a-button-delete.component.html',
  styleUrls: ['./a-button-delete.component.scss']
})
export class AButtonDeleteComponent {
  @Input()
  public disabled = false;

  @Input()
  public labelKey: string;

  @Input()
  public loading = false;

  @Input()
  public white: boolean;

  @Input()
  public hollow = false;

  @Input()
  public size: Size = 'DEFAULT';
  public fromSize = fromSize;

  @Output()
  public btnClick = new EventEmitter<MouseEvent>();

  @Input()
  public btnRouterLink: any[] | string | null | undefined;

  @Input()
  public btnRouterLinkQueryParams: {
    [key: string]: any;
  };
}
