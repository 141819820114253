import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {Reference} from '../../../../../domain/Reference';
import {SelectableRef} from '../../../../../domain/SelectableRef';

export class CompanyType extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'companyType';

  public name: string;

  constructor() {
    super('com.beclm.company.api.company.CompanyType');
  }

  public static toSelectableRef(
    companyRef: Reference<CompanyType>
  ): SelectableRef<CompanyType> {
    const selectableRef = new SelectableRef<CompanyType>();
    selectableRef.id = companyRef;
    if (companyRef.isResolved()) {
      selectableRef.__label = companyRef.entity.name;
    } else {
      selectableRef.__label = companyRef.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<CompanyType> {
    const selectableRef = new SelectableRef<CompanyType>();
    selectableRef.id = new Reference<CompanyType>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
