<ng-container *ngIf="!showHelp">
  <div [class]="'helpBtn ' + fromHelperType(kind)">
    <a-link-icon
      size="XLARGE"
      tooltip="{{ 'common.help.open' | translate }}"
      (click)="openHelpMessage()"
    >
      <i class="fa"></i>
    </a-link-icon>
  </div>
</ng-container>

<div [class]="'helpCallout ' + fromHelperType(kind)" *ngIf="showHelp">
  <span
    class="close-help"
    tooltip="{{ 'common.help.close' | translate }}"
    (click)="closeHelpMessage()"
  >
    <i class="fas fa-times-circle"></i>
  </span>
  <div class="grid-x grid-x grid-padding-x">
    <div class="cell auto">
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
