import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {StepFormComponent} from './step-form-component';
import {NgForm} from '@angular/forms';

@Component({template: ''})
export abstract class StepSingleFormComponent<T>
  extends StepFormComponent<T>
  implements AfterViewInit
{
  @ViewChild('form')
  form: NgForm;

  ngAfterViewInit(): void {
    if (this.form) {
      this.statusChangeSubscribe();
      if (this.form.form.status === 'VALID') {
        this.validationState.emit(true);
      }
    }
  }

  private statusChangeSubscribe() {
    this.form.statusChanges.subscribe((value) => {
      if (this.form.form.disabled) {
        this.validationState.emit(this.validateIfDisabled);
      } else {
        this.validationState.emit(value === 'VALID');
      }
    });
  }
}
