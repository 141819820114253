import {Need} from './Need';
import {ValidationStatus} from './ValidationStatus';
import {PersonType} from '../../person/person/PersonType';
import {Type} from 'class-transformer';
import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {AuditData} from '../../../../../domain/AuditData';
import {Reference} from '../../../../../domain/Reference';
import {SelectableRef} from '../../../../../domain/SelectableRef';
import {Uri} from '../../../../../domain/Uri';

export class ProductType extends ClassedEntity {
  constructor();
  constructor(
    id: string,
    label: string,
    domain: string,
    applicable: PersonType,
    needs: Need[],
    hedgedRisk: string,
    code: string,
    status: ValidationStatus,
    auditData: AuditData
  );
  constructor(
    id?: string,
    label?: string,
    domain?: string,
    applicable?: PersonType,
    needs: Need[] = [],
    hedgedRisk?: string,
    code?: string,
    status?: ValidationStatus,
    auditData?: AuditData,
  ) {
    super('com.beclm.product.api.producttype.ProductType');
    this.id = id;
    this.domain = domain;
    this.applicable = applicable;
    this.needs = needs;
    this.hedgedRisk = hedgedRisk;
    this.code = code;
    this.status = status;
    this.auditData = auditData;
    this.label = label;
  }

  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'productType';

  code: string;
  label: string;
  domain: string;
  hedgedRisk: string;
  applicable: PersonType;
  @Type(() => Need)
  needs: Need[] = [];
  status: ValidationStatus;
  draftDate: Date;
  validationDate: Date;

  public static toSelectableRef(
    reference: Reference<ProductType>
  ): SelectableRef<ProductType> {
    const selectableRef = new SelectableRef<ProductType>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.label;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<ProductType> {
    const selectableRef = new SelectableRef<ProductType>();
    selectableRef.id = new Reference<ProductType>(this);
    selectableRef.__label = this.label;
    return selectableRef;
  }

  isValid() {
    return this.status === ValidationStatus.VALID;
  }

  public static referenceFrom(id: string) {
    return new Reference<ProductType>(
      new Uri(this.MODULE, this.COLLECTION, id)
    );
  }
}
