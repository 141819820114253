import {Type} from "class-transformer";
import {LocalDate} from '../../../../../../../domain/LocalDate';

export class InsurerContract {
  type: string;
  number: string;
  insurer: string;
  @Type(() => Date)
  lastModificationDate: Date;
  @Type(() => Date)
  startDate: LocalDate;
  @Type(() => Date)
  endDate: LocalDate;

  static insurerContractBuilder(value: InsurerContract) {
    const contract = new InsurerContract();
    contract.type = value.type;
    contract.number = value.number;
    contract.insurer = value.insurer;
    contract.lastModificationDate = value.lastModificationDate;
    contract.startDate = value.startDate;
    contract.endDate = value.endDate;
    return contract;
  }
}
