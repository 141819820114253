import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Module} from '../domain/Module';
import {HttpHandlerService} from './http/http-handler.service';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthorizedModuleService {
  private readonly url = this.environment.apiBaseHref + '/api/modules';

  authorizedModules = new ReplaySubject<Module[]>();
  authorizedModuleByName = new ReplaySubject<{[name: string]: Module}>();

  constructor(
    private http: HttpHandlerService,
    authenticationService: AuthenticationService,
    @Inject('env') private environment
  ) {
    authenticationService.isAuthenticated().subscribe((value) => {
      if (value == true) {
        this.loadAuthorizedModules();
      }
    });
  }

  private getRemoteAuthorizedModules(): Observable<Module[]> {
    return this.http.transformResponseTo(Module).get(this.url + '/authorized');
  }

  private loadAuthorizedModules(): void {
    this.getRemoteAuthorizedModules().subscribe((modules) => {
      this.authorizedModules.next(modules);
      const lModuleByName: {[name: string]: Module} = {};
      modules.forEach((module) => (lModuleByName[module.name] = module));
      this.authorizedModuleByName.next(lModuleByName);
    });
  }
}
