import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-title-view',
  templateUrl: './a-title-view.component.html',
  styleUrls: ['./a-title-view.component.scss']
})
export class ATitleViewComponent {
  @Input()
  titleKey: string;

  @Input()
  public faIcon: string;
}
