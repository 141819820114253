import {HttpClient} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {HttpRequestHandler} from './HttpRequestHandler';
import {AbstractClassMapper} from '../transformation/class-mapper/AbstractClassMapper';
import {UtilsService} from '../../services/utils.service';

export class HttpRequestTransformer<T> extends HttpRequestHandler<T> {
  private readonly abstractClassMapper: AbstractClassMapper<T>;

  constructor(httpClient: HttpClient, classMapper?: AbstractClassMapper<T>) {
    super(httpClient);

    this.abstractClassMapper = classMapper;
  }

  protected handleValue(value: T): any {
    if (!UtilsService.exists(this.abstractClassMapper)) {
      return value;
    }

    const classReference: any = this.abstractClassMapper.getClass(value);
    return plainToClass(classReference, value);
  }
}
