import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../services/http/http-handler.service';
import {ModuleService} from '../../../services/module.service';
import {QualificationSummaryQuestion} from '../domain/qualificationQuestion/QualificationSummaryQuestion';
import {PersonType} from '../../core/domain/person/person/PersonType';

@Injectable()
export class QualificationSummaryQuestionEntityService {
  private readonly baseUrl: string = '/api/qualification-summary-questions';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findForCurrentCompanyAndType(
    personType: PersonType
  ): Observable<QualificationSummaryQuestion> {
    return this.moduleService.blacklistPepBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(QualificationSummaryQuestion)
          .get<QualificationSummaryQuestion>(`${url}/current-company/person-type/${personType}`)
      )
    );
  }

  public save(qualificationSummaryQuestion: QualificationSummaryQuestion): Observable<QualificationSummaryQuestion> {
    if (qualificationSummaryQuestion != null) {
      if (qualificationSummaryQuestion.id !== null) {
        return this.update(qualificationSummaryQuestion);
      } else {
        return this.create(qualificationSummaryQuestion);
      }
    } else {
      return of(null);
    }
  }

  public create(
    qualificationSummaryQuestion: QualificationSummaryQuestion
  ): Observable<QualificationSummaryQuestion> {
    return this.moduleService
      .blacklistPepBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(QualificationSummaryQuestion)
            .post<QualificationSummaryQuestion>(`${url}`, qualificationSummaryQuestion)
        )
      );
  }

  public update(
    qualificationSummaryQuestion: QualificationSummaryQuestion
  ): Observable<QualificationSummaryQuestion> {
    return this.moduleService
      .blacklistPepBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(QualificationSummaryQuestion)
            .put<QualificationSummaryQuestion>(`${url}`, qualificationSummaryQuestion)
        )
      );
  }

  public delete(id: string): Observable<void> {
    if (id != undefined) {
      return this.moduleService.blacklistPepBackendUrl(this.baseUrl).pipe(
        switchMap((url) => this.http.delete<void>(
          `${url}`, {params: {qualificationSummaryQuestionId: id}})
        )
      );
    } else {
      return of(null);
    }
  }
}
