import {Type} from 'class-transformer';
import {HierarchizedCode} from './HierarchizedCode';

export class Code {
  name: string;
  defaultValue: string;
  otherValues: string[];
}

export class CodedValue {
  @Type(() => Code, {
    discriminator: {
      property: '@class',
      subTypes: [
        {
          value: HierarchizedCode,
          name: 'com.beclm.base.api.codedValue.HierarchizedCode'
        },
        {
          value: Code,
          name: 'com.beclm.base.api.codedValue.Code'
        }
      ]
    }
  })
  code: Code;
  value: string;
}
