import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';

@Injectable()
export class RuleService {
  protected readonly baseUrl = '/api/rules';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getDownloadRulesUrl(): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl + '/download');
  }
}
