import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Reference} from '../../../../../domain/Reference';
import {Product} from '../../../domain/product/Product';
import {ProductService} from '../../../services/product.service';
import {SessionService} from '../../../../../services/session.service';

const PRODUCT_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductSelectComponent),
  multi: true
};

@Component({
  selector: 'product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
  providers: [PRODUCT_SELECT_VALUE_ACCESSOR]
})
export class ProductSelectComponent implements OnInit, ControlValueAccessor {
  productRefs: Reference<Product>[];
  productRefSelected: Reference<Product>;
  disabled: boolean;

  constructor(
    private sessionService: SessionService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.sessionService.selectedCompany().subscribe((company) => {
      this.productService
        .findForCompany(new Reference(company))
        .subscribe((productRefs) => {
          this.productRefs = productRefs.map(
            (product) => new Reference<Product>(product)
          );
        });
    });
  }

  writeValue(productRef: Reference<Product>): void {
    this.productRefSelected = productRef;
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  valueSelected(productRef: Reference<Product>) {
    this.productRefSelected = productRef;
    this.onChange(productRef);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  compareProductRef(
    productRef1: Reference<Product>,
    productRef2: Reference<Product>
  ): boolean {
    return productRef1.isEqualTo(productRef2);
  }
}
