<div class="card">
  <div class="card-section formStep grid-x grid-padding-x">
    <div
      class="formStep-item cell auto"
      *ngFor="let step of stepsConfig; let i=index"
      [class._active]="i == activeFormStepIndex"
      [class._completed]="i < activeFormStepIndex"
    >
      <span class="formStep-item-bullet"></span>
      <label class="formStep-item-label">{{step.labelKey | translate}}</label>
    </div>
  </div>
</div>
