import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {PagesModule} from './pages/pages.module';
import {AppRootComponent} from './pages/appRoot.component';
import {registerLocaleData} from '@angular/common';
import {map, tap} from 'rxjs/operators';
import localeFr from '@angular/common/locales/fr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {
  BaseCoreServicesModule,
  BaseDirectivesModule,
  BeclmErrorHandler,
  BeclmErrorInterceptor,
  CookieInterceptor,
  RecaptchaService
} from 'base';
import {AppLoadService} from './services/app.load.service';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

registerLocaleData(localeFr, 'fr');

export function translateLoaderFactory(http: HttpClient) {
  return {
    getTranslation(lang: string): any {
      const headers: HttpHeaders = new HttpHeaders();
      headers.append('Accept-Language', lang);
      return http
        .get(environment.apiBaseHref + '/api/i18n/labels', {headers: headers})
        .pipe(map((res) => res));
    }
  };
}

export function recaptchaLoaderFactory(recaptchaService: RecaptchaService) {
  return recaptchaService.getPublicKey();
}

export function initApp(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

export function initRecaptchaKey(recaptchaService: RecaptchaService) {
  return () =>
    recaptchaService
      .initPublicKey()
      .pipe(tap((publicKey) => recaptchaService.loadPublicKey(publicKey.value)))
      .toPromise();
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PagesModule,
    BaseCoreServicesModule,
    BaseDirectivesModule,
    RecaptchaV3Module
  ],
  providers: [
    AppLoadService,
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BeclmErrorInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppLoadService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initRecaptchaKey,
      deps: [RecaptchaService],
      multi: true
    },
    {provide: ErrorHandler, useClass: BeclmErrorHandler},
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: recaptchaLoaderFactory,
      deps: [RecaptchaService]
    }
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule {}
