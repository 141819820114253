import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {Company} from './Company';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';

export class CompanyRefName extends ClassedEntity {
  public name: string;
  @Transform(ReferenceTransformer.transformTo(Company))
  public companyRef: Reference<Company>;
}
