import {PublicFunction} from './PublicFunction';
import {PublicFunctionConnection} from './PublicFunctionConnection';
import {Type} from 'class-transformer';
import {NaturalPersonPepStatus} from './NaturalPersonPepStatus';
import {AuditDataItem} from '../../../../../../../domain/AuditDataitem';

export class PoliticalExposure {
  @Type(() => Map)
  exposure: Map<PublicFunction, PublicFunctionConnection[]> = null;
  comment: string;
  pepStatus: NaturalPersonPepStatus;
  @Type(() => AuditDataItem)
  pepModificationData: AuditDataItem;
}
