import {Reference} from '../../../../domain/Reference';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {ClassedEntity} from '../../../../domain/ClassedEntity';

export class BlacklistPepInjector extends ClassedEntity {
  public name: string;
  public ttlMinFileBeforeTake: number;
  public ttlFileAfterFiltering: number;
  public patternExclusion: string;
  public patternTermination: string;
  public fileProvider: string;
  public injectionTriggers: string[] = [];
  public useParentCompanySftp: boolean = false;
  public sftpPath: string;


  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'injector';

  constructor() {
    super('com.beclm.blacklist.pep.api.injector.BlacklistPepInjector');
  }

  public static toSelectableRef(
    reference: Reference<BlacklistPepInjector>
  ): SelectableRef<BlacklistPepInjector> {
    const selectableRef = new SelectableRef<BlacklistPepInjector>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<BlacklistPepInjector> {
    const selectableRef = new SelectableRef<BlacklistPepInjector>();
    selectableRef.id = new Reference<BlacklistPepInjector>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
