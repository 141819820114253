import {Type} from 'class-transformer';
import {PostalAddress} from '../natural/main-information/PostalAddress';

export class PersonCompanyOffice {
  siret = '';
  mainActivity: string;
  @Type(() => PostalAddress)
  address: PostalAddress = new PostalAddress();
  establishmentsSiret: string[];
}
