import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormStep} from '../FormStep';
import {FormStepData} from '../FormStepData';

@Component({
  selector: 'm-multi-step-form',
  templateUrl: './m-multi-step-form.component.html',
  styleUrls: ['./m-multi-step-form.component.scss']
})
export class MMultiStepFormComponent implements OnChanges {
  @Input()
  stepsConfig: FormStep[];

  @Input()
  data: FormStepData;
  @Input()
  isValidateShown: boolean;
  @Input()
  isSavable: boolean;
  @Input()
  editable = true;
  @Input()
  forceShowValidationOnLastStep = false;
  @Input()
  canAccessNextStep = true;
  @Input()
  loadLastStep = false;

  @Output()
  dataChange: EventEmitter<FormStepData> = new EventEmitter();
  @Output()
  savedValue: EventEmitter<FormStepData> = new EventEmitter();
  @Output()
  validatedValue: EventEmitter<FormStepData> = new EventEmitter();
  @Output()
  resetData: EventEmitter<any> = new EventEmitter();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @ViewChild('topPage')
  topPage: ElementRef;

  @ContentChild('header', {static: true}) headerTemplate: TemplateRef<any>;

  activeFormStep: FormStep;

  @Input()
  activeFormStepIndex = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeFormStepIndex && changes.activeFormStepIndex.currentValue) {
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
    if (changes.stepsConfig && changes.stepsConfig.currentValue) {
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
    if (changes.loadLastStep && changes.loadLastStep.currentValue) {
      this.setLastStep();
    }
  }

  setPreviousStep(): void {
    this.topPage.nativeElement.scrollIntoView({behavior: 'smooth'});

    if (this.activeFormStepIndex > 0) {
      this.activeFormStepIndex--;
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
  }

  setNextStep(): void {
    this.topPage.nativeElement.scrollIntoView({behavior: 'smooth'});

    if (this.activeFormStepIndex < this.stepsConfig.length - 1) {
      this.activeFormStepIndex++;
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
  }

  setLastStep(): void {
    this.activeFormStepIndex = this.stepsConfig.length - 1;
    this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
  }

  saveForm(): void {
    this.savedValue.emit(this.data);
  }

  validateForm(): void {
    this.validatedValue.emit(this.data);
  }

  resetForm(): void {
    this.resetData.emit();
    this.activeFormStepIndex = 0;
    this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
  }

  dataChanged(data: FormStepData): void {
    this.dataChange.emit(data);
  }
}
