import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ModuleService} from './module.service';
import {of} from 'rxjs';

@Injectable()
export class NavigationService {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private moduleService: ModuleService
  ) {}

  public navigateTo(url: string, openToBlank = true) {
    this.authenticationService
      .isAuthenticatedNotCached()
      .pipe(
        switchMap((authenticated) => {
          if (authenticated) {
            return openToBlank
              ? of(window.open(url, '_blank'))
              : of(window.open(url));
          } else {
            return this.moduleService
              .userModuleUiUrl('/login')
              .pipe(tap((url) => (window.location.href = url)));
          }
        })
      )
      .subscribe();
  }
}
