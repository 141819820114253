import { Injectable } from '@angular/core';
import {combineLatest} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ServiceDeskService} from './service-desk.service';
import {ServiceDeskMessage} from '../../../domain/adapter/servicedesk/ServiceDeskMessage';

@Injectable()
export class SendSubscriptionRequestService {

  constructor(
    private readonly translateService: TranslateService,
    private readonly serviceDeskService: ServiceDeskService
  ) { }

  openPopinToSendRequestSubscription(moduleName: string, subscriptionSubjectKey: string) {
    const serviceDeskMessage = new ServiceDeskMessage();
    combineLatest([
      this.translateService.get(
        subscriptionSubjectKey
      ),
      this.translateService.get('mail.message.subscription.asking', {
        moduleName: moduleName
      })
    ]).subscribe(([title, message]) => {
      serviceDeskMessage.title = title.toString();
      serviceDeskMessage.message = message.toString();
      this.serviceDeskService.updateServiceDeskMessage(serviceDeskMessage);
    });
  }
}
