import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-loading-spinner',
  templateUrl: './a-loading-spinner.component.html',
  styleUrls: ['./a-loading-spinner.component.scss']
})
export class ALoadingSpinnerComponent {
  @Input()
  isRoot = false;
}
