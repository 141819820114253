import {
  AbstractControl,
  AsyncValidator,
  AsyncValidatorFn,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from '@angular/forms';
import {Directive, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {BlacklistMailService} from '../../services/blacklist-mail.service';
import {BooleanResponse, CustomValidators} from 'base';

@Directive({
  selector: '[emailCheckBlacklist]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: EmailCheckBlacklistValidatorDirective,
      multi: true
    }
  ]
})
@Injectable()
export class EmailCheckBlacklistValidatorDirective implements AsyncValidator {
  constructor(private blacklistMailService: BlacklistMailService) {}

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.emailIsBlacklist(this.blacklistMailService)(control);
  }

  emailIsBlacklist(
    blacklistMailService: BlacklistMailService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return blacklistMailService
        .emailIsValid(control.value.toLowerCase())
        .pipe(first())
        .pipe(
          map((isExisting: BooleanResponse) => {
            return !isExisting.value
              ? CustomValidators.buildError('emailIsBlacklist', true)
              : null;
          })
        );
    };
  }
}
