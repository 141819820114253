import {DataSource} from '../../data-source/DataSource';
import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../domain/Reference';
import * as duration from 'duration-fns';
import {BlacklistPepFileMapping} from '../../fileMapping/BlacklistPepFileMapping';

export class FileConfig {
  fileId: string;
  regex: string;
  @Transform(ReferenceTransformer.transformTo(DataSource))
  subSources: Reference<DataSource>[] = [];
  parserName: string;
  @Transform(ReferenceTransformer.transformTo(BlacklistPepFileMapping))
  fileMapping: Reference<BlacklistPepFileMapping>;
  expectedEvery: string;

  public getExpectedEveryAsNumber(): number {
    if (this.expectedEvery) {
      return duration.toDays(duration.parse(this.expectedEvery));
    }
    return null;
  }

  public setExpectedEveryFromNumber(nbOfDaysString: string) {
    if (nbOfDaysString) {
      const nbOfDays = Math.trunc(Number(nbOfDaysString));
      this.expectedEvery = duration.toString({days: nbOfDays});
    } else {
      this.expectedEvery = null;
    }
  }
}
