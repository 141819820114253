import {Type} from 'class-transformer';
import {ControlResult} from '../person/control/ControlResult';
import {ControlType} from '../person/control/ControlType';
import {PersonDataCategoryType} from './PersonDataCategoryType';

export class PersonDataCategory {
  type: PersonDataCategoryType;
  @Type(() => ControlResult)
  controlResults: {[key:string] : ControlResult};
  completenessRatio: number;
  vigilanceRatio: number;
}
