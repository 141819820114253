import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PresetNeedComment} from '../../domain/product/product-type/presetNeedComment/PresetNeedComment';
import {PaginatedPresetNeedComments} from '../../domain/product/product-type/presetNeedComment/PaginatedPresetNeedComments';
import {PresetNeedCommentSearchCriteria} from '../../domain/product/product-type/presetNeedComment/PresetNeedCommentSearchCriteria';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class PresetNeedCommentService {
  private readonly baseUrl = '/api/preset-need-comment';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getNeedCommentTypes(): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) => this.http.get<string[]>(url + '/comment-types'))
      );
  }

  public save(
    presetNeedComment: PresetNeedComment
  ): Observable<PresetNeedComment> {
    if (presetNeedComment.id !== undefined) {
      return this.update(presetNeedComment);
    } else {
      return this.create(presetNeedComment);
    }
  }

  public findById(id: string): Observable<PresetNeedComment> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PresetNeedComment)
            .get<PresetNeedComment>(`${url}/${id}`)
        )
      );
  }

  public create(
    presetNeedComment: PresetNeedComment
  ): Observable<PresetNeedComment> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PresetNeedComment)
            .post<PresetNeedComment>(url, presetNeedComment)
        )
      );
  }

  public update(
    presetNeedComment: PresetNeedComment
  ): Observable<PresetNeedComment> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PresetNeedComment)
            .put<PresetNeedComment>(url, presetNeedComment)
        )
      );
  }

  public paginatedFind(
    param: PresetNeedCommentSearchCriteria
  ): Observable<PaginatedPresetNeedComments> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedPresetNeedComments)
          .get<PaginatedPresetNeedComments>(url + '/paginated', {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public delete(id: string): Observable<PresetNeedComment> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PresetNeedComment)
            .delete<PresetNeedComment>(`${url}/${id}`)
        )
      );
  }
}
