import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Reference} from '../../../../../domain/Reference';
import {OperationType} from '../../../domain/operation/OperationType';
import {OperationTypeService} from '../../../services/operation-type.service';
import {Product} from '../../../domain/product/Product';
import {SessionService} from '../../../../../services/session.service';

const PRODUCT_SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OperationTypeSelectComponent),
  multi: true
};

@Component({
  selector: 'operation-type-select',
  templateUrl: './operation-type-select.component.html',
  styleUrls: ['./operation-type-select.component.scss'],
  providers: [PRODUCT_SELECT_VALUE_ACCESSOR]
})
export class OperationTypeSelectComponent
  implements OnInit, ControlValueAccessor
{
  operationTypeRefs: Reference<OperationType>[];
  operationTypeRefSelected: Reference<OperationType>;
  disabled: boolean;

  constructor(
    private sessionService: SessionService,
    private operationTypeService: OperationTypeService
  ) {}

  ngOnInit(): void {
    this.sessionService.selectedCompany().subscribe((company) => {
      this.operationTypeService
        .findForCompany(new Reference(company))
        .subscribe((operationTypeRefs) => {
          this.operationTypeRefs = operationTypeRefs.map(
            (operationType) => new Reference<OperationType>(operationType)
          );
        });
    });
  }

  writeValue(operationTypeRef: Reference<OperationType>): void {
    this.operationTypeRefSelected = operationTypeRef;
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  valueSelected(operationTypeRef: Reference<OperationType>) {
    this.operationTypeRefSelected = operationTypeRef;
    this.onChange(operationTypeRef);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  compareOperationTypeRef(
    operationTypeRef1: Reference<Product>,
    operationTypeRef2: Reference<Product>
  ): boolean {
    return operationTypeRef1.isEqualTo(operationTypeRef2);
  }
}
