import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {SireneService} from '../modules/core/services/person/sirene.service';
import {PersonCompanyInfo} from '../modules/core/domain/person/person/company-siren/PersonCompanyInfo';
import {CustomValidators} from '../utils/CustomValidators';
import {LegalEntity} from '../modules/core/domain/person/person/legal-entity/LegalEntity';
import {BooleanResponse} from '../domain/BooleanResponse';

export class SirenIsAllowedWithNafValidator {
  constructor(
    private sireneService: SireneService,
    private sirenIsAllowedWithNaf = false
  ) {}

  validate(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    if (!this.sirenIsAllowedWithNaf) {
      return of(null);
    }

    return this.sirenIsAllowedWithNafEnabledCheck(this.sireneService, control);
  }

  sirenIsAllowedWithNafEnabledCheck(
    sirenService: SireneService,
    control: AbstractControl
  ): Observable<ValidatorFn> {
    return sirenService
      .isSirenNafAllowedForRegistration(control.value)
      .pipe(first())
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .pipe(
        map((isAllowed: BooleanResponse) => {
          return this.sirenIsAllowedWithNafValidatorFn(
            control,
            isAllowed
          );
        })
      );
  }

  sirenIsAllowedWithNafValidatorFn(
    control: AbstractControl,
    isAllowed: BooleanResponse
  ): ValidatorFn {
    if (
      control.value != null &&
      !isAllowed.value
    ) {
      return CustomValidators.buildError('sirenHasInvalidNaf', true);
    } else {
      return null;
    }
  }
}
